import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ProfileBadges',
    props: {
        badgeItems: {
            type: Array,
            required: true
        },
        enableNoomBadge: {
            type: Boolean,
            default: false
        },
    }
});
