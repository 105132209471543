import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "contact-button" };
const _hoisted_2 = {
    key: 0,
    class: "tooltip-component"
};
const _hoisted_3 = { class: "tooltip-component__description" };
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
    key: 0,
    class: "copied-to-clipboard"
};
const _hoisted_6 = {
    key: 0,
    class: "copied-to-clipboard"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ZButton = _resolveComponent("ZButton");
    const _component_ContactInstructorHandler = _resolveComponent("ContactInstructorHandler");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.showIconOnly && _ctx.showTooltip)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("ul", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactTypes, (contactType) => {
                            return (_openBlock(), _createElementBlock("li", { key: contactType }, [
                                _createElementVNode("a", {
                                    href: "#",
                                    class: "contact-option",
                                    onClick: _ctx.contactTypesDetails[contactType].action
                                }, _toDisplayString(contactType), 9, _hoisted_4),
                                (_ctx.copiedToClipboardTooltip && contactType === _ctx.AvailableContactTypes.SMS)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.t.copiedToClipboard), 1))
                                    : _createCommentVNode("", true)
                            ]));
                        }), 128))
                    ])
                ]),
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "tooltip-component__rectangle" }, null, -1))
            ]))
            : _createCommentVNode("", true),
        (_ctx.contactTypes.length)
            ? (_openBlock(), _createBlock(_component_ZButton, {
                key: 1,
                variety: _ctx.variety,
                class: _normalizeClass({ 'icon-only': _ctx.showIconOnly }),
                compact: _ctx.compact,
                wide: _ctx.wide,
                onClick: _ctx.showContactOptions
            }, {
                icon: _withCtx(() => [
                    (_ctx.showIconOnly && _ctx.showIcon)
                        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.contactTypesDetails[_ctx.contactTypes[0]].icon), {
                            key: 0,
                            class: "icon"
                        }))
                        : _createCommentVNode("", true)
                ]),
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString((!_ctx.showIconOnly || _ctx.alwaysShowLabel) ? _ctx.t.contactLabel : '') + " ", 1),
                    (_ctx.copiedToClipboardTooltip && _ctx.contactTypes[0] === _ctx.AvailableContactTypes.SMS)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.t.copiedToClipboard), 1))
                        : _createCommentVNode("", true)
                ]),
                _: 1
            }, 8, ["variety", "class", "compact", "wide", "onClick"]))
            : _createCommentVNode("", true),
        _createVNode(_component_ContactInstructorHandler, {
            t: _ctx.t,
            "show-modal": _ctx.canContactInstructorEmail === true,
            "requester-name": _ctx.requesterName,
            "requester-email": _ctx.requesterEmail,
            "instructor-pid": _ctx.instructorPid,
            "instructor-display-name": _ctx.instructorDisplayName,
            "instructor-profile-photo-url": _ctx.instructorProfilePhotoUrl,
            "instructor-location": _ctx.instructorLocation,
            "recaptcha-key": _ctx.recaptchaKey,
            onClosed: _cache[0] || (_cache[0] = ($event) => (_ctx.canContactInstructorEmail = false)),
            onOnSendMessage: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('on-send-message', $event))),
            "onToast:message": _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('toast:message', $event))),
            "onRecaptcha:failure": _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('recaptcha:failure', $event)))
        }, null, 8, ["t", "show-modal", "requester-name", "requester-email", "instructor-pid", "instructor-display-name", "instructor-profile-photo-url", "instructor-location", "recaptcha-key"])
    ]));
}
