import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "item-container" };
const _hoisted_2 = { class: "review-card" };
const _hoisted_3 = { class: "review__content" };
const _hoisted_4 = { class: "review__student-avatar" };
const _hoisted_5 = ["src"];
const _hoisted_6 = {
    key: 1,
    class: "default-student-avatar"
};
const _hoisted_7 = { class: "student-initials" };
const _hoisted_8 = { class: "review-comments" };
const _hoisted_9 = {
    key: 0,
    class: "review__class-name z-h4"
};
const _hoisted_10 = {
    key: 1,
    class: "review__class-name z-h5"
};
const _hoisted_11 = {
    key: 0,
    class: "review__details f-inter z-b3"
};
const _hoisted_12 = {
    key: 1,
    class: "review__details z-b3"
};
const _hoisted_13 = { class: "review__footer" };
const _hoisted_14 = {
    key: 0,
    class: "review__student-name f-inter z-b2"
};
const _hoisted_15 = {
    key: 1,
    class: "review__student-name z-b3"
};
const _hoisted_16 = {
    key: 2,
    class: "review__date f-inter z-b3"
};
const _hoisted_17 = {
    key: 3,
    class: "review__date z-b3"
};
import { computed, ref } from 'vue';
import Rating from './Rating';
import { currentUserLocale } from "@ts/Util/i18n";
import { theme, ThemeType } from '@ts/Util/theme';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ReviewRedesign',
    props: {
        review: {
            type: Object,
            required: true
        },
        t: {
            type: Object,
            default: () => ({
                at: 'at',
            })
        }
    },
    setup(__props) {
        const props = __props;
        const reviewDateText = computed(() => {
            const dateFormatOpts = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
            return new Intl.DateTimeFormat(currentUserLocale(), dateFormatOpts).format(new Date(props.review.created));
        });
        const reviewTimeText = computed(() => {
            const timeFormatOpts = { hour: 'numeric', minute: '2-digit' };
            return new Intl.DateTimeFormat(currentUserLocale(), timeFormatOpts).format(new Date(props.review.created));
        });
        const firstName = ref(props.review.first_name);
        const lastName = ref(props.review.last_name);
        const studentInitials = computed(() => {
            const initials = `${firstName.value.charAt(0)}${lastName.value.charAt(0)}`;
            return initials.toUpperCase();
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createVNode(_unref(Rating), {
                            score: __props.review.rating
                        }, null, 8, ["score"]),
                        _createElementVNode("div", _hoisted_4, [
                            (__props.review.profile_photo_url)
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    src: __props.review.profile_photo_url + '?w=40&h=40&fit=crop&auto=format,compress 2x'
                                }, null, 8, _hoisted_5))
                                : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                    _createElementVNode("span", _hoisted_7, _toDisplayString(studentInitials.value), 1)
                                ]))
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                            (__props.review.class_title && _unref(theme) === _unref(ThemeType).Zumba)
                                ? (_openBlock(), _createElementBlock("h4", _hoisted_9, _toDisplayString(__props.review.class_title), 1))
                                : (_openBlock(), _createElementBlock("h5", _hoisted_10, _toDisplayString(__props.review.class_title), 1))
                        ]),
                        (__props.review.comment && _unref(theme) === _unref(ThemeType).Zumba)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(__props.review.comment), 1))
                            : (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(__props.review.comment), 1)),
                        _createElementVNode("div", _hoisted_13, [
                            (_unref(theme) === _unref(ThemeType).Zumba)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(__props.review.display_name), 1))
                                : (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(__props.review.display_name), 1)),
                            (_unref(theme) === _unref(ThemeType).Zumba)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(reviewDateText.value) + " " + _toDisplayString(__props.t.at) + " " + _toDisplayString(reviewTimeText.value), 1))
                                : (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(reviewDateText.value) + " " + _toDisplayString(__props.t.at) + " " + _toDisplayString(reviewTimeText.value), 1))
                        ])
                    ])
                ])
            ]));
        };
    }
});
