import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import StarSvg from '@bx-icons/solid/bxs-star.svg';
import HeartFilledSvg from '@bx-icons/solid/bxs-heart.svg';
import HeartSvg from '@bx-icons/regular/bx-heart.svg';
import GiftSvg from '@bx-icons/regular/bx-gift.svg';
import PencilSvg from '@bx-icons/regular/bx-pencil.svg';
import WebSvg from '@icons/web.svg';
import { validateTranslations } from '@ts/Util/i18n';
import ZButton from '@components/Core/ZButton.vue';
import { csrfToken } from '@ts/Util/security';
import { post } from '@ts/Util/http';
import ContactButton from '@components/ContactButton/ContactButton.vue';
import ContactButtonHandler from '@components/ContactButton/ContactButtonHandler.vue';
import ProfilePicture, { BrandColors } from '@modules/InstructorPage/ProfilePicture.vue';
import Social from '@modules/InstructorPage/Social.vue';
import SocialModal from '@modules/InstructorPage/SocialModal.vue';
import Tag from '@components/Tags/Tag.vue';
import InstructorNameModal from '@modules/InstructorPage/InstructorNameModal.vue';
import MyPageURLModalHandler from '@modules/InstructorPage/MyPageURLModalHandler.vue';
import WebsiteModal from '@modules/InstructorPage/WebsiteModal.vue';
import IcExport from '@bx-icons/regular/bx-export.svg';
import { interpolate } from '@ts/Util/text';
import ContactModal from '@modules/InstructorPage/ContactModal.vue';
import Message from '@bx-icons/regular/bx-envelope.svg';
import Whatsapp from '@bx-icons/logos/bxl-whatsapp.svg';
import IcPost from '@bx-icons/regular/bx-message-square-detail.svg';
import FacebookIcon from '@bx-icons/logos/bxl-facebook-circle.svg';
import LinkedinIcon from '@bx-icons/logos/bxl-linkedin.svg';
import InstagramIcon from '@bx-icons/logos/bxl-instagram-alt.svg';
import TiktokIcon from '@bx-icons/logos/bxl-tiktok.svg';
import SpotifyIcon from '@bx-icons/logos/bxl-spotify.svg';
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue';
import ShareModal from '@components/Sharing/ShareModal.vue';
import { QRCodeShareType } from '@components/Sharing/QRCodeModal.vue';
import { mixpanelServiceTrack } from '@ts/Util/analytics';
import QRCodeSVG from "@bx-icons/regular/bx-qr.svg";
import QRCodeModal from '@components/Sharing/QRCodeModal.vue';
import { isMobile } from '@ts/Util/responsiveness';
import { theme, ThemeType } from '@ts/Util/theme';
const translationDefaults = {
    websiteTitle: 'Website',
    leaveATipLabel: 'Send a thank you',
    leaveATipLinkTitle: 'Leave a tip',
    ratings: 'ratings',
    save: 'Save',
    following: 'Following',
    contactLabel: 'Contact',
    copiedToClipboard: 'Copied !',
    socialLabel: 'Social',
    tippingLabel: 'Tipping',
    setUp: 'Set up',
    on: 'On',
    off: 'Off',
    instructorNameModal: {
        instructions: 'Choose the name to display on your instructor page',
        title: 'Edit Name',
        yourName: 'Your name',
        requiredError: 'Name is required',
        save: 'Save',
        cancel: 'Cancel',
    },
    websiteModal: {
        instructions: 'Add a link to another webpage you want visitors to see.',
        title: 'Additional Webpage',
        pageLink: 'Page link',
        pageTitle: 'Page title',
        save: 'Save',
        cancel: 'Cancel',
    },
    profilePicture: {
        profileImageAlt: "Instructor picture",
        modal: {
            instructions: 'Choose a picture that shows off your face, smile, and glowing personality. This photo will show everywhere your classes appear (including Zumba, STRONG, and other platforms).',
            title: 'Instructor Photo',
            addPhoto: 'Add Photo',
            add: 'Add',
            deletePhoto: 'Delete Photo',
            delete: 'Delete',
            save: 'Save',
            cancel: 'Cancel',
            hint: 'We recommend a square 400x400px image, .jpg or .png',
        }
    },
    myPageUrl: 'My Page URL',
    myPageUrlModal: {
        title: 'My Page URL',
        shortname: 'Username',
        save: 'Save',
        cancel: 'Cancel',
        description: 'Enter your preferred username to customize the URL of this instructor page.',
        urlEditRequirementsHeader: 'Please be sure that your username:',
        urlEditRequirements1: 'begins and ends with a letter or number',
        urlEditRequirements2: 'is unique to you',
        baseUrl: 'https://www.zumba.com/p/{0}',
        errorCustomURL: 'The custom URL must be between 4-40 characters, and can only contain letters and numbers.',
        errorCustomURLTaken: "This custom URL is already taken please select another",
        confirmModal: {
            confirmNewUrl: 'Confirm new URL',
            back: 'Back',
            confirm: 'Confirm',
            baseUrl: 'https://www.zumba.com/p/{0}',
            uponConfirmation: 'Upon confirmation, your URL will be changed and you can modify your instructor page URL {0} more times.',
            uponConfirmationOne: 'Upon confirmation, your URL will be changed and you can modify your instructor page URL {0} more time.',
            uponConfirmationNoMore: 'Upon confirmation, your URL will be changed. You will not be able to edit this URL once it is changed.',
        }
    },
    share: 'share',
    licenseToTeach: 'License to teach',
    viewCredentials: 'View Credentials',
    additionalLicensesCertifications: 'Additional Licenses and Certifications',
    classesPosted: 'Classes Posted',
    licensesCertifications: 'Licenses & Certifications',
    profileImageAlt: 'Instructor picture',
    instructorSinceLabel: 'Instructor since',
    requiredFieldError: "Required Field.",
    emailError: "You must enter a valid email address to continue.",
};
export var Brands;
(function (Brands) {
    Brands["STRONG"] = "strong";
    Brands["ZUMBA"] = "zumba";
})(Brands || (Brands = {}));
export default defineComponent({
    name: 'InstructorCover',
    components: {
        StarSvg,
        WebSvg,
        GiftSvg,
        ZButton,
        HeartFilledSvg,
        HeartSvg,
        ContactButton,
        ContactButtonHandler,
        ProfilePicture,
        SocialModal,
        Social,
        Tag,
        PencilSvg,
        InstructorNameModal,
        MyPageURLModalHandler,
        WebsiteModal,
        IcExport,
        ContactModal,
        Message,
        Whatsapp,
        IcPost,
        FacebookIcon,
        LinkedinIcon,
        InstagramIcon,
        TiktokIcon,
        SpotifyIcon,
        HorizontalSeparator,
        ShareModal,
        QRCodeSVG,
        QRCodeModal
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        editMode: {
            type: Boolean,
            default: () => false
        },
        brand: {
            type: String,
            required: true,
        },
        followInstructorAction: {
            type: String,
            required: true
        },
        specialties: {
            type: Object,
            default: () => { }
        },
        instructorDisplayName: {
            type: String,
            required: false,
            default: ''
        },
        instructorProfilePhotoUrl: {
            type: String,
            required: false,
            default: ''
        },
        instructorProfileShortname: {
            type: String,
            required: false,
            default: ''
        },
        instructorLocation: {
            type: String,
            required: true
        },
        instructorJoinDate: {
            type: String,
            required: true
        },
        instructorRatingScore: {
            type: Number,
            required: true
        },
        instructorFollowed: {
            type: Boolean,
            required: true
        },
        instructorSocials: {
            type: Object,
            required: true
        },
        instructorInstructorType: {
            type: String,
            required: true
        },
        instructorWebsite: {
            type: Object,
            required: false,
            default: () => ({ page_url: '', title: '' })
        },
        instructorTippingEnabled: {
            type: Boolean,
            required: true
        },
        instructorTipping: {
            type: Boolean,
            required: true
        },
        instructorTipEmail: {
            type: String,
            default: ''
        },
        instructorShareEnabled: {
            type: Boolean,
            required: true
        },
        instructorHasEmail: {
            type: Boolean,
            required: true
        },
        instructorHasWhatsApp: {
            type: Boolean,
            required: true
        },
        instructorHasPhone: {
            type: Boolean,
            required: true
        },
        instructorEmail: {
            type: String,
            required: true
        },
        instructorWhatsApp: {
            type: String,
            required: true
        },
        instructorPhone: {
            type: String,
            required: true
        },
        instructorNumReviews: {
            type: Number,
            required: true
        },
        detailsInstructorAction: {
            type: String,
            default: () => '',
        },
        showFollowIcon: {
            type: Boolean,
            required: true
        },
        savePhotoAction: {
            type: String,
            required: false,
            default: ''
        },
        websiteTitleScraperAction: {
            type: String,
            required: false,
            default: ''
        },
        instructorPageUrlDisableEdit: {
            type: Boolean,
            default: false,
        },
        classManagementSettings: {
            type: String,
            required: true,
        },
        saveUrlAction: {
            type: String,
            required: true,
            default: ''
        },
        isUrlAvailableAction: {
            type: String,
            required: true,
            default: ''
        },
        userPid: {
            type: String,
            required: true,
        },
        shareData: {
            type: Object,
            required: true
        },
        fbAppId: {
            type: String,
            required: true
        },
        isLoggedIn: {
            type: Boolean,
            required: true,
        },
        followInstructorLink: {
            type: String,
            required: true
        },
        followImmediately: {
            type: Boolean,
            default: false
        },
        instructorPid: {
            type: String,
            required: true
        },
        useAsyncContactButton: {
            type: Boolean,
            default: true
        },
        instructorContactAction: {
            type: String,
            required: true,
        },
        show: {
            type: Boolean,
            default: false
        },
        profilePhotoUrl: {
            type: String,
            default: '',
        },
        style: {
            type: Object,
            default: () => ({})
        },
        defaultLicenses: {
            type: Array,
            required: true,
        },
        customizedLicensesText: {
            type: String,
            required: true,
        },
        requesterName: {
            type: String,
            default: ''
        },
        requesterEmail: {
            type: String,
            default: ''
        },
        recaptchaKey: {
            type: String,
            default: ''
        },
        dialCodes: {
            type: Object,
            required: true,
        },
        enableNoomBadge: {
            type: Boolean,
            default: false
        },
        memberView: {
            type: Boolean,
            default: false
        },
    },
    emits: ['tip-modal:open', 'toast:message'],
    setup(props) {
        const instructorState = reactive({
            email: props.instructorEmail,
            display_name: props.instructorDisplayName,
            profile_shortname: props.instructorProfileShortname,
            profile_photo_url: props.instructorProfilePhotoUrl,
            website: props.instructorWebsite || '',
            location: props.instructorLocation,
            rating_score: props.instructorRatingScore,
            num_reviews: props.instructorNumReviews,
            error: ''
        });
        const instructorPageUrlDisableEditState = ref(props.instructorPageUrlDisableEdit);
        const instructorTypeTagVariety = (index) => {
            switch (index) {
                case 0:
                    return 'featured';
                case 1:
                    return 'information';
                case 2:
                    return 'success';
                case 3:
                    return 'warning';
                default:
                    break;
            }
        };
        const profileURL = computed(() => {
            if (instructorState.profile_shortname) {
                return interpolate(props.t.baseUrl, [instructorState.profile_shortname ?? '']);
            }
            return interpolate(props.t.baseUrl, [instructorState.display_name.toLowerCase().replace(/\s/g, '-')]) + '/' + props.userPid;
        });
        const websiteTitle = computed(() => {
            const maxTitleLength = 25;
            const title = instructorState?.website?.title || instructorState?.website?.page_url;
            if (title.length > maxTitleLength) {
                return title.substring(0, maxTitleLength) + '...';
            }
            return title;
        });
        const modals = reactive({
            name: false,
            website: false,
            my_page_url: false,
            share: false,
            qr: false
        });
        const handleSavedName = async (e) => {
            let response = await post(props.detailsInstructorAction, csrfToken(), {
                display_name: e.value
            });
            if (response.ok) {
                instructorState.display_name = e.value;
                modals.name = false;
                return;
            }
            throw Error('Error while saving instructor name');
        };
        const handleSavedWebsite = async (e) => {
            let response = await post(props.detailsInstructorAction, csrfToken(), {
                website_title: e.title,
                website_url: e.page_url
            });
            if (response.ok) {
                instructorState.website = {
                    page_url: e.page_url,
                    title: e.title,
                };
                modals.website = false;
                return;
            }
            throw Error('Error while saving instructor website');
        };
        const formatDate = (dateTime) => {
            if (!dateTime) {
                return null;
            }
            const date = new Date(dateTime);
            return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
        };
        const membershipTypeLabel = computed(() => {
            const membershipType = props.t.instructorSinceLabel;
            let joinDate = props.instructorJoinDate ? formatDate(props.instructorJoinDate) : null;
            return !membershipType ? '' : membershipType + " " + joinDate;
        });
        const isInterFont = computed(() => (theme.value === ThemeType.Zumba));
        const isFollowing = ref(props.instructorFollowed);
        const follow = async (follow) => {
            const response = await post(props.followInstructorAction, csrfToken(), {
                instructorPID: props.instructorPid,
                following: follow,
            });
            if (response.ok) {
                isFollowing.value = follow;
                return;
            }
            throw Error('Error while following instructor');
        };
        const followClick = async () => {
            if (!props.isLoggedIn) {
                window.location.href = props.followInstructorLink;
                return;
            }
            follow(!isFollowing.value);
        };
        const themeColor = computed(() => {
            if (props.editMode) {
                return '';
            }
            return BrandColors[props.brand];
        });
        const instructorTypeLabels = computed(() => {
            if (props.editMode) {
                return '';
            }
            return props.instructorInstructorType.split(' ');
        });
        const profilePictureBackgroundClass = computed(() => {
            if (props.editMode) {
                return '';
            }
            const className = `profile-image-container-background-${props.brand}`;
            return className;
        });
        const hasSocialLinks = ref(!Object.values(props.instructorSocials).every(l => !l.length));
        const networks = ref([
            'facebook', 'instagram', 'spotify', 'tiktok', 'linkedin',
        ]);
        const showSocialsModal = ref(false);
        const showContactModal = ref(false);
        const openMyPageURLModal = () => {
            if (instructorPageUrlDisableEditState.value) {
                return;
            }
            modals['my_page_url'] = !modals['my_page_url'];
        };
        const onSaveUrl = ({ shortname, requestsLeft }) => {
            instructorState.profile_shortname = shortname;
            instructorPageUrlDisableEditState.value = requestsLeft === 0;
            modals['my_page_url'] = !modals['my_page_url'];
        };
        onMounted(() => {
            if (props.followImmediately && !isFollowing.value) {
                follow(true);
            }
        });
        const mixpanelReported = ref(false);
        const mixpanelTrack = () => {
            const referrer = document.referrer;
            // track redirects from the Clarity flow
            if (referrer.includes('/training/register') && !mixpanelReported.value) {
                mixpanelReported.value = true;
                mixpanelServiceTrack('TrackContactedTrainingInstructor', {
                    pageName: 'instructor_page'
                });
            }
        };
        const handleShareButtonClick = () => {
            const isMobileBrowser = isMobile();
            const hasNativeShare = !!navigator.share;
            if (isMobileBrowser && hasNativeShare) {
                // Trigger native share directly
                navigator.share({
                    url: props.shareData?.url
                }).catch(() => { });
            }
            else {
                // Open the modal
                modals.share = true;
            }
        };
        return {
            isInterFont,
            QRCodeShareType,
            showSocialsModal,
            hasSocialLinks,
            formatDate,
            membershipTypeLabel,
            isFollowing,
            followClick,
            modals,
            onSaveUrl,
            instructorPageUrlDisableEditState,
            instructorTypeTagVariety,
            openMyPageURLModal,
            handleSavedName,
            handleSavedWebsite,
            instructorState,
            themeColor,
            instructorTypeLabels,
            profilePictureBackgroundClass,
            Brands,
            networks,
            profileURL,
            showContactModal,
            websiteTitle,
            mixpanelTrack,
            mixpanelReported,
            handleShareButtonClick
        };
    }
});
