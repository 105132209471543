import { defineComponent, ref, computed } from 'vue';
import { validateTranslations, currentUserLocale } from "@ts/Util/i18n";
import { interpolate } from '@ts/Util/text';
import AboutModal from '@modules/InstructorPage/AboutModal.vue';
import PencilIcon from '@bx-icons/regular/bx-pencil.svg';
import { default as ProfileBadges } from "@modules/InstructorPage/ProfileBadges.vue";
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue';
import { isMobileReactive } from '@ts/Util/responsiveness';
const translationDefaults = {
    about: '',
    bio: '',
    licensesCertifications: '',
    memberSince: '',
    since: '',
    noBioPosted: '',
    add: '',
    achievements: '',
    readMore: '',
    readLess: '',
    viewAllAchievements: '',
    sinceText: '',
    viewLessAchievements: '',
    instructor: ''
};
export default defineComponent({
    name: 'About',
    components: {
        AboutModal,
        PencilIcon,
        ProfileBadges,
        HorizontalSeparator
    },
    props: {
        t: {
            type: Object,
            default: () => translationDefaults,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        editMode: {
            type: Boolean,
            default: () => false
        },
        biography: {
            type: String,
            required: false,
            default: ''
        },
        memberships: {
            type: Array,
            required: false,
            default: () => ([])
        },
        detailsInstructorAction: {
            type: String,
            default: '',
        },
        about: {
            type: String,
            required: false,
            default: ''
        },
        licenses: {
            type: String,
            required: false,
            default: ''
        },
        instructorBadges: {
            type: Array,
            required: false,
            default: () => ([])
        },
        enableNoomBadge: {
            type: Boolean,
            default: false
        },
        licenseBadges: {
            type: Array,
            required: false,
            default: () => ([])
        }
    },
    setup(props) {
        const showEditModal = ref(false);
        const formatDate = (dateTime) => {
            if (!dateTime) {
                return null;
            }
            const date = new Date(dateTime);
            return date.toLocaleDateString(currentUserLocale(), { month: "short", year: "numeric" });
        };
        const sinceYearMemberships = computed(() => {
            const data = {};
            props.memberships.forEach((membership) => {
                data[membership.membership_type] = interpolate(props.t.sinceText, [formatDate(membership.start_date)]);
            });
            return data;
        });
        const moreText = ref(false);
        const isMobile = isMobileReactive();
        const maxBioLength = computed(() => isMobile.value ? 250 : 370);
        const showMoreLink = computed(() => props.biography.length > maxBioLength.value && !moreText.value);
        const truncatedBio = computed(() => {
            if (moreText.value) {
                return props.biography;
            }
            return props.biography.length > maxBioLength.value
                ? props.biography.slice(0, maxBioLength.value) + '...'
                : props.biography;
        });
        const toggleMoreText = () => {
            moreText.value = !moreText.value;
        };
        const initialVisibleRecords = 8;
        const visibleRecords = ref(initialVisibleRecords);
        const visibleBadges = computed(() => props.instructorBadges.slice(0, visibleRecords.value));
        const showMore = () => {
            visibleRecords.value += props.instructorBadges.length - initialVisibleRecords;
        };
        const showLess = () => {
            visibleRecords.value = initialVisibleRecords;
        };
        const showViewAllAchievements = computed(() => visibleRecords.value < props.instructorBadges.length);
        const showViewLessAchievements = computed(() => visibleRecords.value > initialVisibleRecords);
        return {
            formatDate,
            showEditModal,
            toggleMoreText,
            showMoreLink,
            moreText,
            visibleBadges,
            showViewAllAchievements,
            showMore,
            truncatedBio,
            showLess,
            showViewLessAchievements,
            sinceYearMemberships
        };
    },
});
