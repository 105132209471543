import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveDynamicComponent as _resolveDynamicComponent } from "vue";
const _hoisted_1 = { class: "instructor-container" };
const _hoisted_2 = { class: "instructor-cover" };
const _hoisted_3 = { class: "instructor-type-tags" };
const _hoisted_4 = { class: "instructor-details" };
const _hoisted_5 = { class: "instructor-name instructor-name--redesign" };
const _hoisted_6 = { class: "instructor-details-container" };
const _hoisted_7 = { class: "instructor-details-list instructor-details-list--redesign" };
const _hoisted_8 = { class: "instructor-details-list instructor-details-list--redesign ratings" };
const _hoisted_9 = { class: "instructor-details-container-mobile" };
const _hoisted_10 = { class: "instructor-details-list instructor-details-list--redesign" };
const _hoisted_11 = { class: "instructor-details-list instructor-details-list--redesign" };
const _hoisted_12 = { class: "instructor-details-list mobile-list instructor-details-list--redesign rating-mobile" };
const _hoisted_13 = { href: "#instructor-reviews" };
const _hoisted_14 = {
    key: 1,
    class: "instructor-share-button-mobile cta-container"
};
const _hoisted_15 = { class: "fitness_types fitness_types--redesign" };
const _hoisted_16 = {
    key: 0,
    class: "licenses-and-certs-column"
};
const _hoisted_17 = {
    key: 0,
    class: "licenses-and-certs-list"
};
const _hoisted_18 = {
    key: 2,
    class: "instructor-details-editable"
};
const _hoisted_19 = { class: "editable-row" };
const _hoisted_20 = { class: "row-label f-inter" };
const _hoisted_21 = { class: "row-actions f-inter" };
const _hoisted_22 = { class: "editable-row" };
const _hoisted_23 = { class: "row-label f-inter" };
const _hoisted_24 = { class: "row-actions" };
const _hoisted_25 = { class: "my-page-url-preview" };
const _hoisted_26 = { class: "editable-row" };
const _hoisted_27 = { class: "row-label f-inter" };
const _hoisted_28 = { class: "social-label" };
const _hoisted_29 = { class: "row-actions" };
const _hoisted_30 = { class: "editable-row" };
const _hoisted_31 = { class: "row-label f-inter" };
const _hoisted_32 = { class: "row-actions f-inter" };
const _hoisted_33 = ["href", "title"];
const _hoisted_34 = {
    key: 0,
    class: "editable-row"
};
const _hoisted_35 = { class: "row-label f-inter" };
const _hoisted_36 = ["href"];
const _hoisted_37 = { class: "row-actions f-inter" };
const _hoisted_38 = {
    key: 0,
    class: "toggle-label"
};
const _hoisted_39 = ["href"];
const _hoisted_40 = { key: 1 };
const _hoisted_41 = {
    key: 1,
    class: "social"
};
const _hoisted_42 = { class: "website-action" };
const _hoisted_43 = ["href", "title"];
const _hoisted_44 = {
    key: 5,
    class: "cta-container hide-mobile cta-container--redesign"
};
const _hoisted_45 = { class: "desktop-buttons-container" };
const _hoisted_46 = {
    key: 6,
    class: "instructor-share-button hide-mobile"
};
const _hoisted_47 = { class: "instructor-details-actions" };
const _hoisted_48 = { class: "cta-container-mobile" };
const _hoisted_49 = { key: 0 };
const _hoisted_50 = { class: "cta-container" };
const _hoisted_51 = {
    key: 0,
    class: "buttons-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ProfilePicture = _resolveComponent("ProfilePicture");
    const _component_tag = _resolveComponent("tag");
    const _component_PencilSvg = _resolveComponent("PencilSvg");
    const _component_InstructorNameModal = _resolveComponent("InstructorNameModal");
    const _component_StarSvg = _resolveComponent("StarSvg");
    const _component_HorizontalSeparator = _resolveComponent("HorizontalSeparator");
    const _component_IcExport = _resolveComponent("IcExport");
    const _component_ZButton = _resolveComponent("ZButton");
    const _component_QRCodeSVG = _resolveComponent("QRCodeSVG");
    const _component_IcPost = _resolveComponent("IcPost");
    const _component_Message = _resolveComponent("Message");
    const _component_Whatsapp = _resolveComponent("Whatsapp");
    const _component_ContactModal = _resolveComponent("ContactModal");
    const _component_MyPageURLModalHandler = _resolveComponent("MyPageURLModalHandler");
    const _component_SocialModal = _resolveComponent("SocialModal");
    const _component_WebsiteModal = _resolveComponent("WebsiteModal");
    const _component_social = _resolveComponent("social");
    const _component_HeartFilledSvg = _resolveComponent("HeartFilledSvg");
    const _component_HeartSvg = _resolveComponent("HeartSvg");
    const _component_contact_button_handler = _resolveComponent("contact-button-handler");
    const _component_GiftSvg = _resolveComponent("GiftSvg");
    const _component_ShareModal = _resolveComponent("ShareModal");
    const _component_QRCodeModal = _resolveComponent("QRCodeModal");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["instructor-cover-bg--redesign", { 'no-margin': _ctx.memberView }])
    }, [
        _createElementVNode("section", {
            id: "instructor-details",
            class: _normalizeClass(["instructor-section instructor-cover--redesign", { 'edit-mode': _ctx.editMode }])
        }, [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", {
                    class: _normalizeClass(["instructor-img", _ctx.profilePictureBackgroundClass])
                }, [
                    _createVNode(_component_ProfilePicture, {
                        t: _ctx.t.profilePicture,
                        "profile-photo-url": _ctx.instructorState.profile_photo_url,
                        "background-class": "",
                        "theme-color": _ctx.themeColor,
                        "edit-mode": _ctx.editMode,
                        "save-photo-action": _ctx.savePhotoAction,
                        "onPhoto:updated": _cache[0] || (_cache[0] = (preview) => _ctx.instructorState.profile_photo_url = preview)
                    }, null, 8, ["t", "profile-photo-url", "theme-color", "edit-mode", "save-photo-action"])
                ], 2),
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.instructorTypeLabels, (instructorTypeLabel, index) => {
                            return (_openBlock(), _createElementBlock("div", { key: instructorTypeLabel }, [
                                (instructorTypeLabel !== '')
                                    ? (_openBlock(), _createBlock(_component_tag, {
                                        key: 0,
                                        variety: _ctx.instructorTypeTagVariety(index) + ' filled',
                                        "show-cancel": false
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(instructorTypeLabel), 1)
                                        ]),
                                        _: 2
                                    }, 1032, ["variety"]))
                                    : _createCommentVNode("", true)
                            ]));
                        }), 128))
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("h1", _hoisted_5, [
                            _createTextVNode(_toDisplayString(_ctx.instructorState.display_name) + " ", 1),
                            (_ctx.editMode)
                                ? (_openBlock(), _createBlock(_component_PencilSvg, {
                                    key: 0,
                                    class: "edit-mode-icon",
                                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.modals.name = !_ctx.modals.name))
                                }))
                                : _createCommentVNode("", true),
                            _createVNode(_component_InstructorNameModal, {
                                t: _ctx.t.instructorNameModal,
                                "instructor-name": _ctx.instructorState.display_name,
                                "show-modal": _ctx.modals.name,
                                error: _ctx.instructorState.error,
                                "handle-closed-modal": () => _ctx.modals.name = !_ctx.modals.name,
                                "onUpdate:name": _ctx.handleSavedName
                            }, null, 8, ["t", "instructor-name", "show-modal", "error", "handle-closed-modal", "onUpdate:name"])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("ul", _hoisted_7, [
                            (_ctx.instructorLocation)
                                ? (_openBlock(), _createElementBlock("li", {
                                    key: 0,
                                    class: _normalizeClass([_ctx.isInterFont ? 'f-inter' : 'z-b4'])
                                }, _toDisplayString(_ctx.instructorLocation), 3))
                                : _createCommentVNode("", true),
                            _createElementVNode("li", {
                                class: _normalizeClass([_ctx.isInterFont ? 'f-inter' : 'z-b4'])
                            }, _toDisplayString(_ctx.membershipTypeLabel), 3)
                        ]),
                        _createElementVNode("ul", _hoisted_8, [
                            (_ctx.instructorRatingScore)
                                ? (_openBlock(), _createElementBlock("li", {
                                    key: 0,
                                    class: _normalizeClass([_ctx.isInterFont ? 'f-inter' : 'z-b4', !_ctx.editMode ? 'rating-mobile' : ''])
                                }, [
                                    _createVNode(_component_StarSvg),
                                    _createTextVNode(" " + _toDisplayString(_ctx.instructorRatingScore), 1)
                                ], 2))
                                : _createCommentVNode("", true),
                            (_ctx.instructorNumReviews)
                                ? (_openBlock(), _createElementBlock("li", {
                                    key: 1,
                                    class: _normalizeClass([_ctx.isInterFont ? 'f-inter' : 'z-b4'])
                                }, [
                                    _createElementVNode("a", {
                                        href: "#instructor-reviews",
                                        class: _normalizeClass([_ctx.isInterFont ? 'f-inter' : 'z-b4'])
                                    }, _toDisplayString(_ctx.instructorNumReviews) + " " + _toDisplayString(_ctx.t.ratings), 3)
                                ], 2))
                                : _createCommentVNode("", true)
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("ul", _hoisted_10, [
                            (_ctx.instructorLocation)
                                ? (_openBlock(), _createElementBlock("li", {
                                    key: 0,
                                    class: _normalizeClass([_ctx.isInterFont ? 'f-inter' : 'z-b4'])
                                }, _toDisplayString(_ctx.instructorLocation), 3))
                                : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("ul", _hoisted_11, [
                            _createElementVNode("li", {
                                class: _normalizeClass([_ctx.isInterFont ? 'f-inter' : 'z-b4'])
                            }, _toDisplayString(_ctx.membershipTypeLabel), 3)
                        ]),
                        _createElementVNode("ul", _hoisted_12, [
                            (_ctx.instructorRatingScore)
                                ? (_openBlock(), _createElementBlock("li", {
                                    key: 0,
                                    class: _normalizeClass([_ctx.isInterFont ? 'f-inter' : 'z-b4'])
                                }, [
                                    _createVNode(_component_StarSvg),
                                    _createTextVNode(" " + _toDisplayString(_ctx.instructorRatingScore), 1)
                                ], 2))
                                : _createCommentVNode("", true),
                            (_ctx.instructorNumReviews)
                                ? (_openBlock(), _createElementBlock("li", {
                                    key: 1,
                                    class: _normalizeClass([_ctx.isInterFont ? 'f-inter' : 'z-b4'])
                                }, [
                                    _createElementVNode("a", _hoisted_13, _toDisplayString(_ctx.instructorNumReviews) + " " + _toDisplayString(_ctx.t.ratings), 1)
                                ], 2))
                                : _createCommentVNode("", true)
                        ])
                    ]),
                    (!_ctx.editMode)
                        ? (_openBlock(), _createBlock(_component_HorizontalSeparator, {
                            key: 0,
                            class: "section-divider"
                        }))
                        : _createCommentVNode("", true),
                    (_ctx.editMode)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createVNode(_component_ZButton, {
                                variety: "love",
                                compact: "desktop-only",
                                class: "share-button",
                                onClick: _withModifiers(_ctx.handleShareButtonClick, ["prevent"])
                            }, {
                                icon: _withCtx(() => [
                                    _createVNode(_component_IcExport)
                                ]),
                                default: _withCtx(() => [
                                    _createTextVNode(" " + _toDisplayString(_ctx.t.share), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_ZButton, {
                                variety: "love",
                                compact: "desktop-only",
                                class: "share-button",
                                onClick: _cache[2] || (_cache[2] = _withModifiers(($event) => (_ctx.modals.qr = true), ["prevent"]))
                            }, {
                                icon: _withCtx(() => [
                                    _createVNode(_component_QRCodeSVG, { class: "qr-icon" })
                                ]),
                                _: 1
                            })
                        ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_15, [
                        (!_ctx.editMode)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(["licenses-and-certs-label headline", [_ctx.isInterFont ? 'f-inter' : 'z-b4']])
                                }, _toDisplayString(_ctx.t.licensesCertifications), 3),
                                _createElementVNode("div", null, [
                                    (!Boolean(_ctx.customizedLicensesText))
                                        ? (_openBlock(), _createElementBlock("ul", _hoisted_17, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.defaultLicenses, (license) => {
                                                return (_openBlock(), _createElementBlock("li", {
                                                    key: license.badge_slug,
                                                    class: _normalizeClass(["licenses-and-certs-list-item", [_ctx.isInterFont ? 'f-inter' : 'z-b4']])
                                                }, _toDisplayString(license.badge_name), 3));
                                            }), 128))
                                        ]))
                                        : (_openBlock(), _createElementBlock("span", {
                                            key: 1,
                                            class: _normalizeClass(["customized-licenses", [_ctx.isInterFont ? 'f-inter' : 'z-b4']])
                                        }, _toDisplayString(_ctx.customizedLicensesText), 3))
                                ])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    (_ctx.editMode)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                            _createElementVNode("div", _hoisted_19, [
                                _createElementVNode("div", _hoisted_20, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.t.contact), 1),
                                    _createVNode(_component_PencilSvg, {
                                        class: "edit-mode-icon icons hide-desktop",
                                        onClick: _cache[3] || (_cache[3] = _withModifiers(($event) => (_ctx.showContactModal = true), ["prevent"]))
                                    })
                                ]),
                                _createElementVNode("div", _hoisted_21, [
                                    _createVNode(_component_IcPost, {
                                        class: _normalizeClass(["edit-mode-icon icons", { 'disabled': !_ctx.instructorPhone }])
                                    }, null, 8, ["class"]),
                                    _createVNode(_component_Message, {
                                        class: _normalizeClass(["edit-mode-icon icons message-icon", { 'disabled': !_ctx.instructorEmail }])
                                    }, null, 8, ["class"]),
                                    _createVNode(_component_Whatsapp, {
                                        class: _normalizeClass(["edit-mode-icon icons", { 'disabled': !_ctx.instructorWhatsApp }])
                                    }, null, 8, ["class"]),
                                    _createVNode(_component_PencilSvg, {
                                        class: "edit-mode-icon icons hide-mobile",
                                        onClick: _cache[4] || (_cache[4] = _withModifiers(($event) => (_ctx.showContactModal = true), ["prevent"]))
                                    }),
                                    _createVNode(_component_ContactModal, {
                                        t: _ctx.t,
                                        show: _ctx.showContactModal,
                                        action: _ctx.detailsInstructorAction,
                                        "email-value": _ctx.instructorEmail,
                                        "whatsapp-value": _ctx.instructorWhatsApp,
                                        "phone-value": _ctx.instructorPhone,
                                        "dial-codes": _ctx.dialCodes,
                                        onClosed: _cache[5] || (_cache[5] = ($event) => (_ctx.showContactModal = false))
                                    }, null, 8, ["t", "show", "action", "email-value", "whatsapp-value", "phone-value", "dial-codes"])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_22, [
                                _createElementVNode("div", _hoisted_23, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.t.myPageUrl), 1),
                                    _createVNode(_component_PencilSvg, {
                                        class: _normalizeClass(["edit-mode-icon action-icon hide-desktop", { disabled: _ctx.instructorPageUrlDisableEditState }]),
                                        onClick: _cache[6] || (_cache[6] = ($event) => (_ctx.openMyPageURLModal()))
                                    }, null, 8, ["class"])
                                ]),
                                _createElementVNode("div", _hoisted_24, [
                                    _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.profileURL), 1),
                                    _createVNode(_component_PencilSvg, {
                                        class: _normalizeClass(["edit-mode-icon action-icon hide-mobile", { disabled: _ctx.instructorPageUrlDisableEditState }]),
                                        onClick: _cache[7] || (_cache[7] = ($event) => (_ctx.openMyPageURLModal()))
                                    }, null, 8, ["class"])
                                ]),
                                _createVNode(_component_MyPageURLModalHandler, {
                                    t: _ctx.t.myPageUrlModal,
                                    shortname: _ctx.instructorState.profile_shortname,
                                    "show-modal": _ctx.modals['my_page_url'],
                                    "save-url-action": _ctx.saveUrlAction,
                                    "is-url-available-action": _ctx.isUrlAvailableAction,
                                    onCancel: _cache[8] || (_cache[8] = ($event) => (_ctx.modals.my_page_url = !_ctx.modals.my_page_url)),
                                    onSave: _cache[9] || (_cache[9] = ($event) => (_ctx.onSaveUrl($event)))
                                }, null, 8, ["t", "shortname", "show-modal", "save-url-action", "is-url-available-action"])
                            ]),
                            _createElementVNode("div", _hoisted_26, [
                                _createElementVNode("div", _hoisted_27, [
                                    _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.t.socialLabel), 1),
                                    _createVNode(_component_PencilSvg, {
                                        class: "edit-mode-icon action-icon hide-desktop",
                                        onClick: _cache[10] || (_cache[10] = ($event) => (_ctx.showSocialsModal = true))
                                    })
                                ]),
                                _createElementVNode("div", _hoisted_29, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.networks, (network) => {
                                        return (_openBlock(), _createElementBlock("span", {
                                            key: network,
                                            class: _normalizeClass(["social-item", {
                                                    'withValue': !!_ctx.instructorSocials[network]
                                                }])
                                        }, [
                                            (_openBlock(), _createBlock(_resolveDynamicComponent(network + '-icon')))
                                        ], 2));
                                    }), 128)),
                                    _createVNode(_component_PencilSvg, {
                                        class: "edit-mode-icon action-icon hide-mobile",
                                        onClick: _cache[11] || (_cache[11] = ($event) => (_ctx.showSocialsModal = true))
                                    })
                                ]),
                                _createVNode(_component_SocialModal, {
                                    t: _ctx.t,
                                    action: _ctx.detailsInstructorAction,
                                    "social-links": _ctx.instructorSocials,
                                    show: _ctx.showSocialsModal,
                                    onClosed: _cache[12] || (_cache[12] = ($event) => (_ctx.showSocialsModal = false))
                                }, null, 8, ["t", "action", "social-links", "show"])
                            ]),
                            _createElementVNode("div", _hoisted_30, [
                                _createElementVNode("div", _hoisted_31, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.t.websiteTitle), 1),
                                    _createVNode(_component_PencilSvg, {
                                        class: "edit-mode-icon action-icon hide-desktop",
                                        onClick: _cache[13] || (_cache[13] = ($event) => (_ctx.modals.website = !_ctx.modals.website))
                                    })
                                ]),
                                _createElementVNode("div", _hoisted_32, [
                                    _createElementVNode("a", {
                                        href: _ctx.instructorState.website?.page_url,
                                        title: _ctx.instructorState.website?.title,
                                        target: "_blank",
                                        rel: "noopener"
                                    }, _toDisplayString(_ctx.instructorState.website?.title || _ctx.instructorState.website?.page_url), 9, _hoisted_33),
                                    _createVNode(_component_PencilSvg, {
                                        class: "edit-mode-icon action-icon hide-mobile",
                                        onClick: _cache[14] || (_cache[14] = ($event) => (_ctx.modals.website = !_ctx.modals.website))
                                    }),
                                    _createVNode(_component_WebsiteModal, {
                                        t: _ctx.t.websiteModal,
                                        "website-title": _ctx.instructorState.website?.title,
                                        "website-url": _ctx.instructorState.website?.page_url,
                                        "show-modal": _ctx.modals.website,
                                        error: _ctx.instructorState.error,
                                        "handle-closed-modal": () => _ctx.modals.website = !_ctx.modals.website,
                                        "website-title-scraper-action": _ctx.websiteTitleScraperAction,
                                        "onUpdate:website": _ctx.handleSavedWebsite
                                    }, null, 8, ["t", "website-title", "website-url", "show-modal", "error", "handle-closed-modal", "website-title-scraper-action", "onUpdate:website"])
                                ])
                            ]),
                            (_ctx.instructorTippingEnabled)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                    _createElementVNode("div", _hoisted_35, [
                                        _createElementVNode("span", null, _toDisplayString(_ctx.t.tippingLabel), 1),
                                        (_ctx.instructorTipEmail)
                                            ? (_openBlock(), _createElementBlock("a", {
                                                key: 0,
                                                href: _ctx.classManagementSettings
                                            }, [
                                                _createVNode(_component_PencilSvg, { class: "edit-mode-icon action-icon hide-desktop" })
                                            ], 8, _hoisted_36))
                                            : _createCommentVNode("", true)
                                    ]),
                                    _createElementVNode("div", _hoisted_37, [
                                        (_ctx.instructorTipEmail)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString(_ctx.instructorTipping ? _ctx.t.on : _ctx.t.off), 1))
                                            : _createCommentVNode("", true),
                                        _createElementVNode("a", { href: _ctx.classManagementSettings }, [
                                            (_ctx.instructorTipEmail)
                                                ? (_openBlock(), _createBlock(_component_PencilSvg, {
                                                    key: 0,
                                                    class: "edit-mode-icon action-icon hide-mobile"
                                                }))
                                                : (_openBlock(), _createElementBlock("span", _hoisted_40, _toDisplayString(_ctx.t.setUp), 1))
                                        ], 8, _hoisted_39)
                                    ])
                                ]))
                                : _createCommentVNode("", true)
                        ]))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 3,
                            class: _normalizeClass(["social-section", { 'hide-mobile': !_ctx.hasSocialLinks && !Boolean(_ctx.instructorWebsite?.page_url) }])
                        }, [
                            (!_ctx.editMode)
                                ? (_openBlock(), _createBlock(_component_HorizontalSeparator, {
                                    key: 0,
                                    class: "section-divider hide-desktop"
                                }))
                                : _createCommentVNode("", true),
                            (_ctx.instructorWebsite?.page_url)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                                    _createElementVNode("div", {
                                        class: _normalizeClass(["website-label social-section-subhead", [_ctx.isInterFont ? 'f-inter' : 'z-b4']])
                                    }, _toDisplayString(_ctx.t.websiteTitle), 3),
                                    _createElementVNode("div", _hoisted_42, [
                                        _createElementVNode("a", {
                                            href: _ctx.instructorWebsite.page_url,
                                            title: _ctx.instructorWebsite.title,
                                            target: "_blank",
                                            rel: "noopener"
                                        }, [
                                            _createElementVNode("span", {
                                                class: _normalizeClass(["website-title", [_ctx.isInterFont ? 'f-inter' : 'z-b4']])
                                            }, _toDisplayString(_ctx.websiteTitle), 3)
                                        ], 8, _hoisted_43)
                                    ])
                                ]))
                                : _createCommentVNode("", true),
                            (_ctx.hasSocialLinks)
                                ? (_openBlock(), _createBlock(_component_social, {
                                    key: 2,
                                    t: _ctx.t,
                                    "social-links": _ctx.instructorSocials,
                                    size: "mobile-large"
                                }, null, 8, ["t", "social-links"]))
                                : _createCommentVNode("", true)
                        ], 2)),
                    (!_ctx.editMode)
                        ? (_openBlock(), _createBlock(_component_HorizontalSeparator, {
                            key: 4,
                            class: "section-divider"
                        }))
                        : _createCommentVNode("", true),
                    (!_ctx.editMode)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                            _createElementVNode("div", _hoisted_45, [
                                _createVNode(_component_ZButton, {
                                    variety: _ctx.isFollowing ? 'phoenix' : 'optimus',
                                    mode: _ctx.isFollowing ? 'dark' : 'light',
                                    class: _normalizeClass(["follow-desktop follow-button", { 'following': _ctx.isFollowing }]),
                                    compact: "desktop-only",
                                    onClick: _cache[15] || (_cache[15] = ($event) => (_ctx.followClick()))
                                }, {
                                    icon: _withCtx(() => [
                                        (_ctx.isFollowing && _ctx.showFollowIcon)
                                            ? (_openBlock(), _createBlock(_component_HeartFilledSvg, {
                                                key: 0,
                                                class: "following-icon"
                                            }))
                                            : (_ctx.showFollowIcon)
                                                ? (_openBlock(), _createBlock(_component_HeartSvg, {
                                                    key: 1,
                                                    class: "follow-icon"
                                                }))
                                                : _createCommentVNode("", true)
                                    ]),
                                    default: _withCtx(() => [
                                        _createTextVNode(" " + _toDisplayString(_ctx.isFollowing ? _ctx.t.saved : _ctx.t.save), 1)
                                    ]),
                                    _: 1
                                }, 8, ["variety", "mode", "class"])
                            ]),
                            _createVNode(_component_contact_button_handler, {
                                t: _ctx.t,
                                "instructor-pid": _ctx.instructorPid,
                                "has-phone": _ctx.instructorHasPhone,
                                "has-email": _ctx.instructorHasEmail,
                                "has-whatsapp": _ctx.instructorHasWhatsApp,
                                "instructor-contact-action": _ctx.instructorContactAction,
                                "instructor-display-name": _ctx.instructorDisplayName,
                                "instructor-profile-photo-url": _ctx.instructorProfilePhotoUrl,
                                "instructor-location": _ctx.instructorLocation,
                                "requester-name": _ctx.requesterName,
                                "requester-email": _ctx.requesterEmail,
                                "recaptcha-key": _ctx.recaptchaKey,
                                variety: "punisher",
                                class: "contact-button",
                                "always-show-label": "",
                                "show-icon": false,
                                compact: "desktop-only",
                                "onToast:message": _cache[16] || (_cache[16] = ($event) => (_ctx.$emit('toast:message', $event))),
                                "onRecaptcha:failure": _cache[17] || (_cache[17] = ($event) => (_ctx.$emit('toast:message', { type: 'danger', message: $event }))),
                                "onClicked:contact": _ctx.mixpanelTrack
                            }, null, 8, ["t", "instructor-pid", "has-phone", "has-email", "has-whatsapp", "instructor-contact-action", "instructor-display-name", "instructor-profile-photo-url", "instructor-location", "requester-name", "requester-email", "recaptcha-key", "onClicked:contact"]),
                            (_ctx.instructorShareEnabled)
                                ? (_openBlock(), _createBlock(_component_ZButton, {
                                    key: 0,
                                    variety: "punisher",
                                    class: "share-button",
                                    compact: "desktop-only",
                                    onClick: _withModifiers(_ctx.handleShareButtonClick, ["prevent"])
                                }, {
                                    icon: _withCtx(() => [
                                        _createVNode(_component_IcExport)
                                    ]),
                                    _: 1
                                }, 8, ["onClick"]))
                                : _createCommentVNode("", true),
                            (_ctx.instructorTipping && _ctx.instructorTippingEnabled)
                                ? (_openBlock(), _createBlock(_component_ZButton, {
                                    key: 1,
                                    variety: "punisher",
                                    compact: "desktop-only",
                                    class: "tip-button",
                                    onClick: _cache[19] || (_cache[19] = _withModifiers(($event) => (_ctx.$emit('tip-modal:open')), ["prevent"]))
                                }, {
                                    default: _withCtx(() => [
                                        _createElementVNode("a", {
                                            onClick: _cache[18] || (_cache[18] = _withModifiers(($event) => (_ctx.$emit('tip-modal:open')), ["prevent"]))
                                        }, [
                                            _createVNode(_component_GiftSvg)
                                        ])
                                    ]),
                                    _: 1
                                }))
                                : _createCommentVNode("", true)
                        ]))
                        : _createCommentVNode("", true),
                    (_ctx.editMode)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                            (_ctx.editMode)
                                ? (_openBlock(), _createBlock(_component_ZButton, {
                                    key: 0,
                                    variety: "love",
                                    compact: "desktop-only",
                                    class: "share-button",
                                    onClick: _withModifiers(_ctx.handleShareButtonClick, ["prevent"])
                                }, {
                                    icon: _withCtx(() => [
                                        _createVNode(_component_IcExport)
                                    ]),
                                    default: _withCtx(() => [
                                        _createTextVNode(" " + _toDisplayString(_ctx.t.share), 1)
                                    ]),
                                    _: 1
                                }, 8, ["onClick"]))
                                : _createCommentVNode("", true),
                            _createVNode(_component_ZButton, {
                                variety: "love",
                                compact: "desktop-only",
                                class: "share-button",
                                onClick: _cache[20] || (_cache[20] = _withModifiers(($event) => (_ctx.modals.qr = true), ["prevent"]))
                            }, {
                                icon: _withCtx(() => [
                                    _createVNode(_component_QRCodeSVG, { class: "qr-icon" })
                                ]),
                                _: 1
                            })
                        ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_47, [
                        _createElementVNode("div", _hoisted_48, [
                            (!_ctx.editMode)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
                                    _createVNode(_component_ZButton, {
                                        variety: _ctx.isFollowing ? 'phoenix' : 'optimus',
                                        mode: _ctx.isFollowing ? 'dark' : 'light',
                                        class: _normalizeClass(["follow-mobile follow-button", { 'following': _ctx.isFollowing }]),
                                        compact: "desktop-only",
                                        onClick: _cache[21] || (_cache[21] = ($event) => (_ctx.followClick()))
                                    }, {
                                        icon: _withCtx(() => [
                                            (_ctx.isFollowing && _ctx.showFollowIcon)
                                                ? (_openBlock(), _createBlock(_component_HeartFilledSvg, {
                                                    key: 0,
                                                    class: "following-icon"
                                                }))
                                                : (_ctx.showFollowIcon)
                                                    ? (_openBlock(), _createBlock(_component_HeartSvg, {
                                                        key: 1,
                                                        class: "follow-icon"
                                                    }))
                                                    : _createCommentVNode("", true)
                                        ]),
                                        default: _withCtx(() => [
                                            _createTextVNode(" " + _toDisplayString(_ctx.isFollowing ? _ctx.t.saved : _ctx.t.save), 1)
                                        ]),
                                        _: 1
                                    }, 8, ["variety", "mode", "class"])
                                ]))
                                : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_50, [
                            (!_ctx.editMode)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                                    _createVNode(_component_contact_button_handler, {
                                        class: "contact-button",
                                        t: _ctx.t,
                                        "instructor-pid": _ctx.instructorPid,
                                        "has-phone": _ctx.instructorHasPhone,
                                        "has-email": _ctx.instructorHasEmail,
                                        "has-whatsapp": _ctx.instructorHasWhatsApp,
                                        "always-show-label": true,
                                        "show-icon": false,
                                        "instructor-contact-action": _ctx.instructorContactAction,
                                        "instructor-display-name": _ctx.instructorDisplayName,
                                        "instructor-profile-photo-url": _ctx.instructorProfilePhotoUrl,
                                        "instructor-location": _ctx.instructorLocation,
                                        "requester-name": _ctx.requesterName,
                                        "requester-email": _ctx.requesterEmail,
                                        "recaptcha-key": _ctx.recaptchaKey,
                                        variety: "punisher",
                                        "onToast:message": _cache[22] || (_cache[22] = ($event) => (_ctx.$emit('toast:message', $event))),
                                        "onRecaptcha:failure": _cache[23] || (_cache[23] = ($event) => (_ctx.$emit('toast:message', { type: 'danger', message: $event }))),
                                        "onClicked:contact": _ctx.mixpanelTrack
                                    }, null, 8, ["t", "instructor-pid", "has-phone", "has-email", "has-whatsapp", "instructor-contact-action", "instructor-display-name", "instructor-profile-photo-url", "instructor-location", "requester-name", "requester-email", "recaptcha-key", "onClicked:contact"]),
                                    (_ctx.instructorShareEnabled)
                                        ? (_openBlock(), _createBlock(_component_ZButton, {
                                            key: 0,
                                            variety: "punisher",
                                            class: "mobile-button share-button",
                                            compact: "desktop-only",
                                            onClick: _withModifiers(_ctx.handleShareButtonClick, ["prevent"])
                                        }, {
                                            icon: _withCtx(() => [
                                                _createVNode(_component_IcExport)
                                            ]),
                                            _: 1
                                        }, 8, ["onClick"]))
                                        : _createCommentVNode("", true),
                                    (_ctx.instructorTipping && _ctx.instructorTippingEnabled)
                                        ? (_openBlock(), _createBlock(_component_ZButton, {
                                            key: 1,
                                            variety: "punisher",
                                            class: "mobile-button tip-button",
                                            compact: "desktop-only",
                                            onClick: _cache[24] || (_cache[24] = _withModifiers(($event) => (_ctx.$emit('tip-modal:open')), ["prevent"]))
                                        }, {
                                            icon: _withCtx(() => [
                                                _createVNode(_component_GiftSvg)
                                            ]),
                                            _: 1
                                        }))
                                        : _createCommentVNode("", true)
                                ]))
                                : _createCommentVNode("", true)
                        ])
                    ])
                ])
            ]),
            _createVNode(_component_ShareModal, {
                t: _ctx.t,
                "share-type": _ctx.QRCodeShareType.SHARE_INSTRUCTOR,
                "fb-app-id": _ctx.fbAppId,
                show: _ctx.modals.share,
                "share-data": _ctx.shareData,
                simplified: "",
                onClose: _cache[25] || (_cache[25] = ($event) => (_ctx.modals.share = false))
            }, null, 8, ["t", "share-type", "fb-app-id", "show", "share-data"]),
            (_ctx.shareData?.qrSRC)
                ? (_openBlock(), _createBlock(_component_QRCodeModal, {
                    key: 0,
                    t: _ctx.t,
                    show: _ctx.modals.qr,
                    "instructor-data": {
                        title: _ctx.instructorState.display_name,
                        location: _ctx.instructorState?.location,
                        membership_type: _ctx.membershipTypeLabel,
                        rating_score: _ctx.instructorState?.rating_score,
                        num_reviews: _ctx.instructorState?.num_reviews,
                    },
                    "qr-src": _ctx.shareData?.qrSRC ?? '',
                    "share-qr-url": _ctx.shareData.qrShareURL,
                    "share-type": _ctx.QRCodeShareType.SHARE_INSTRUCTOR,
                    onClose: _cache[26] || (_cache[26] = ($event) => (_ctx.modals.qr = false))
                }, null, 8, ["t", "show", "instructor-data", "qr-src", "share-qr-url", "share-type"]))
                : _createCommentVNode("", true)
        ], 2)
    ], 2));
}
