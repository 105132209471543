import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    id: "modal-content",
    class: "view-credentials-details"
};
const _hoisted_2 = { class: "credential-details" };
const _hoisted_3 = { class: "profile-img" };
const _hoisted_4 = { class: "inst-details" };
const _hoisted_5 = { class: "z-h4 instructor-name f-inter" };
const _hoisted_6 = { class: "instructor-details-list" };
const _hoisted_7 = {
    key: 0,
    class: "z-b3 f-inter location"
};
const _hoisted_8 = { class: "z-b3 f-inter location" };
const _hoisted_9 = { class: "license-certification" };
const _hoisted_10 = { class: "headline z-b4 f-inter" };
const _hoisted_11 = { class: "z-b3 f-inter" };
const _hoisted_12 = { class: "credentials" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ProfilePictureDefault = _resolveComponent("ProfilePictureDefault");
    const _component_HorizontalSeparator = _resolveComponent("HorizontalSeparator");
    const _component_profile_credentials = _resolveComponent("profile-credentials");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        show: _ctx.show,
        "close-when-click-on-background": "",
        "close-when-type-esc": "",
        "show-close-button": !_ctx.isMobile,
        "enable-scroll": "",
        "mobile-drawer": "",
        "custom-styles": _ctx.customModalStyles,
        onClose: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('close')))
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_ProfilePictureDefault, {
                            t: _ctx.t,
                            "profile-photo-url": _ctx.profilePhotoUrl,
                            style: { transform: 'scale(.8)' }
                        }, null, 8, ["t", "profile-photo-url"])
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.instructor.display_name), 1),
                        _createElementVNode("span", _hoisted_6, [
                            (_ctx.instructor.location)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.instructor.location), 1))
                                : _createCommentVNode("", true),
                            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.membershipTypeLabel), 1)
                        ])
                    ])
                ]),
                _createVNode(_component_HorizontalSeparator),
                _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.t.additionalLicensesCertifications), 1),
                    _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.licenses), 1)
                ]),
                _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_profile_credentials, {
                        t: _ctx.t,
                        license: _ctx.license,
                        education: _ctx.education,
                        milestone: _ctx.milestone,
                        "enable-noom-badge": _ctx.enableNoomBadge
                    }, null, 8, ["t", "license", "education", "milestone", "enable-noom-badge"])
                ])
            ])
        ]),
        _: 1
    }, 8, ["show", "show-close-button", "custom-styles"]));
}
