import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "instructor-contact-modal" };
const _hoisted_2 = { class: "modal-headline f-inter" };
const _hoisted_3 = { class: "instructions" };
const _hoisted_4 = { class: "main-container" };
const _hoisted_5 = { class: "actions-container" };
import { ref, computed, reactive } from 'vue';
import { csrfToken } from '@ts/Util/security';
import { post } from '@ts/Util/http';
import Modal from '@components/Core/Modal.vue';
import ZButton from '@components/Core/ZButton.vue';
import ZInput from '@components/Core/ZInput.vue';
import DialCodeInput from '@components/Core/DialCodeInput.vue';
import { theme, ThemeType } from "@ts/Util/theme";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ContactModal',
    props: {
        show: {
            type: Boolean,
            default: () => false
        },
        emailValue: {
            type: String,
            default: () => ''
        },
        whatsappValue: {
            type: String,
            default: () => ''
        },
        phoneValue: {
            type: String,
            default: () => ''
        },
        dialCodes: {
            type: Object,
            required: true,
        },
        action: {
            type: String,
            default: '',
        },
        t: {
            type: Object,
            default: () => ({
                contact: 'Contact',
                modalInstruction: 'Edit how students contact you. Please respond to inquiries within 24 hours.',
                inputEmail: 'Email',
                inputTextMessage: 'Text Message',
                inputPhoneErrorMessage: 'Please enter a valid phone number',
                inputDialCodeMessage: 'Please select a dial code'
            })
        }
    },
    emits: ['closed', 'save'],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        const props = __props;
        const emailValueRef = ref(props.emailValue);
        const whatsappValueRef = ref(props.whatsappValue || '');
        const phoneValueRef = ref(props.phoneValue || '');
        const state = reactive({
            saving: false,
            whatsappError: false,
            phoneError: false,
        });
        const doCancel = () => {
            emailValueRef.value = props.emailValue;
            whatsappValueRef.value = props.whatsappValue;
            phoneValueRef.value = props.phoneValue;
            emit('closed');
        };
        const formattedDialCodes = computed(() => {
            const formattedDialCodes = {};
            for (const [code, country] of Object.entries(props.dialCodes)) {
                formattedDialCodes[`+${code}`] = country;
            }
            return formattedDialCodes;
        });
        const buttonThemed = theme.value === ThemeType.Zumba ? 'plum' : 'phoenix';
        const isFormValid = () => {
            if (phoneValueRef.value && state.phoneError) {
                return false;
            }
            if (whatsappValueRef.value && state.whatsappError) {
                return false;
            }
            return true;
        };
        const doSave = async () => {
            state.saving = true;
            if (!isFormValid()) {
                state.saving = false;
                return;
            }
            try {
                const response = await post(props.action, csrfToken(), {
                    email: emailValueRef.value,
                    whatsapp: whatsappValueRef.value,
                    phone: phoneValueRef.value
                });
                if (response.ok) {
                    window.location.reload();
                }
            }
            catch (e) {
                console.error(e);
            }
            finally {
                state.saving = false;
            }
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(Modal, {
                show: __props.show,
                mode: 'light',
                "mobile-drawer": true,
                "show-close-button": false,
                "custom-styles": { modal: { 'padding': '2.5rem' } },
                onClosed: _cache[7] || (_cache[7] = ($event) => (emit('closed')))
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("h3", _hoisted_2, _toDisplayString(__props.t.contact), 1),
                        _createElementVNode("p", _hoisted_3, _toDisplayString(__props.t.modalInstructions), 1),
                        _createElementVNode("div", _hoisted_4, [
                            _createVNode(ZInput, {
                                modelValue: emailValueRef.value,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((emailValueRef).value = $event)),
                                class: "contact-input",
                                label: __props.t.inputEmail,
                                type: "email",
                                name: "email",
                                required: ""
                            }, null, 8, ["modelValue", "label"]),
                            _createVNode(DialCodeInput, {
                                modelValue: phoneValueRef.value,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((phoneValueRef).value = $event)),
                                class: "contact-input",
                                label: __props.t.inputTextMessage,
                                "dial-codes": formattedDialCodes.value,
                                saving: state.saving,
                                onValid: _cache[2] || (_cache[2] = ($event) => (state.phoneError = false)),
                                onInvalid: _cache[3] || (_cache[3] = ($event) => (state.phoneError = true))
                            }, null, 8, ["modelValue", "label", "dial-codes", "saving"]),
                            _createVNode(DialCodeInput, {
                                modelValue: whatsappValueRef.value,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((whatsappValueRef).value = $event)),
                                class: "contact-input",
                                label: "WhatsApp",
                                "dial-codes": formattedDialCodes.value,
                                saving: state.saving,
                                onValid: _cache[5] || (_cache[5] = ($event) => (state.whatsappError = false)),
                                onInvalid: _cache[6] || (_cache[6] = ($event) => (state.whatsappError = true))
                            }, null, 8, ["modelValue", "dial-codes", "saving"])
                        ]),
                        _createElementVNode("div", _hoisted_5, [
                            _createVNode(ZButton, {
                                class: "actions-container-button",
                                wide: "mobile-only",
                                variety: _unref(buttonThemed),
                                disabled: state.saving,
                                onClick: doCancel
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(__props.t.cancel), 1)
                                ]),
                                _: 1
                            }, 8, ["variety", "disabled"]),
                            _createVNode(ZButton, {
                                class: "actions-container-button",
                                wide: "mobile-only",
                                variety: "plum",
                                mode: "dark",
                                disabled: state.saving,
                                onClick: _withModifiers(doSave, ["prevent"])
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(__props.t.save), 1)
                                ]),
                                _: 1
                            }, 8, ["disabled"])
                        ])
                    ])
                ]),
                _: 1
            }, 8, ["show"]));
        };
    }
});
