import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "top-nav__container" };
const _hoisted_2 = ["onClick"];
import { ref, reactive, onMounted } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TopNav',
    props: {
        triggerElement: {
            type: String,
            required: true,
        },
        links: {
            type: Object,
            required: true,
        },
        enableRedesign: {
            type: Boolean,
            default: false
        },
        t: {
            type: Object,
            default: () => ({
                title: 'Reviews',
                showMore: 'Show More',
                details: 'Details',
                liveClasses: 'Live Classes',
                onDemands: 'On Demand',
                classPacks: 'Class Packs',
                about: 'About',
                instagram: 'Instagram',
                photos: 'Photos',
                ratings: 'Ratings',
            })
        }
    },
    setup(__props) {
        const props = __props;
        const active = ref(null);
        const visibleLinks = ref([]);
        const state = reactive({
            isSticky: false
        });
        onMounted(() => {
            addStickyObserver();
            viewerChecked();
            window.addEventListener('scroll', viewerChecked, { passive: true });
        });
        const scrollTo = (link) => {
            const element = document.getElementById(props.links[link]);
            if (!element) {
                return;
            }
            window.scrollTo({
                top: element.offsetTop - 100,
                behavior: "smooth"
            });
        };
        const addStickyObserver = () => {
            const navTrigger = document.getElementById(props.triggerElement);
            const observerOptions = { threshold: 0.1 };
            const toggleIsSticky = (entries) => {
                state.isSticky = entries[0].intersectionRatio < 0.1;
                if (state.isSticky && visibleLinks.value.length === 0) {
                    visibleLinks.value = Object.keys(props.links).filter((link) => {
                        const element = document.getElementById(props.links[link]);
                        return element && element.textContent?.trim() !== '';
                    });
                }
            };
            const observer = new IntersectionObserver(toggleIsSticky, observerOptions);
            if (!navTrigger)
                return;
            observer.observe(navTrigger);
        };
        const viewerChecked = () => {
            const visibleSections = visibleLinks.value.filter((link) => {
                const element = document.getElementById(props.links[link]);
                if (!element || element.innerHTML.trim() === '')
                    return false;
                const rect = element.getBoundingClientRect();
                const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
                return ((rect.top >= 2 && rect.top <= windowHeight) || (rect.bottom >= 2 && rect.bottom <= windowHeight));
            });
            if (visibleSections.length === 0) {
                active.value = '';
                return;
            }
            else if (visibleSections.length === 1) {
                active.value = visibleSections[0];
                scrollToActiveLink();
                return;
            }
            const desiredPosition = window.innerHeight / 2;
            let activeLink = null;
            let minDistance = Infinity;
            for (const link of visibleSections) {
                const elBounds = document.getElementById(props.links[link]).getBoundingClientRect();
                const distanceToTop = Math.abs(elBounds.top - desiredPosition);
                const distanceToBottom = Math.abs(elBounds.bottom - desiredPosition);
                const distance = Math.min(distanceToTop, distanceToBottom);
                if (elBounds.top <= desiredPosition && desiredPosition <= elBounds.bottom) {
                    activeLink = link;
                    break;
                }
                else if (distance < minDistance) {
                    minDistance = distance;
                    activeLink = link;
                }
            }
            active.value = activeLink;
            scrollToActiveLink();
        };
        const scrollToActiveLink = () => {
            const activeLink = document.querySelector('.top-nav__container .active');
            if (activeLink) {
                const container = document.querySelector('.top-nav__container');
                const containerWidth = container.clientWidth;
                const activeLinkWidth = activeLink.offsetWidth;
                const activeLinkOffsetLeft = activeLink.offsetLeft;
                const scrollLeft = activeLinkOffsetLeft - containerWidth / 2 + activeLinkWidth / 2;
                container.scrollLeft = scrollLeft;
            }
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["top-nav", {
                        'sticky': state.isSticky,
                        'top-nav__redesign': __props.enableRedesign
                    }])
            }, [
                _createElementVNode("div", _hoisted_1, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleLinks.value, (l) => {
                        return (_openBlock(), _createElementBlock("a", {
                            key: l,
                            class: _normalizeClass(["f-inter z-b3", {
                                    'active': l === active.value,
                                    'top-nav-tabs__redesign': __props.enableRedesign,
                                }]),
                            onClick: _withModifiers(($event) => (scrollTo(l)), ["prevent"])
                        }, _toDisplayString(__props.t[l]), 11, _hoisted_2));
                    }), 128))
                ])
            ], 2));
        };
    }
});
