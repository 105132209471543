import { defineComponent } from 'vue';
import { default as ProfileBadges } from "@modules/InstructorPage/ProfileBadges.vue";
import { validateTranslations } from "@ts/Util/i18n";
const translationDefaults = {
    licenseToTeach: 'License to Teach',
    licenses: 'licenses',
    education: 'Education',
    milestone: 'Milestone',
    profileImageAlt: 'Instructor picture'
};
export default defineComponent({
    name: 'ProfileCredentials',
    components: {
        ProfileBadges
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        license: {
            type: Array,
            required: true,
        },
        education: {
            type: Array,
            required: true,
        },
        milestone: {
            type: Array,
            required: true,
        },
        enableNoomBadge: {
            type: Boolean,
            default: false
        },
    },
});
