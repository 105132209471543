import { defineComponent, ref, watch, onMounted, computed } from 'vue';
import ZInput from '@components/Core/ZInput.vue';
import FormSelect from '@components/Core/FormSelect.vue';
export default defineComponent({
    name: 'DialCodeInput',
    components: {
        ZInput,
        FormSelect
    },
    props: {
        dialCodes: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            default: 'Phone'
        },
        modelValue: {
            type: String,
            default: ''
        },
        showIcon: {
            type: Boolean,
            default: false,
        },
        saving: {
            type: Boolean,
            default: false,
        },
        t: {
            type: Object,
            default: () => ({
                inputPhoneErrorMessage: 'Please enter a valid phone number',
                inputDialCodeMessage: 'Please select a dial code'
            })
        }
    },
    emits: ['update:modelValue', 'valid', 'invalid'],
    setup(props, { emit }) {
        const selectedDialCode = ref('');
        const displayCountryCode = ref('');
        const phoneNumber = ref('');
        const phoneNumberError = ref('');
        const dialCodeError = ref('');
        const splitNumber = (value) => {
            const countryCodes = Object.keys(props.dialCodes);
            for (const code of countryCodes) {
                if (value.startsWith(code)) {
                    const codeSize = code.length;
                    const number = value.substring(codeSize).replace(/\s/g, '');
                    return { code, number };
                }
            }
            return { code: '', number: value.replace(/\s/g, '') };
        };
        const updateDisplayCountryCode = () => {
            displayCountryCode.value = selectedDialCode.value ? `${selectedDialCode.value}` : '';
        };
        const onCodeUpdated = () => {
            updateDisplayCountryCode();
            notifyValidity();
            if (selectedDialCode.value) {
                emit('update:modelValue', `${selectedDialCode.value}${phoneNumber.value}`);
            }
        };
        const onPhoneUpdated = () => {
            notifyValidity();
            if (!phoneNumber.value && selectedDialCode.value) {
                emit('update:modelValue', '');
            }
            else {
                emit('update:modelValue', `${selectedDialCode.value}${phoneNumber.value}`);
            }
        };
        const setFormData = () => {
            const { code, number } = splitNumber(props.modelValue);
            selectedDialCode.value = code;
            phoneNumber.value = number;
            updateDisplayCountryCode();
            notifyValidity();
        };
        const validateNumber = () => {
            const regex = /^\d{1,14}$/;
            if (phoneNumber.value.trim() !== '' && !regex.test(phoneNumber.value)) {
                phoneNumberError.value = props.t.inputPhoneErrorMessage;
                return false;
            }
            else {
                phoneNumberError.value = '';
                return true;
            }
        };
        const validateDialCode = () => {
            if (phoneNumber.value && !selectedDialCode.value) {
                dialCodeError.value = props.t.inputDialCodeMessage;
                return false;
            }
            else {
                dialCodeError.value = '';
                return true;
            }
        };
        const isPhoneValid = () => {
            const regex = /^\+\d{1,3}\d{1,14}$/;
            return regex.test(props.modelValue);
        };
        const clearPhoneNumber = () => {
            phoneNumber.value = '';
            onPhoneUpdated();
        };
        const handleInput = () => {
            if (!phoneNumber.value.trim()) {
                clearPhoneNumber();
            }
        };
        const errorMessage = computed(() => {
            if (dialCodeError.value) {
                return dialCodeError.value;
            }
            else if (phoneNumberError.value) {
                return phoneNumberError.value;
            }
            return '';
        });
        const notifyValidity = () => {
            dialCodeError.value = '';
            phoneNumberError.value = '';
            if (isPhoneValid()) {
                emit('valid');
            }
            else {
                validateNumber();
                validateDialCode();
                emit('invalid');
            }
        };
        watch(() => props.modelValue, setFormData);
        watch(() => props.saving, notifyValidity);
        onMounted(() => {
            setFormData();
        });
        return {
            selectedDialCode,
            displayCountryCode,
            onCodeUpdated,
            phoneNumber,
            phoneNumberError,
            dialCodeError,
            isPhoneValid,
            onPhoneUpdated,
            errorMessage,
            clearPhoneNumber,
            handleInput
        };
    },
});
