import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "about__header" };
const _hoisted_2 = { class: "f-inter z-b4" };
const _hoisted_3 = { class: "about__content" };
const _hoisted_4 = { class: "about__form-field text-area" };
const _hoisted_5 = { class: "f-inter z-b4" };
const _hoisted_6 = { class: "about__form-counter f-inter z-b5" };
const _hoisted_7 = { class: "about__form-counter f-inter z-b5" };
const _hoisted_8 = {
    key: 0,
    class: "warning"
};
const _hoisted_9 = { class: "about__form-field" };
const _hoisted_10 = { class: "f-inter z-b5" };
const _hoisted_11 = { class: "about__form-counter f-inter z-b5" };
const _hoisted_12 = {
    key: 0,
    class: "warning"
};
const _hoisted_13 = { class: "about__buttons" };
import { isMobileReactive } from '@ts/Util/responsiveness';
import { interpolate } from "@ts/Util/text";
import { computed, ref, watch } from 'vue';
import { csrfToken } from '@ts/Util/security';
import { post } from '@ts/Util/http';
import Modal from '@components/Core/Modal.vue';
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue';
import ZButton from '@components/Core/ZButton.vue';
const ABOUT_MAX_LENGTH = 2500;
const LICENSES_MAX_LENGTH = 255;
export default /*@__PURE__*/ _defineComponent({
    __name: 'AboutModal',
    props: {
        show: {
            type: Boolean,
            default: () => false
        },
        aboutValue: {
            type: String,
            default: () => ''
        },
        licensesValue: {
            type: String,
            default: () => ''
        },
        licenseBadges: {
            type: Array,
            required: false,
            default: () => ([])
        },
        action: {
            type: String,
            default: '',
        },
        t: {
            type: Object,
            default: () => ({})
        }
    },
    emits: ['closed', 'save'],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        const props = __props;
        const excludedWords = ['Zumba', 'Strong', 'Circl'];
        function defaultLicensesValueFromLicenseBadges() {
            return props.licenseBadges
                .map(badge => badge.badge_name)
                .join(", ");
        }
        const defaultLicensesValue = props.licensesValue === '' ?
            defaultLicensesValueFromLicenseBadges() : props.licensesValue;
        const aboutValueRef = ref(props.aboutValue);
        const licensesValueRef = ref(defaultLicensesValue);
        const aboutLength = computed(() => {
            if (!props.t.maxCharacters) {
                return '';
            }
            return interpolate(props.t.maxCharacters, [ABOUT_MAX_LENGTH - aboutValueRef.value.length]);
        });
        const licensesLength = computed(() => {
            if (!props.t.maxCharacters) {
                return '';
            }
            return interpolate(props.t.maxCharacters, [LICENSES_MAX_LENGTH - licensesValueRef.value.length]);
        });
        const isAboutMaxLengthReached = computed(() => {
            return aboutValueRef.value.length >= ABOUT_MAX_LENGTH;
        });
        const isMaxLengthReached = computed(() => {
            return licensesValueRef.value.length >= LICENSES_MAX_LENGTH;
        });
        watch(aboutValueRef, (newValue) => {
            if (newValue.length > ABOUT_MAX_LENGTH) {
                aboutValueRef.value = newValue.slice(0, ABOUT_MAX_LENGTH);
            }
        });
        watch(licensesValueRef, (newValue) => {
            if (newValue.length > LICENSES_MAX_LENGTH) {
                licensesValueRef.value = newValue.slice(0, LICENSES_MAX_LENGTH);
            }
        });
        const customModalStyles = computed(() => {
            const isMobile = isMobileReactive();
            let modalStyle = {
                border: 0,
                width: isMobile.value ? '100%' : '47.25rem',
                paddingTop: isMobile.value ? '1.25rem' : '2.5rem',
                paddingBottom: isMobile.value ? '1.25rem' : '2.5rem',
                paddingLeft: isMobile.value ? '1.25rem' : '2.5rem',
                paddingRight: isMobile.value ? '1.25rem' : '2.5rem',
                bottom: isMobile.value ? '1.25rem' : '',
                left: isMobile.value ? 0 : '',
                top: isMobile.value ? 'initial' : '',
                transform: isMobile.value ? 'none' : '',
                borderRadius: isMobile.value ? '0.5rem 0.5rem 0 0' : '',
            };
            return {
                modal: modalStyle
            };
        });
        const doCancel = () => {
            aboutValueRef.value = props.aboutValue;
            licensesValueRef.value = defaultLicensesValue;
            emit('closed');
        };
        const doSave = async () => {
            const response = await post(props.action, csrfToken(), {
                about: aboutValueRef.value,
                licenses: licensesValueRef.value
            });
            if (response.ok) {
                window.location.reload();
            }
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(Modal, {
                show: __props.show,
                "close-when-click-on-background": "",
                "close-when-type-esc": "",
                "show-close-button": false,
                "enable-scroll": "",
                "mobile-drawer": "",
                "custom-styles": customModalStyles.value,
                onClosed: _cache[2] || (_cache[2] = ($event) => (emit('closed')))
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("h3", null, _toDisplayString(__props.t.about), 1),
                        _createElementVNode("p", _hoisted_2, _toDisplayString(__props.t.aboutDetails), 1)
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("label", _hoisted_5, _toDisplayString(__props.t.instructorBio), 1),
                            _withDirectives(_createElementVNode("textarea", {
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((aboutValueRef).value = $event)),
                                maxlength: "{{ ABOUT_MAX_LENGTH }}"
                            }, null, 512), [
                                [_vModelText, aboutValueRef.value]
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_6, _toDisplayString(aboutLength.value), 1),
                        _createElementVNode("div", _hoisted_7, [
                            (isAboutMaxLengthReached.value)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(__props.t.youHaveReachedTheMaximumLength), 1))
                                : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("label", _hoisted_10, _toDisplayString(__props.t.licensesCertifications), 1),
                            _withDirectives(_createElementVNode("textarea", {
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((licensesValueRef).value = $event)),
                                maxlength: "{{ LICENSES_MAX_LENGTH }}"
                            }, null, 512), [
                                [_vModelText, licensesValueRef.value]
                            ])
                        ]),
                        _createElementVNode("div", {
                            class: _normalizeClass([{ 'max-length-reached': isMaxLengthReached.value }, "about__form-counter f-inter z-b5"])
                        }, _toDisplayString(licensesLength.value), 3),
                        _createElementVNode("div", _hoisted_11, [
                            (isMaxLengthReached.value)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(__props.t.youHaveReachedTheMaximumLength), 1))
                                : _createCommentVNode("", true)
                        ])
                    ]),
                    _createVNode(HorizontalSeparator),
                    _createElementVNode("div", _hoisted_13, [
                        _createVNode(ZButton, {
                            class: "about__buttons-button",
                            wide: "always",
                            variety: "plum",
                            onClick: doCancel
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(__props.t.cancel), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(ZButton, {
                            class: "about__buttons-button",
                            wide: "always",
                            variety: "plum",
                            mode: "dark",
                            onClick: doSave
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(__props.t.save), 1)
                            ]),
                            _: 1
                        })
                    ])
                ]),
                _: 1
            }, 8, ["show", "custom-styles"]));
        };
    }
});
