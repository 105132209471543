import { default as SocialLinks } from '@components/SocialLinks/SocialLinks.vue';
import { defineComponent } from 'vue';
import { validateTranslations } from "@ts/Util/i18n";
const translationDefaults = {
    socialLabel: 'Social',
};
export default defineComponent({
    name: 'Social',
    components: {
        SocialLinks
    },
    props: {
        socialLinks: {
            type: Object,
            required: true
        },
        size: {
            type: String,
            default: 'normal',
        },
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
    },
    emits: ['open-modal'],
    setup() {
        return {};
    }
});
