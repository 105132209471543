import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "credentials" };
const _hoisted_2 = { class: "credentials-item" };
const _hoisted_3 = { class: "headline z-b4 f-inter" };
const _hoisted_4 = { class: "credentials-item" };
const _hoisted_5 = { class: "headline z-b4 f-inter" };
const _hoisted_6 = { class: "credentials-item" };
const _hoisted_7 = { class: "headline z-b4 f-inter" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ProfileBadges = _resolveComponent("ProfileBadges");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t.licenses), 1),
            _createVNode(_component_ProfileBadges, { "badge-items": _ctx.license }, null, 8, ["badge-items"])
        ]),
        _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t.education), 1),
            _createVNode(_component_ProfileBadges, {
                "badge-items": _ctx.education,
                "enable-noom-badge": _ctx.enableNoomBadge
            }, null, 8, ["badge-items", "enable-noom-badge"])
        ]),
        _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t.milestone), 1),
            _createVNode(_component_ProfileBadges, { "badge-items": _ctx.milestone }, null, 8, ["badge-items"])
        ])
    ]));
}
