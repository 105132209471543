import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = { class: "socials_header" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { class: "f-inter z-b4" };
const _hoisted_5 = { class: "socials_form" };
const _hoisted_6 = { class: "socials__buttons" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_z_input = _resolveComponent("z-input");
    const _component_HorizontalSeparator = _resolveComponent("HorizontalSeparator");
    const _component_z_button = _resolveComponent("z-button");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        show: _ctx.show,
        "close-when-click-on-background": "",
        "close-when-type-esc": "",
        "show-close-button": false,
        "custom-styles": {
            modal: {
                'width': '100%',
                'max-width': '47.25rem'
            }
        },
        "mobile-drawer": "",
        onClosed: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('closed')))
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                (_ctx.isMobile)
                    ? (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString(_ctx.t.socialLinks), 1))
                    : (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.t.socialLinks), 1)),
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t.socialLinksDetails), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.networks, (network) => {
                    return (_openBlock(), _createElementBlock("div", { key: network }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(["socials_form-item", {
                                    'withValue': !!_ctx.values[network]
                                }])
                        }, [
                            _createVNode(_component_z_input, {
                                modelValue: _ctx.values[network],
                                "onUpdate:modelValue": [($event) => ((_ctx.values[network]) = $event), ($event) => (_ctx.validateSocialNetwork(network))],
                                type: "text",
                                class: "f-inter",
                                name: network,
                                label: _ctx.t[network],
                                maxlength: 50,
                                error: _ctx.state.errorSocialNetwork[network] ? _ctx.t.errorInvalidSocialLink : ''
                            }, {
                                "right-slot": _withCtx(() => [
                                    (_openBlock(), _createBlock(_resolveDynamicComponent(network + '-icon')))
                                ]),
                                _: 2
                            }, 1032, ["modelValue", "onUpdate:modelValue", "name", "label", "error"])
                        ], 2)
                    ]));
                }), 128))
            ]),
            _createVNode(_component_HorizontalSeparator),
            _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_z_button, {
                    class: "socials__buttons-button",
                    wide: "always",
                    variety: "plum",
                    onClick: _withModifiers(_ctx.doCancel, ["prevent"])
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t.cancel), 1)
                    ]),
                    _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_z_button, {
                    class: "socials__buttons-button",
                    wide: "always",
                    variety: "plum",
                    mode: "dark",
                    disabled: _ctx.state.isSaving,
                    onClick: _withModifiers(_ctx.doSave, ["prevent"])
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t.save), 1)
                    ]),
                    _: 1
                }, 8, ["disabled", "onClick"])
            ])
        ]),
        _: 1
    }, 8, ["show"]));
}
