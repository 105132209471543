import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "redesign-margin-top"
};
const _hoisted_2 = { class: "instructor-section" };
const _hoisted_3 = { class: "instructor-section--header" };
const _hoisted_4 = { class: "instructor-section--title f-inter" };
const _hoisted_5 = {
    key: 0,
    class: "instructor-bio z-b4 f-inter"
};
const _hoisted_6 = { class: "about-me z-b3 f-inter" };
const _hoisted_7 = {
    key: 1,
    class: "z-b3 f-inter"
};
const _hoisted_8 = { class: "details-item" };
const _hoisted_9 = { class: "details-item-wrapper" };
const _hoisted_10 = { class: "license-to-teach z-b4 f-inter" };
const _hoisted_11 = ["innerHTML"];
const _hoisted_12 = ["innerHTML"];
const _hoisted_13 = { class: "details-item-wrapper" };
const _hoisted_14 = {
    key: 0,
    class: "member-since z-b4 f-inter"
};
const _hoisted_15 = { class: "membership-details" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_PencilIcon = _resolveComponent("PencilIcon");
    const _component_AboutModal = _resolveComponent("AboutModal");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (!_ctx.enableRedesign)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1))
            : _createCommentVNode("", true),
        _createElementVNode("section", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.t.about), 1),
                (_ctx.editMode)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        class: "",
                        href: "#",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => (_ctx.showEditModal = true), ["prevent"]))
                    }, [
                        _createVNode(_component_PencilIcon)
                    ]))
                    : _createCommentVNode("", true)
            ]),
            (_ctx.instructor.about !== '')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.t.bio), 1))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.instructor.about), 1),
            (_ctx.instructor.about === '' && _ctx.editMode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createTextVNode(_toDisplayString(_ctx.t.noBioPosted) + " ", 1),
                    _createElementVNode("a", {
                        class: "z-b3 f-inter",
                        href: "#",
                        onClick: _cache[1] || (_cache[1] = _withModifiers(($event) => (_ctx.showEditModal = true), ["prevent"]))
                    }, _toDisplayString(_ctx.t.add), 1)
                ]))
                : _createCommentVNode("", true),
            _cache[3] || (_cache[3] = _createElementVNode("hr", { class: "separator" }, null, -1)),
            _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.t.licensesCertifications), 1),
                    _createElementVNode("span", {
                        class: "member-license-details z-b3 f-inter",
                        innerHTML: _ctx.memberLicenses
                    }, null, 8, _hoisted_11),
                    _createElementVNode("span", {
                        class: "license-details z-b3 f-inter",
                        innerHTML: _ctx.licenses
                    }, null, 8, _hoisted_12)
                ]),
                _createElementVNode("div", _hoisted_13, [
                    (_ctx.memberships.length > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(_ctx.t.memberSince), 1))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_15, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.memberships, (membership, index) => {
                            return (_openBlock(), _createElementBlock("span", {
                                key: index,
                                class: "z-b3 f-inter"
                            }, _toDisplayString(membership.membership_type) + " " + _toDisplayString(_ctx.t.since) + " " + _toDisplayString(_ctx.formatDate(membership.start_date)), 1));
                        }), 128))
                    ])
                ])
            ]),
            _createVNode(_component_AboutModal, {
                t: _ctx.t,
                show: _ctx.showEditModal,
                action: _ctx.detailsInstructorAction,
                "about-value": _ctx.instructor.about,
                "licenses-value": _ctx.licenses,
                onClosed: _cache[2] || (_cache[2] = ($event) => (_ctx.showEditModal = false))
            }, null, 8, ["t", "show", "action", "about-value", "licenses-value"])
        ])
    ], 64));
}
