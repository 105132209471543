import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["for"];
const _hoisted_2 = ["id", "name", "disabled"];
import { computed, ref } from 'vue';
import { v4 as uuid } from 'uuid';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ToggleSwitch',
    props: {
        name: {
            type: String,
            default: `toggle_switch`
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['update:modelValue'],
    setup(__props, { emit: __emit }) {
        const uniqueID = uuid();
        const isFocused = ref(false);
        const emit = __emit;
        const isChecked = computed({
            get() {
                return props.modelValue;
            },
            set(isChecked) {
                emit('update:modelValue', isChecked);
            }
        });
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("label", { for: _unref(uniqueID) }, [
                _renderSlot(_ctx.$slots, "label"),
                _createElementVNode("span", {
                    class: _normalizeClass(["switch", {
                            disabled: __props.disabled,
                            focused: isFocused.value
                        }])
                }, [
                    _withDirectives(_createElementVNode("input", {
                        id: _unref(uniqueID),
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((isChecked).value = $event)),
                        name: __props.name,
                        type: "checkbox",
                        disabled: __props.disabled,
                        onClick: _cache[1] || (_cache[1] = ($event) => (isFocused.value = false)),
                        onFocus: _cache[2] || (_cache[2] = ($event) => (isFocused.value = true)),
                        onBlur: _cache[3] || (_cache[3] = ($event) => (isFocused.value = false))
                    }, null, 40, _hoisted_2), [
                        [_vModelCheckbox, isChecked.value]
                    ]),
                    _cache[4] || (_cache[4] = _createElementVNode("span", { class: "slider" }, null, -1))
                ], 2)
            ], 8, _hoisted_1));
        };
    }
});
