export var AvailableContactTypes;
(function (AvailableContactTypes) {
    AvailableContactTypes["WHATSAPP"] = "Whatsapp";
    AvailableContactTypes["EMAIL"] = "Email";
    AvailableContactTypes["SMS"] = "SMS";
})(AvailableContactTypes || (AvailableContactTypes = {}));
const translationDefaults = {
    contactLabel: 'Contact',
    copiedToClipboard: 'Copied !'
};
import { ref, defineComponent, computed } from 'vue';
import { validateTranslations } from "@ts/Util/i18n";
import { isMobileReactive } from '@ts/Util/responsiveness';
import EnvelopeSvg from '@icons/envelope.svg';
import WhatsappSvg from '@icons/whatsapp.svg';
import CommentSvg from '@icons/comment.svg';
import ZButton from '@components/Core/ZButton.vue';
import ContactInstructorHandler from '@modules/InstructorPage/ContactInstructorHandler.vue';
export default defineComponent({
    name: 'ContactButton',
    components: {
        EnvelopeSvg,
        WhatsappSvg,
        CommentSvg,
        ZButton,
        ContactInstructorHandler
    },
    props: {
        phone: {
            type: String,
            required: false,
            default: '',
        },
        email: {
            type: String,
            required: false,
            default: ''
        },
        whatsapp: {
            type: String,
            required: false,
            default: ''
        },
        wide: {
            type: String,
            default: 'always',
            validator: (value) => {
                return [
                    'never',
                    'always',
                    'mobile-only',
                    'desktop-only',
                ].includes(value);
            },
        },
        compact: {
            type: String,
            default: 'desktop-only',
            validator: (value) => {
                return [
                    'never',
                    'always',
                    'mobile-only',
                    'desktop-only',
                ].includes(value);
            },
        },
        variety: {
            type: String,
            default: 'cyclops',
            validator: (value) => {
                // You can add more variety types here
                return [
                    'optimus',
                    'robin',
                    'phoenix',
                    'cyclops',
                    'rogue',
                    'love',
                    'plum',
                ].includes(value);
            },
        },
        alwaysShowLabel: {
            type: Boolean,
            default: false,
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
        showModal: {
            type: Boolean,
            default: false
        },
        instructorDisplayName: {
            type: String,
            required: false,
            default: ''
        },
        instructorProfilePhotoUrl: {
            type: String,
            required: false,
            default: ''
        },
        instructorLocation: {
            type: String,
            required: false,
            default: ''
        },
        requesterName: {
            type: String,
            default: ''
        },
        requesterEmail: {
            type: String,
            default: ''
        },
        enabledContactInstructor: {
            type: Boolean,
            default: false
        },
        recaptchaKey: {
            type: String,
            default: ''
        },
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
    },
    emits: [
        'option:email', 'option:whatsapp', 'option:sms', 'toast:message', 'on-send-message', 'recaptcha:failure', 'clicked:contact'
    ],
    setup(props, { emit }) {
        const showTooltip = ref(false);
        const copiedToClipboardTooltip = ref(false);
        const isMobile = isMobileReactive();
        const canContactInstructorEmail = ref(false);
        const contactTypes = computed(() => {
            const available = [];
            if (props.whatsapp)
                available.push(AvailableContactTypes.WHATSAPP);
            if (props.email)
                available.push(AvailableContactTypes.EMAIL);
            if (props.phone)
                available.push(AvailableContactTypes.SMS);
            return available;
        });
        const showIconOnly = computed(() => contactTypes.value.length === 1);
        const contactTypesDetails = {
            Whatsapp: {
                action: ($e) => {
                    $e.preventDefault();
                    const message = 'Hi';
                    let url = `https://wa.me/${props.whatsapp}?text=${encodeURIComponent(message)}`;
                    if (isMobile.value) {
                        // Construct the URL with the "intent://" scheme for Android and "whatsapp://" scheme for iOS
                        if (/Android/i.test(navigator.userAgent)) {
                            url = `intent://send/${props.whatsapp}#Intent;scheme=whatsapp;action=android.intent.action.SENDTO;end`;
                        }
                        else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                            url = `whatsapp://send?phone=${props.whatsapp}`;
                        }
                    }
                    window.open(url);
                    if (props.enabledContactInstructor) {
                        canContactInstructorEmail.value = true;
                    }
                    else {
                        window.location.href = `mailto:${props.email}`;
                    }
                    showTooltip.value = false;
                    emit('option:whatsapp');
                },
                icon: WhatsappSvg
            },
            SMS: {
                action: ($e) => {
                    $e.preventDefault();
                    if (isMobile.value) {
                        window.open(`sms:${props.phone}`);
                        showTooltip.value = false;
                        return;
                    }
                    navigator.clipboard.writeText(props.phone ?? '')
                        .then(() => {
                        console.log("Phone copied to clipboard!");
                        copiedToClipboardTooltip.value = true;
                        setTimeout(() => {
                            copiedToClipboardTooltip.value = false;
                            showTooltip.value = false;
                        }, 2000);
                    })
                        .catch((error) => {
                        console.error("Failed to copy phone: ", error);
                        showTooltip.value = false;
                    });
                    emit('option:sms');
                },
                icon: CommentSvg
            },
            Email: {
                action: ($e) => {
                    $e.preventDefault();
                    showTooltip.value = false;
                    emit('option:email');
                    canContactInstructorEmail.value = true;
                },
                icon: EnvelopeSvg
            }
        };
        const showContactOptions = ($e) => {
            emit('clicked:contact');
            if (contactTypes.value.length === 1) {
                return contactTypesDetails[contactTypes.value[0]].action($e);
            }
            showTooltip.value = !showTooltip.value;
        };
        return {
            isMobile,
            contactTypes,
            showTooltip,
            showContactOptions,
            contactTypesDetails,
            showIconOnly,
            copiedToClipboardTooltip,
            AvailableContactTypes,
            canContactInstructorEmail
        };
    }
});
