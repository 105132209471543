import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "badges-container" };
const _hoisted_2 = ["src"];
const _hoisted_3 = { class: "badge-name z-b5 f-inter" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badgeItems, (badgeItem, index) => {
            return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "badges-item"
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass({ 'hide-noom-badge': !_ctx.enableNoomBadge && badgeItem.badge_slug === 'noom_certified' })
                }, [
                    _createElementVNode("img", {
                        class: "badge-icon",
                        src: badgeItem.badge_icon
                    }, null, 8, _hoisted_2),
                    _createElementVNode("strong", _hoisted_3, _toDisplayString(badgeItem.badge_name), 1)
                ], 2)
            ]));
        }), 128))
    ]));
}
