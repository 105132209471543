import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "dial-code-list-section" };
const _hoisted_2 = { class: "input-wrapper" };
const _hoisted_3 = { class: "input-wrapper" };
const _hoisted_4 = {
    key: 0,
    class: "icon-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_form_select = _resolveComponent("form-select");
    const _component_ZInput = _resolveComponent("ZInput");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_form_select, {
                modelValue: _ctx.selectedDialCode,
                "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event) => ((_ctx.selectedDialCode) = $event)),
                    _ctx.onCodeUpdated
                ],
                name: "dialCode",
                light: "",
                "display-value-override": _ctx.displayCountryCode,
                items: _ctx.dialCodes,
                "scroll-after": 5,
                error: _ctx.errorMessage ? ' ' : ''
            }, null, 8, ["modelValue", "display-value-override", "items", "error", "onUpdate:modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ZInput, {
                modelValue: _ctx.phoneNumber,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.phoneNumber) = $event)),
                name: "phone",
                type: "text",
                label: _ctx.label,
                error: _ctx.errorMessage ? _ctx.errorMessage : '',
                onBlur: _ctx.onPhoneUpdated,
                onInput: _ctx.handleInput
            }, null, 8, ["modelValue", "label", "error", "onBlur", "onInput"]),
            (_ctx.showIcon)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
                ]))
                : _createCommentVNode("", true)
        ])
    ]));
}
