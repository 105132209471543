import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '@images/InstructorPage/InstaLogo.png';
const _hoisted_1 = {
    key: 0,
    class: "header-container"
};
const _hoisted_2 = { class: "instructor-section__title--redesign z-h1" };
const _hoisted_3 = {
    key: 0,
    class: "toggle-container"
};
const _hoisted_4 = { class: "toggle" };
const _hoisted_5 = { class: "toggle-text f-inter" };
const _hoisted_6 = {
    key: 1,
    class: "title-container"
};
const _hoisted_7 = { class: "title f-inter" };
const _hoisted_8 = { class: "toggle" };
const _hoisted_9 = { class: "toggle-text f-inter" };
const _hoisted_10 = {
    key: 2,
    class: "container"
};
const _hoisted_11 = {
    key: 0,
    class: "setup"
};
const _hoisted_12 = { class: "no-feed z-b2 f-inter" };
const _hoisted_13 = { class: "form" };
const _hoisted_14 = { key: 1 };
const _hoisted_15 = ["innerHTML"];
const _hoisted_16 = {
    key: 3,
    class: "instagram-container"
};
const _hoisted_17 = {
    key: 4,
    class: "connect-note"
};
const _hoisted_18 = { class: "note z-b4 f-inter" };
const _hoisted_19 = ["innerHTML"];
const _hoisted_20 = { class: "modal-body" };
const _hoisted_21 = { class: "z-h4 disconnect-modal-title" };
const _hoisted_22 = { class: "diconnect-message z-b2 f-inter" };
const _hoisted_23 = { class: "modal-button-container" };
import { computed, reactive, watch } from "vue";
import ZInput from "@components/Core/ZInput.vue";
import ZButton from "@components/Core/ZButton.vue";
import InstaIcon from '@bx-icons/logos/bxl-instagram-alt.svg';
import InstagramFeed from "./InstagramFeed.vue";
import ToggleSwitch from "@components/Core/ToggleSwitch.vue";
import Modal from "@components/Core/Modal.vue";
import { isMobile } from "@ts/Util/responsiveness";
import { interpolate } from "@ts/Util/text";
const translationDefaults = {
    title: 'Instagram Feed',
    no_feed: 'No Instagram feed to display. Connect it to start sharing.',
    username: 'Instagram username',
    usernameError: 'Invalid Instagram username',
    connect: 'Connect',
    connect_note: 'When your feed is connected it will update automatically and appear directly on this instructor page. You can hide it anytime.',
    connect_instructions: 'To display your Instagram profile, please make sure that your account is set to {0} and that the {1} option is turned ON.',
    connect_public: 'public',
    connect_embed: 'embed',
    hide: 'Hide',
    show: 'Show',
    hidden: 'Your Instagram feed is hidden. Click on &quot;Show&quot; to display it!',
    disconnect: 'Disconnect',
    disconnect_title: 'Disconnect my Instagram feed',
    disconnect_message: 'Are you sure you want to disconnect your Instagram feed from your page?',
    disconnect_yes: 'Yes, disconnect',
    cancel: 'Cancel',
};
/**
 * Validates user is not a URL
 */
const checkUsername = (state) => {
    state.usernameInput = state.usernameInput?.trim();
    /**
     * An Instagram username is limited to 30 characters and must contain only letters, numbers, periods, and underscores.
     */
    if (state.usernameInput.match(/[^a-zA-Z0-9._]/g) || state.usernameInput.length > 30) {
        state.hasError = true;
    }
    else {
        state.hasError = false;
    }
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'InstagramOptions',
    props: {
        username: {
            type: String,
            default: () => ''
        },
        instructorName: {
            type: String,
            default: () => ''
        },
        enabled: {
            type: Boolean,
            default: () => false
        },
        show: {
            type: Boolean,
            default: () => false
        },
        edit: {
            type: Boolean,
            default: () => false
        },
        loading: {
            type: Boolean,
            default: () => false
        },
        showDisconnectModal: {
            type: Boolean,
            default: () => false
        },
        enableRedesign: {
            type: Boolean,
            default: false
        },
        t: {
            type: Object,
            default: () => translationDefaults,
        }
    },
    emits: [
        'connect-requested',
        'visibility-changed',
        'disconnect-modal-requested',
        'disconnect-requested',
        'disconnect-request-cancelled',
    ],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const state = reactive({
            usernameInput: props.username,
            toggled: props.show,
            hasError: false,
        });
        watch(() => props.show, (newVal) => {
            state.toggled = newVal;
        });
        watch(() => state.toggled, (newVal, oldVal) => {
            if (newVal !== oldVal) {
                emit('visibility-changed', newVal);
            }
        });
        watch(() => state.usernameInput, (newVal, oldVal) => {
            checkUsername(state);
        });
        const publicLink = computed(() => ('<a href="https://help.instagram.com/225222310104065">' + props.t.connect_public + '</a>'));
        const embedLink = computed(() => ('<a href="https://help.instagram.com/252460186989212/?cms_platform=iphone-app&helpref=platform_switcher">' + props.t.connect_embed + '</a>'));
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    'instagram-redesign-container': __props.enableRedesign
                })
            }, [
                (__props.enableRedesign)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createElementVNode("h4", _hoisted_2, _toDisplayString(__props.t.title), 1),
                        (__props.enabled && __props.edit)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _createElementVNode("div", _hoisted_4, [
                                    (__props.enabled && __props.edit)
                                        ? (_openBlock(), _createBlock(ToggleSwitch, {
                                            key: 0,
                                            modelValue: state.toggled,
                                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((state.toggled) = $event)),
                                            disabled: __props.loading
                                        }, {
                                            label: _withCtx(() => [
                                                _createElementVNode("span", _hoisted_5, _toDisplayString(state.toggled ? __props.t.hide : __props.t.show), 1)
                                            ]),
                                            _: 1
                                        }, 8, ["modelValue", "disabled"]))
                                        : _createCommentVNode("", true)
                                ])
                            ]))
                            : _createCommentVNode("", true)
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("h5", _hoisted_7, _toDisplayString(__props.t.title), 1),
                        _createElementVNode("div", _hoisted_8, [
                            (__props.enabled && __props.edit)
                                ? (_openBlock(), _createBlock(ToggleSwitch, {
                                    key: 0,
                                    modelValue: state.toggled,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((state.toggled) = $event)),
                                    disabled: __props.loading
                                }, {
                                    label: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_9, _toDisplayString(state.toggled ? __props.t.hide : __props.t.show), 1)
                                    ]),
                                    _: 1
                                }, 8, ["modelValue", "disabled"]))
                                : _createCommentVNode("", true)
                        ])
                    ])),
                (!__props.enabled || !state.toggled)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _cache[8] || (_cache[8] = _createElementVNode("img", {
                            src: _imports_0,
                            alt: "Instagram Logo",
                            class: "logo"
                        }, null, -1)),
                        (!__props.enabled)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                _createElementVNode("p", _hoisted_12, _toDisplayString(__props.t.no_feed), 1),
                                _createElementVNode("div", _hoisted_13, [
                                    _createVNode(ZInput, {
                                        modelValue: state.usernameInput,
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((state.usernameInput) = $event)),
                                        class: "input",
                                        name: __props.t.username,
                                        label: __props.t.username,
                                        error: state.hasError ? __props.t.usernameError : ''
                                    }, {
                                        "right-slot": _withCtx(() => [
                                            _createVNode(_unref(InstaIcon))
                                        ]),
                                        _: 1
                                    }, 8, ["modelValue", "name", "label", "error"]),
                                    _createVNode(ZButton, {
                                        class: "connect-button",
                                        variety: "optimus",
                                        wide: "mobile-only",
                                        disabled: __props.loading || state.hasError,
                                        onClick: _cache[3] || (_cache[3] = () => _ctx.$emit('connect-requested', state.usernameInput))
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(__props.t.connect), 1)
                                        ]),
                                        _: 1
                                    }, 8, ["disabled"])
                                ])
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                _createElementVNode("p", {
                                    class: "hidden-text z-b2 f-inter",
                                    innerHTML: __props.t.hidden
                                }, null, 8, _hoisted_15)
                            ]))
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        (state.toggled)
                            ? (_openBlock(), _createBlock(InstagramFeed, {
                                key: 0,
                                username: __props.username,
                                "instructor-name": __props.instructorName
                            }, null, 8, ["username", "instructor-name"]))
                            : _createCommentVNode("", true)
                    ])),
                (__props.edit)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _createElementVNode("p", _hoisted_18, _toDisplayString(__props.t.connect_note), 1),
                        _createElementVNode("p", {
                            class: "instructions z-b4 f-inter",
                            innerHTML: _unref(interpolate)(__props.t.connect_instructions, [
                                publicLink.value,
                                embedLink.value
                            ])
                        }, null, 8, _hoisted_19)
                    ]))
                    : _createCommentVNode("", true),
                (__props.enabled && __props.edit)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 5,
                        class: "disconnect-button z-b2 f-inter",
                        href: "#",
                        onClick: _cache[4] || (_cache[4] = _withModifiers(() => _ctx.$emit('disconnect-modal-requested'), ["prevent"]))
                    }, _toDisplayString(__props.t.disconnect), 1))
                    : _createCommentVNode("", true),
                _createVNode(Modal, {
                    show: __props.showDisconnectModal,
                    "show-close-button": true,
                    "mobile-drawer": _unref(isMobile)(),
                    "custom-styles": {
                        modal: {
                            width: '47.25rem',
                            maxWidth: '100%',
                        }
                    },
                    onClosed: _cache[7] || (_cache[7] = () => _ctx.$emit('disconnect-request-cancelled'))
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("h4", _hoisted_21, _toDisplayString(__props.t.disconnect_title), 1),
                            _createElementVNode("p", _hoisted_22, _toDisplayString(__props.t.disconnect_message), 1)
                        ]),
                        _createElementVNode("div", _hoisted_23, [
                            _createVNode(ZButton, {
                                class: "modal-button",
                                variety: "optimus",
                                wide: "always",
                                disabled: __props.loading,
                                onClick: _cache[5] || (_cache[5] = () => _ctx.$emit('disconnect-requested'))
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(__props.t.disconnect_yes), 1)
                                ]),
                                _: 1
                            }, 8, ["disabled"]),
                            _createVNode(ZButton, {
                                class: "modal-button",
                                variety: "phoenix",
                                wide: "always",
                                disabled: __props.loading,
                                onClick: _cache[6] || (_cache[6] = () => _ctx.$emit('disconnect-request-cancelled'))
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(__props.t.cancel), 1)
                                ]),
                                _: 1
                            }, 8, ["disabled"])
                        ])
                    ]),
                    _: 1
                }, 8, ["show", "mobile-drawer"])
            ], 2));
        };
    }
});
