import { defineComponent, computed } from 'vue';
import { validateTranslations } from '@ts/Util/i18n';
import Modal from '@components/Core/Modal.vue';
import ZButton from '@components/Core/ZButton.vue';
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue';
import { interpolate } from '@ts/Util/text';
const translationDefaults = {
    confirmNewUrl: "Confirm new URL",
    back: "Back",
    confirm: "Confirm",
    uponConfirmation: "Upon confirmation, your URL will be changed and you can modify your instructor page URL {0} more times.",
    uponConfirmationOne: "Upon confirmation, your URL will be changed and you can modify your instructor page URL {0} more time.",
    uponConfirmationNoMore: "Upon confirmation, your URL will be changed. You will not be able to edit this URL once it is changed.",
};
export default defineComponent({
    name: 'MyPageURLConfirmModal',
    components: {
        Modal,
        ZButton,
        HorizontalSeparator
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        shortname: {
            type: String,
            required: true
        },
        requestsLeft: {
            type: Number,
            default: 2
        },
        disable: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            required: false,
            default: ''
        },
        baseUrl: {
            type: String,
            reuired: true,
            default: ''
        }
    },
    emits: [
        'close',
        'confirm',
        'back'
    ],
    setup(props, context) {
        const confirmationText = computed(() => {
            if (props.requestsLeft == 1) {
                return interpolate(props.t.uponConfirmationOne, [props.requestsLeft]);
            }
            else if (props.requestsLeft == 0) {
                return (props.t.uponConfirmationNoMore);
            }
            return interpolate(props.t.uponConfirmation, [props.requestsLeft]);
        });
        const urlText = computed(() => interpolate(props.t.baseUrl, [`<strong>${props.shortname}</strong>`]));
        return {
            urlText,
            confirmationText,
            showModal: computed(() => {
                return props.shortname?.trim().length > 0;
            })
        };
    },
});
