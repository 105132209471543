import { defineComponent, ref } from 'vue';
import { validateTranslations } from "@ts/Util/i18n";
import AboutModal from '@modules/InstructorPage/AboutModal.vue';
import PencilIcon from '@bx-icons/regular/bx-pencil.svg';
const translationDefaults = {
    about: '',
    bio: '',
    licensesCertifications: '',
    memberSince: '',
    since: '',
    noBioPosted: '',
    add: ''
};
export default defineComponent({
    name: 'About',
    components: {
        AboutModal,
        PencilIcon
    },
    props: {
        t: {
            type: Object,
            default: () => translationDefaults,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        editMode: {
            type: Boolean,
            default: () => false
        },
        memberships: {
            type: Array,
            required: true
        },
        detailsInstructorAction: {
            type: String,
            default: '',
        },
        instructor: {
            type: Object,
            required: true
        },
        licenses: {
            type: String,
            required: true,
        },
        memberLicenses: {
            type: String,
            required: true,
        },
        enableRedesign: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const showCredentials = ref(false);
        const showEditModal = ref(false);
        const formatDate = (dateTime) => {
            if (!dateTime) {
                return null;
            }
            const date = new Date(dateTime);
            return date.toLocaleDateString("en-US", { month: "short", year: "numeric" });
        };
        return {
            formatDate,
            showCredentials,
            showEditModal
        };
    },
});
