import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "instructor-section__title--redesign z-h1"
};
const _hoisted_2 = {
    key: 1,
    class: "instructor-section__title f-inter"
};
const _hoisted_3 = ["href"];
const _hoisted_4 = {
    key: 3,
    class: "break--redesign"
};
const _hoisted_5 = {
    key: 4,
    class: "instructor-section__filters"
};
const _hoisted_6 = { class: "class-list__filter-trigger" };
const _hoisted_7 = {
    key: 0,
    class: "class-list__filter-count z-b5"
};
const _hoisted_8 = {
    key: 0,
    class: "class-list__filter-count z-b5"
};
const _hoisted_9 = { key: 0 };
const _hoisted_10 = { class: "class-list" };
const _hoisted_11 = { key: 1 };
const _hoisted_12 = { class: "no-classes-posted f-inter" };
const _hoisted_13 = { key: 2 };
const _hoisted_14 = { class: "no-classes-posted f-inter" };
const _hoisted_15 = ["href"];
const _hoisted_16 = { key: 4 };
import { computed, ref, reactive, onMounted, watch } from 'vue';
import { validateTranslations } from '@ts/Util/i18n';
import CalendarIcon from '@bx-icons/regular/bx-calendar-alt.svg';
import FilterIcon from '@bx-icons/regular/bx-filter.svg';
import EditIcon from '@bx-icons/regular/bx-pencil.svg';
import { default as ClassElement } from '@components/ClassListing/ClassElement.vue';
import ClassFilterModal from '@components/ClassListing/ClassFilterModal.vue';
import DatePicker from "@components/ClassListing/DatePicker.vue";
import Loading from '@components/Util/Loading.vue';
import { csrfToken } from '@ts/Util/security';
import { get } from '@ts/Util/http';
import ZButton from "@components/Core/ZButton.vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'LiveClassesHandler',
    props: {
        classes: {
            type: Array,
            required: true,
        },
        totalClasses: {
            type: Number,
            default: 0
        },
        specialties: {
            type: Object,
            default: () => { }
        },
        editMode: {
            type: Boolean,
            default: () => false
        },
        liveClassesAction: {
            type: String,
            default: ''
        },
        hideFilters: {
            type: Boolean,
            default: false
        },
        moreClassesAction: {
            type: String,
            default: ''
        },
        enableRedesign: {
            type: Boolean,
            default: false
        },
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, {
                title: 'Live Classes',
                showMore: 'Show More',
                noClassesPosted: 'No classes posted.',
                add: 'add',
                noClassesAvailable: 'There are no classes available',
                viewFullSchedule: 'Click here to view full schedule'
            })
        }
    },
    emits: ['no-direct-payment-modal:open'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const showFilters = ref(false);
        const dateFilter = ref('');
        const selectedFilters = ref({
            durations: [],
            specialties: [],
            locations: [],
        });
        const minDateAllowed = computed(() => {
            return new Date().toISOString().split('T')[0];
        });
        const availableFilters = ref({
            durations: ['underThirty', 'thirtySixty', 'overSixty'],
            specialties: [...Object.keys(props.specialties)],
            locations: ['inPerson', 'virtual']
        });
        const filterCount = computed(() => {
            let { durations, specialties, locations } = selectedFilters.value;
            return durations.length + specialties.length + locations.length;
        });
        const hasMoreClasses = computed(() => {
            return state.classes.length < state.total;
        });
        const filterApplied = computed(() => {
            return filterCount.value > 0 || dateFilter.value.length > 0;
        });
        const noClassesPosted = computed(() => {
            return !state.classes?.length && state.firstLoading;
        });
        const state = reactive({
            firstLoading: false,
            loading: true,
            classes: [],
            page: 1,
            total: 0,
        });
        watch(() => dateFilter.value, () => {
            state.classes = [];
            state.page = 0;
            search();
        });
        watch(() => selectedFilters.value, () => {
            state.classes = [];
            state.page = 0;
            search();
        });
        const search = async () => {
            try {
                state.loading = true;
                state.page++;
                const payload = {
                    page: state.page,
                    dateFrom: dateFilter.value ?? '',
                    platformTypes: selectedFilters.value.locations,
                    fitnessSpecialties: selectedFilters.value.specialties,
                    durations: selectedFilters.value.durations.map(r => `${r.min}-${r.max}`).join(',') ?? ''
                };
                const result = await get(`${props.moreClassesAction}`, csrfToken(), payload);
                if (!result.ok) {
                    console.error(result);
                    throw new Error('There was an error getting instructor schedule classes.');
                }
                const results = await result.json();
                state.classes = state.classes.concat(results.classes ?? []);
                state.total = results.total;
            }
            catch (error) {
                // TODO: Handle error
                console.error(error);
            }
            finally {
                state.loading = false;
            }
        };
        const clearFilters = () => {
            state.classes = [];
            state.page = 0;
            dateFilter.value = '';
            selectedFilters.value = {
                durations: [],
                specialties: [],
                locations: [],
            };
        };
        const showMoreClasses = () => {
            search();
        };
        onMounted(() => {
            state.classes = [...props.classes];
            state.total = props.totalClasses;
            state.loading = false;
        });
        return (_ctx, _cache) => {
            return (__props.editMode || __props.totalClasses > 0)
                ? (_openBlock(), _createElementBlock("section", {
                    key: 0,
                    class: _normalizeClass(["instructor-section", { 'instructor-section--redesign': __props.enableRedesign }])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(["instructor-section__header", { 'instructor-section__header--redesign': __props.enableRedesign && !__props.editMode,
                                'instructor-section__header--redesign-edit': __props.enableRedesign && __props.editMode }])
                    }, [
                        (__props.enableRedesign)
                            ? (_openBlock(), _createElementBlock("h4", _hoisted_1, _toDisplayString(__props.t.title), 1))
                            : (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(__props.t.title), 1)),
                        (__props.editMode)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 2,
                                href: __props.liveClassesAction,
                                class: "edit-icon"
                            }, [
                                _createVNode(_unref(EditIcon))
                            ], 8, _hoisted_3))
                            : _createCommentVNode("", true),
                        (__props.enableRedesign && __props.editMode)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                            : _createCommentVNode("", true),
                        ((!__props.editMode || state.classes?.length) && !__props.hideFilters)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                _createElementVNode("span", _hoisted_6, [
                                    (dateFilter.value)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, " 1 "))
                                        : _createCommentVNode("", true),
                                    _createVNode(DatePicker, {
                                        modelValue: dateFilter.value,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((dateFilter).value = $event)),
                                        min: minDateAllowed.value,
                                        class: "class-list__filter-datepicker"
                                    }, {
                                        icon: _withCtx(() => [
                                            _createVNode(_unref(CalendarIcon), { class: "class-list__filter-icon" })
                                        ]),
                                        _: 1
                                    }, 8, ["modelValue", "min"])
                                ]),
                                _createElementVNode("a", {
                                    class: "class-list__filter-trigger",
                                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event) => (showFilters.value = true), ["prevent"]))
                                }, [
                                    (filterCount.value > 0)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(filterCount.value), 1))
                                        : _createCommentVNode("", true),
                                    _createVNode(_unref(FilterIcon), { class: "class-list__filter-icon" })
                                ])
                            ]))
                            : _createCommentVNode("", true)
                    ], 2),
                    (state.classes?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.classes, (classItem) => {
                                    return (_openBlock(), _createBlock(ClassElement, {
                                        key: classItem.id,
                                        "class-item": classItem,
                                        specialties: __props.specialties,
                                        t: __props.t,
                                        "enable-redesign": __props.enableRedesign,
                                        "onNoDirectPaymentModal:open": _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('no-direct-payment-modal:open', $event)))
                                    }, null, 8, ["class-item", "specialties", "t", "enable-redesign"]));
                                }), 128))
                            ])
                        ]))
                        : (!state.classes?.length && filterApplied.value && !state.loading)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                _createElementVNode("div", _hoisted_12, [
                                    _createTextVNode(_toDisplayString(__props.t.noClassesAvailable) + " ", 1),
                                    _createElementVNode("a", {
                                        class: "z-b3 f-inter",
                                        onClick: _cache[3] || (_cache[3] = _withModifiers(($event) => (clearFilters()), ["prevent"]))
                                    }, _toDisplayString(__props.t.viewFullSchedule), 1)
                                ])
                            ]))
                            : (!state.loading && !filterApplied.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                    _createElementVNode("div", _hoisted_14, [
                                        _createTextVNode(_toDisplayString(__props.t.noClassesPosted) + " ", 1),
                                        _createElementVNode("a", {
                                            class: "z-b3 f-inter",
                                            href: __props.liveClassesAction
                                        }, _toDisplayString(__props.t.add), 9, _hoisted_15)
                                    ])
                                ]))
                                : _createCommentVNode("", true),
                    (hasMoreClasses.value && !state.loading)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 3,
                            class: _normalizeClass({ 'class-list__show-more--redesign': __props.enableRedesign, 'class-list__show-more': !__props.enableRedesign })
                        }, [
                            (__props.enableRedesign)
                                ? (_openBlock(), _createBlock(ZButton, {
                                    key: 0,
                                    variety: "optimus",
                                    wide: "never",
                                    onClick: _cache[4] || (_cache[4] = _withModifiers(($event) => (showMoreClasses()), ["prevent"]))
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(__props.t.showMore), 1)
                                    ]),
                                    _: 1
                                }))
                                : (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    class: "f-inter",
                                    onClick: _cache[5] || (_cache[5] = _withModifiers(($event) => (showMoreClasses()), ["prevent"]))
                                }, _toDisplayString(__props.t.showMore), 1))
                        ], 2))
                        : _createCommentVNode("", true),
                    (state.loading)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                            _createVNode(Loading)
                        ]))
                        : _createCommentVNode("", true),
                    _createVNode(ClassFilterModal, {
                        t: __props.t,
                        show: showFilters.value,
                        specialties: __props.specialties,
                        "available-filters": availableFilters.value,
                        "mobile-drawer": "",
                        onApply: _cache[6] || (_cache[6] = (filters) => selectedFilters.value = filters),
                        onClosed: _cache[7] || (_cache[7] = ($event) => (showFilters.value = false))
                    }, null, 8, ["t", "show", "specialties", "available-filters"])
                ], 2))
                : _createCommentVNode("", true);
        };
    }
});
