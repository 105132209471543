import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_MyPageURLModal = _resolveComponent("MyPageURLModal");
    const _component_MyPageURLConfirmModal = _resolveComponent("MyPageURLConfirmModal");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_ctx.state.mode !== 'confirm')
            ? (_openBlock(), _createBlock(_component_MyPageURLModal, {
                key: 0,
                t: _ctx.t,
                shortname: _ctx.state.lastValidatedShortname,
                "shortname-taken": _ctx.state.shortnameTaken,
                "show-modal": _ctx.state.mode === 'edit',
                disable: _ctx.state.disable,
                onCancel: _cache[0] || (_cache[0] = ($event) => (_ctx.closeModal())),
                onConfirm: _cache[1] || (_cache[1] = ($event) => (_ctx.confirmAvailabilityURL($event))),
                onClose: _cache[2] || (_cache[2] = ($event) => (_ctx.closeModal()))
            }, null, 8, ["t", "shortname", "shortname-taken", "show-modal", "disable"]))
            : _createCommentVNode("", true),
        (_ctx.state.mode === 'confirm')
            ? (_openBlock(), _createBlock(_component_MyPageURLConfirmModal, {
                key: 1,
                t: _ctx.t.confirmModal,
                shortname: _ctx.state.confirmData?.shortname ?? '',
                "requests-left": _ctx.requestsLeft,
                disable: _ctx.state.disable,
                onBack: _cache[3] || (_cache[3] = ($event) => (_ctx.state.mode = 'edit')),
                onConfirm: _cache[4] || (_cache[4] = ($event) => (_ctx.saveURL($event))),
                onClose: _cache[5] || (_cache[5] = ($event) => (_ctx.state.mode = 'none'))
            }, null, 8, ["t", "shortname", "requests-left", "disable"]))
            : _createCommentVNode("", true)
    ], 64));
}
