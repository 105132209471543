import { defineComponent, ref } from 'vue';
import { validateTranslations } from '@ts/Util/i18n';
import Modal from '@components/Core/Modal.vue';
import ZButton from '@components/Core/ZButton.vue';
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue';
import ZInput from '@components/Core/ZInput.vue';
const translationDefaults = {
    instructions: 'Choose the name to display on your instructor page',
    title: 'Edit Name',
    yourName: 'Your name',
    save: 'Save',
    cancel: 'Cancel',
};
export default defineComponent({
    name: 'InstructorNameModal',
    components: {
        Modal,
        ZButton,
        ZInput,
        HorizontalSeparator
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        instructorName: {
            type: String,
            required: true,
        },
        showModal: {
            type: Boolean,
            required: true,
            default: false
        },
        handleClosedModal: {
            type: Function,
            required: true
        },
        error: {
            type: String,
            required: false,
            default: ''
        },
    },
    emits: ['update:name'],
    setup(props, context) {
        const show = ref(props.showModal);
        const nameChanged = ref(false);
        const name = ref(props.instructorName);
        const saveName = () => {
            nameChanged.value = false;
            context.emit('update:name', name);
        };
        const internalError = ref(props.error);
        const validateName = () => {
            if (name.value === '') {
                internalError.value = props.error;
            }
            else {
                internalError.value = '';
            }
            nameChanged.value = name.value !== '' && name.value !== props.instructorName;
        };
        return {
            show,
            name,
            nameChanged,
            saveName,
            validateName,
            internalError
        };
    },
});
