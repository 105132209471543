import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent } from "vue";
const _hoisted_1 = { class: "instructor-container" };
const _hoisted_2 = { class: "instructor-cover" };
const _hoisted_3 = { class: "instructor-details" };
const _hoisted_4 = { class: "instructor-name" };
const _hoisted_5 = {
    key: 0,
    class: "cta-container hide-mobile"
};
const _hoisted_6 = { class: "desktop-buttons-container" };
const _hoisted_7 = {
    key: 1,
    class: "instructor-share-button hide-mobile"
};
const _hoisted_8 = { class: "instructor-details-container" };
const _hoisted_9 = { class: "instructor-details-list" };
const _hoisted_10 = {
    key: 0,
    class: "z-b4 f-inter"
};
const _hoisted_11 = { class: "z-b4 f-inter" };
const _hoisted_12 = {
    key: 1,
    class: "z-b4 f-inter"
};
const _hoisted_13 = {
    key: 2,
    class: "z-b4 f-inter"
};
const _hoisted_14 = {
    href: "#instructor-reviews",
    class: "z-b4 f-inter"
};
const _hoisted_15 = { class: "instructor-details-container-mobile" };
const _hoisted_16 = { class: "instructor-details-list" };
const _hoisted_17 = {
    key: 0,
    class: "z-b4 f-inter"
};
const _hoisted_18 = { class: "z-b4 f-inter" };
const _hoisted_19 = { class: "instructor-details-list mobile-list" };
const _hoisted_20 = {
    key: 0,
    class: "z-b4 f-inter"
};
const _hoisted_21 = {
    key: 1,
    class: "z-b4 f-inter"
};
const _hoisted_22 = { href: "#instructor-reviews" };
const _hoisted_23 = {
    key: 0,
    class: "instructor-share-button-mobile cta-container"
};
const _hoisted_24 = { class: "fitness_types" };
const _hoisted_25 = {
    key: 0,
    class: "fitness_types_column"
};
const _hoisted_26 = { class: "z-b4 f-inter headline classes-posted-label" };
const _hoisted_27 = {
    key: 1,
    class: "licenses-and-certs-column fitness_types_column"
};
const _hoisted_28 = { class: "licenses-and-certs-label z-b4 f-inter headline" };
const _hoisted_29 = { class: "view-credentials-details-container" };
const _hoisted_30 = {
    key: 3,
    class: "instructor-details-editable"
};
const _hoisted_31 = { class: "editable-row" };
const _hoisted_32 = { class: "row-label f-inter" };
const _hoisted_33 = { class: "row-actions f-inter" };
const _hoisted_34 = { class: "editable-row" };
const _hoisted_35 = { class: "row-label f-inter" };
const _hoisted_36 = { class: "row-actions" };
const _hoisted_37 = { class: "my-page-url-preview" };
const _hoisted_38 = { class: "editable-row" };
const _hoisted_39 = { class: "row-label f-inter" };
const _hoisted_40 = { class: "row-actions" };
const _hoisted_41 = { class: "editable-row" };
const _hoisted_42 = { class: "row-label f-inter" };
const _hoisted_43 = { class: "row-actions f-inter" };
const _hoisted_44 = ["href", "title"];
const _hoisted_45 = {
    key: 0,
    class: "editable-row"
};
const _hoisted_46 = { class: "row-label f-inter" };
const _hoisted_47 = ["href"];
const _hoisted_48 = { class: "row-actions f-inter" };
const _hoisted_49 = {
    key: 0,
    class: "toggle-label"
};
const _hoisted_50 = ["href"];
const _hoisted_51 = { key: 1 };
const _hoisted_52 = {
    key: 1,
    class: "social"
};
const _hoisted_53 = { class: "z-b4 website-label social-section-subhead f-inter" };
const _hoisted_54 = { class: "website-action" };
const _hoisted_55 = ["href", "title"];
const _hoisted_56 = { class: "website-icon" };
const _hoisted_57 = { class: "website-title z-b3 f-inter" };
const _hoisted_58 = {
    key: 2,
    class: "hide-mobile"
};
const _hoisted_59 = { class: "z-b4 social-section-subhead hide-mobile f-inter" };
const _hoisted_60 = { class: "tip-action" };
const _hoisted_61 = { class: "leave-a-tip-icon" };
const _hoisted_62 = { class: "tip-text z-b3 f-inter" };
const _hoisted_63 = { class: "instructor-details-actions" };
const _hoisted_64 = { class: "cta-container-mobile" };
const _hoisted_65 = { key: 0 };
const _hoisted_66 = { class: "cta-container" };
const _hoisted_67 = {
    key: 0,
    class: "buttons-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ProfilePicture = _resolveComponent("ProfilePicture");
    const _component_PencilSvg = _resolveComponent("PencilSvg");
    const _component_InstructorNameModal = _resolveComponent("InstructorNameModal");
    const _component_IcExport = _resolveComponent("IcExport");
    const _component_ZButton = _resolveComponent("ZButton");
    const _component_contact_button_handler = _resolveComponent("contact-button-handler");
    const _component_HeartFilledSvg = _resolveComponent("HeartFilledSvg");
    const _component_HeartSvg = _resolveComponent("HeartSvg");
    const _component_QRCodeSVG = _resolveComponent("QRCodeSVG");
    const _component_StartSvg = _resolveComponent("StartSvg");
    const _component_HorizontalSeparator = _resolveComponent("HorizontalSeparator");
    const _component_Tag = _resolveComponent("Tag");
    const _component_ViewCredentialsModal = _resolveComponent("ViewCredentialsModal");
    const _component_IcPost = _resolveComponent("IcPost");
    const _component_Message = _resolveComponent("Message");
    const _component_Whatsapp = _resolveComponent("Whatsapp");
    const _component_ContactModal = _resolveComponent("ContactModal");
    const _component_MyPageURLModalHandler = _resolveComponent("MyPageURLModalHandler");
    const _component_SocialModal = _resolveComponent("SocialModal");
    const _component_WebsiteModal = _resolveComponent("WebsiteModal");
    const _component_social = _resolveComponent("social");
    const _component_WebSvg = _resolveComponent("WebSvg");
    const _component_GiftSvg = _resolveComponent("GiftSvg");
    const _component_ShareModal = _resolveComponent("ShareModal");
    const _component_QRCodeModal = _resolveComponent("QRCodeModal");
    return (_openBlock(), _createElementBlock("section", {
        id: "instructor-details",
        class: _normalizeClass(["instructor-section", { 'edit-mode': _ctx.editMode }])
    }, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
                class: _normalizeClass(["instructor-img", _ctx.profilePictureBackgroundClass])
            }, [
                _createVNode(_component_ProfilePicture, {
                    t: _ctx.t.profilePicture,
                    "instructor-type": _ctx.instructorTypeLabel,
                    "profile-photo-url": _ctx.instructorState.profile_photo_url,
                    "background-class": "",
                    "theme-color": _ctx.themeColor,
                    "edit-mode": _ctx.editMode,
                    "save-photo-action": _ctx.savePhotoAction,
                    "onPhoto:updated": _cache[0] || (_cache[0] = (preview) => _ctx.instructorState.profile_photo_url = preview)
                }, null, 8, ["t", "instructor-type", "profile-photo-url", "theme-color", "edit-mode", "save-photo-action"])
            ], 2),
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("h4", _hoisted_4, [
                        _createTextVNode(_toDisplayString(_ctx.instructorState.display_name) + " ", 1),
                        (_ctx.editMode)
                            ? (_openBlock(), _createBlock(_component_PencilSvg, {
                                key: 0,
                                class: "edit-mode-icon",
                                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.modals.name = !_ctx.modals.name))
                            }))
                            : _createCommentVNode("", true),
                        _createVNode(_component_InstructorNameModal, {
                            t: _ctx.t.instructorNameModal,
                            "instructor-name": _ctx.instructorState.display_name,
                            "show-modal": _ctx.modals.name,
                            error: _ctx.instructorState.error,
                            "handle-closed-modal": () => _ctx.modals.name = !_ctx.modals.name,
                            "onUpdate:name": _ctx.handleSavedName
                        }, null, 8, ["t", "instructor-name", "show-modal", "error", "handle-closed-modal", "onUpdate:name"])
                    ]),
                    (!_ctx.editMode)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            (_ctx.instructor.share_enabled)
                                ? (_openBlock(), _createBlock(_component_ZButton, {
                                    key: 0,
                                    variety: "cyclops",
                                    class: "share-button",
                                    compact: "desktop-only",
                                    onClick: _withModifiers(_ctx.handleShareButtonClick, ["prevent"])
                                }, {
                                    icon: _withCtx(() => [
                                        _createVNode(_component_IcExport)
                                    ]),
                                    _: 1
                                }, 8, ["onClick"]))
                                : _createCommentVNode("", true),
                            _createVNode(_component_contact_button_handler, {
                                t: _ctx.t,
                                "instructor-pid": _ctx.instructorPid,
                                "has-phone": _ctx.instructor.hasPhone,
                                "has-email": _ctx.instructor.hasEmail,
                                "has-whatsapp": _ctx.instructor.hasWhatsapp,
                                "instructor-contact-action": _ctx.instructorContactAction,
                                instructor: _ctx.instructor,
                                "requester-name": _ctx.requesterName,
                                "requester-email": _ctx.requesterEmail,
                                "recaptcha-key": _ctx.recaptchaKey,
                                "onToast:message": _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('toast:message', $event))),
                                "onRecaptcha:failure": _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('toast:message', { type: 'danger', message: $event }))),
                                "onClicked:contact": _ctx.mixpanelTrack
                            }, null, 8, ["t", "instructor-pid", "has-phone", "has-email", "has-whatsapp", "instructor-contact-action", "instructor", "requester-name", "requester-email", "recaptcha-key", "onClicked:contact"]),
                            _createElementVNode("div", _hoisted_6, [
                                _createVNode(_component_ZButton, {
                                    variety: _ctx.isFollowing ? 'phoenix' : 'optimus',
                                    mode: _ctx.isFollowing ? 'dark' : 'light',
                                    class: _normalizeClass(["follow-desktop follow-button", { 'following': _ctx.isFollowing }]),
                                    compact: "desktop-only",
                                    onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.followClick()))
                                }, {
                                    icon: _withCtx(() => [
                                        (_ctx.isFollowing && _ctx.showFollowIcon)
                                            ? (_openBlock(), _createBlock(_component_HeartFilledSvg, {
                                                key: 0,
                                                class: "following-icon"
                                            }))
                                            : (_ctx.showFollowIcon)
                                                ? (_openBlock(), _createBlock(_component_HeartSvg, {
                                                    key: 1,
                                                    class: "follow-icon"
                                                }))
                                                : _createCommentVNode("", true)
                                    ]),
                                    default: _withCtx(() => [
                                        _createTextVNode(" " + _toDisplayString(_ctx.isFollowing ? _ctx.t.saved : _ctx.t.save), 1)
                                    ]),
                                    _: 1
                                }, 8, ["variety", "mode", "class"])
                            ])
                        ]))
                        : _createCommentVNode("", true),
                    (_ctx.editMode)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            (_ctx.editMode)
                                ? (_openBlock(), _createBlock(_component_ZButton, {
                                    key: 0,
                                    variety: "love",
                                    compact: "desktop-only",
                                    class: "share-button",
                                    onClick: _withModifiers(_ctx.handleShareButtonClick, ["prevent"])
                                }, {
                                    icon: _withCtx(() => [
                                        _createVNode(_component_IcExport)
                                    ]),
                                    default: _withCtx(() => [
                                        _createTextVNode(" " + _toDisplayString(_ctx.t.share), 1)
                                    ]),
                                    _: 1
                                }, 8, ["onClick"]))
                                : _createCommentVNode("", true),
                            _createVNode(_component_ZButton, {
                                variety: "love",
                                compact: "desktop-only",
                                class: "share-button",
                                onClick: _cache[5] || (_cache[5] = _withModifiers(($event) => (_ctx.modals.qr = true), ["prevent"]))
                            }, {
                                icon: _withCtx(() => [
                                    _createVNode(_component_QRCodeSVG, { class: "qr-icon" })
                                ]),
                                _: 1
                            })
                        ]))
                        : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("ul", _hoisted_9, [
                        (_ctx.instructor.location)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_10, _toDisplayString(_ctx.instructor.location), 1))
                            : _createCommentVNode("", true),
                        _createElementVNode("li", _hoisted_11, _toDisplayString(_ctx.membershipTypeLabel), 1),
                        (_ctx.instructor.rating_score)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
                                _createVNode(_component_StartSvg),
                                _createTextVNode(" " + _toDisplayString(_ctx.instructor.rating_score), 1)
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.instructor.num_reviews)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                                _createElementVNode("a", _hoisted_14, _toDisplayString(_ctx.instructor.num_reviews) + " " + _toDisplayString(_ctx.t.ratings), 1)
                            ]))
                            : _createCommentVNode("", true)
                    ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("ul", _hoisted_16, [
                        (_ctx.instructor.location)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_17, _toDisplayString(_ctx.instructor.location), 1))
                            : _createCommentVNode("", true),
                        _createElementVNode("li", _hoisted_18, _toDisplayString(_ctx.membershipTypeLabel), 1)
                    ]),
                    _createElementVNode("ul", _hoisted_19, [
                        (_ctx.instructor.rating_score)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_20, [
                                _createVNode(_component_StartSvg),
                                _createTextVNode(" " + _toDisplayString(_ctx.instructor.rating_score), 1)
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.instructor.num_reviews)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_21, [
                                _createElementVNode("a", _hoisted_22, _toDisplayString(_ctx.instructor.num_reviews) + " " + _toDisplayString(_ctx.t.ratings), 1)
                            ]))
                            : _createCommentVNode("", true)
                    ])
                ]),
                (_ctx.editMode)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                        _createVNode(_component_ZButton, {
                            variety: "love",
                            compact: "desktop-only",
                            class: "share-button",
                            onClick: _withModifiers(_ctx.handleShareButtonClick, ["prevent"])
                        }, {
                            icon: _withCtx(() => [
                                _createVNode(_component_IcExport)
                            ]),
                            default: _withCtx(() => [
                                _createTextVNode(" " + _toDisplayString(_ctx.t.share), 1)
                            ]),
                            _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_ZButton, {
                            variety: "love",
                            compact: "desktop-only",
                            class: "share-button",
                            onClick: _cache[6] || (_cache[6] = _withModifiers(($event) => (_ctx.modals.qr = true), ["prevent"]))
                        }, {
                            icon: _withCtx(() => [
                                _createVNode(_component_QRCodeSVG, { class: "qr-icon" })
                            ]),
                            _: 1
                        })
                    ]))
                    : _createCommentVNode("", true),
                (!_ctx.editMode)
                    ? (_openBlock(), _createBlock(_component_HorizontalSeparator, {
                        key: 1,
                        class: "first-hr"
                    }))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_24, [
                    (_ctx.instructor.fitness_types?.length && !_ctx.editMode)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                            _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.t.classesPosted), 1),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.instructor.fitness_types, (fitness_type) => {
                                return (_openBlock(), _createBlock(_component_Tag, {
                                    key: fitness_type,
                                    class: "fitness-type-tag",
                                    "show-cancel": false,
                                    variety: "featured filled"
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.specialties[fitness_type]), 1)
                                    ]),
                                    _: 2
                                }, 1024));
                            }), 128))
                        ]))
                        : _createCommentVNode("", true),
                    (!_ctx.editMode)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                            _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.t.licensesCertifications), 1),
                            _createElementVNode("div", _hoisted_29, [
                                _createElementVNode("a", {
                                    class: "view-credentials-details f-inter",
                                    onClick: _cache[7] || (_cache[7] = _withModifiers(($event) => (_ctx.showCredentials = true), ["prevent"]))
                                }, _toDisplayString(_ctx.t.viewCredentials), 1)
                            ])
                        ]))
                        : _createCommentVNode("", true),
                    _createVNode(_component_ViewCredentialsModal, {
                        t: _ctx.t,
                        show: _ctx.showCredentials,
                        "profile-photo-url": _ctx.profilePhotoUrl,
                        style: _normalizeStyle(_ctx.style),
                        license: _ctx.license,
                        education: _ctx.education,
                        milestone: _ctx.milestone,
                        instructor: _ctx.instructor,
                        licenses: _ctx.licenses,
                        "enable-noom-badge": _ctx.enableNoomBadge,
                        onClosed: _cache[8] || (_cache[8] = ($event) => (_ctx.showCredentials = false))
                    }, null, 8, ["t", "show", "profile-photo-url", "style", "license", "education", "milestone", "instructor", "licenses", "enable-noom-badge"])
                ]),
                (!_ctx.editMode)
                    ? (_openBlock(), _createBlock(_component_HorizontalSeparator, {
                        key: 2,
                        class: "second-hr"
                    }))
                    : _createCommentVNode("", true),
                (_ctx.editMode)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                        _createElementVNode("div", _hoisted_31, [
                            _createElementVNode("div", _hoisted_32, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.t.contact), 1),
                                _createVNode(_component_PencilSvg, {
                                    class: "edit-mode-icon icons hide-desktop",
                                    onClick: _cache[9] || (_cache[9] = _withModifiers(($event) => (_ctx.showContactModal = true), ["prevent"]))
                                })
                            ]),
                            _createElementVNode("div", _hoisted_33, [
                                _createVNode(_component_IcPost, {
                                    class: _normalizeClass(["edit-mode-icon icons", { 'disabled': !_ctx.instructor.phone }])
                                }, null, 8, ["class"]),
                                _createVNode(_component_Message, {
                                    class: _normalizeClass(["edit-mode-icon icons message-icon", { 'disabled': !_ctx.instructor.email }])
                                }, null, 8, ["class"]),
                                _createVNode(_component_Whatsapp, {
                                    class: _normalizeClass(["edit-mode-icon icons", { 'disabled': !_ctx.instructor.whatsapp }])
                                }, null, 8, ["class"]),
                                _createVNode(_component_PencilSvg, {
                                    class: "edit-mode-icon icons hide-mobile",
                                    onClick: _cache[10] || (_cache[10] = _withModifiers(($event) => (_ctx.showContactModal = true), ["prevent"]))
                                }),
                                _createVNode(_component_ContactModal, {
                                    t: _ctx.t,
                                    show: _ctx.showContactModal,
                                    action: _ctx.detailsInstructorAction,
                                    "email-value": _ctx.instructor.email,
                                    "whatsapp-value": _ctx.instructor.whatsapp,
                                    "phone-value": _ctx.instructor.phone,
                                    "dial-codes": _ctx.dialCodes,
                                    onClosed: _cache[11] || (_cache[11] = ($event) => (_ctx.showContactModal = false))
                                }, null, 8, ["t", "show", "action", "email-value", "whatsapp-value", "phone-value", "dial-codes"])
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_34, [
                            _createElementVNode("div", _hoisted_35, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.t.myPageUrl), 1),
                                _createVNode(_component_PencilSvg, {
                                    class: _normalizeClass(["edit-mode-icon action-icon hide-desktop", { disabled: _ctx.instructorPageUrlDisableEditState }]),
                                    onClick: _cache[12] || (_cache[12] = ($event) => (_ctx.openMyPageURLModal()))
                                }, null, 8, ["class"])
                            ]),
                            _createElementVNode("div", _hoisted_36, [
                                _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.profileURL), 1),
                                _createVNode(_component_PencilSvg, {
                                    class: _normalizeClass(["edit-mode-icon action-icon hide-mobile", { disabled: _ctx.instructorPageUrlDisableEditState }]),
                                    onClick: _cache[13] || (_cache[13] = ($event) => (_ctx.openMyPageURLModal()))
                                }, null, 8, ["class"])
                            ]),
                            _createVNode(_component_MyPageURLModalHandler, {
                                t: _ctx.t.myPageUrlModal,
                                shortname: _ctx.instructorState.profile_shortname,
                                "show-modal": _ctx.modals['my_page_url'],
                                "save-url-action": _ctx.saveUrlAction,
                                "is-url-available-action": _ctx.isUrlAvailableAction,
                                onCancel: _cache[14] || (_cache[14] = ($event) => (_ctx.modals.my_page_url = !_ctx.modals.my_page_url)),
                                onSave: _cache[15] || (_cache[15] = ($event) => (_ctx.onSaveUrl($event)))
                            }, null, 8, ["t", "shortname", "show-modal", "save-url-action", "is-url-available-action"])
                        ]),
                        _createElementVNode("div", _hoisted_38, [
                            _createElementVNode("div", _hoisted_39, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.t.socialLabel), 1),
                                _createVNode(_component_PencilSvg, {
                                    class: "edit-mode-icon action-icon hide-desktop",
                                    onClick: _cache[16] || (_cache[16] = ($event) => (_ctx.showSocialsModal = true))
                                })
                            ]),
                            _createElementVNode("div", _hoisted_40, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.networks, (network) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                        key: network,
                                        class: _normalizeClass(["social-item", {
                                                'withValue': !!_ctx.instructor.socials[network]
                                            }])
                                    }, [
                                        (_openBlock(), _createBlock(_resolveDynamicComponent(network + '-icon')))
                                    ], 2));
                                }), 128)),
                                _createVNode(_component_PencilSvg, {
                                    class: "edit-mode-icon action-icon hide-mobile",
                                    onClick: _cache[17] || (_cache[17] = ($event) => (_ctx.showSocialsModal = true))
                                })
                            ]),
                            _createVNode(_component_SocialModal, {
                                t: _ctx.t,
                                action: _ctx.detailsInstructorAction,
                                "social-links": _ctx.instructor.socials,
                                show: _ctx.showSocialsModal,
                                onClosed: _cache[18] || (_cache[18] = ($event) => (_ctx.showSocialsModal = false))
                            }, null, 8, ["t", "action", "social-links", "show"])
                        ]),
                        _createElementVNode("div", _hoisted_41, [
                            _createElementVNode("div", _hoisted_42, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.t.websiteLabel), 1),
                                _createVNode(_component_PencilSvg, {
                                    class: "edit-mode-icon action-icon hide-desktop",
                                    onClick: _cache[19] || (_cache[19] = ($event) => (_ctx.modals.website = !_ctx.modals.website))
                                })
                            ]),
                            _createElementVNode("div", _hoisted_43, [
                                _createElementVNode("a", {
                                    href: _ctx.instructorState.website?.page_url,
                                    title: _ctx.instructorState.website?.title,
                                    target: "_blank",
                                    rel: "noopener"
                                }, _toDisplayString(_ctx.instructorState.website?.title || _ctx.instructorState.website?.page_url), 9, _hoisted_44),
                                _createVNode(_component_PencilSvg, {
                                    class: "edit-mode-icon action-icon hide-mobile",
                                    onClick: _cache[20] || (_cache[20] = ($event) => (_ctx.modals.website = !_ctx.modals.website))
                                }),
                                _createVNode(_component_WebsiteModal, {
                                    t: _ctx.t.websiteModal,
                                    "website-title": _ctx.instructorState.website?.title,
                                    "website-url": _ctx.instructorState.website?.page_url,
                                    "show-modal": _ctx.modals.website,
                                    error: _ctx.instructorState.error,
                                    "handle-closed-modal": () => _ctx.modals.website = !_ctx.modals.website,
                                    "website-title-scraper-action": _ctx.websiteTitleScraperAction,
                                    "onUpdate:website": _ctx.handleSavedWebsite
                                }, null, 8, ["t", "website-title", "website-url", "show-modal", "error", "handle-closed-modal", "website-title-scraper-action", "onUpdate:website"])
                            ])
                        ]),
                        (_ctx.instructor.tipping_enabled)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                                _createElementVNode("div", _hoisted_46, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.t.tippingLabel), 1),
                                    (_ctx.instructor.tip_email)
                                        ? (_openBlock(), _createElementBlock("a", {
                                            key: 0,
                                            href: _ctx.classManagementSettings
                                        }, [
                                            _createVNode(_component_PencilSvg, { class: "edit-mode-icon action-icon hide-desktop" })
                                        ], 8, _hoisted_47))
                                        : _createCommentVNode("", true)
                                ]),
                                _createElementVNode("div", _hoisted_48, [
                                    (_ctx.instructor.tip_email)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_49, _toDisplayString(_ctx.instructor.instructor_tipping ? _ctx.t.on : _ctx.t.off), 1))
                                        : _createCommentVNode("", true),
                                    _createElementVNode("a", { href: _ctx.classManagementSettings }, [
                                        (_ctx.instructor.tip_email)
                                            ? (_openBlock(), _createBlock(_component_PencilSvg, {
                                                key: 0,
                                                class: "edit-mode-icon action-icon hide-mobile"
                                            }))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_51, _toDisplayString(_ctx.t.setUp), 1))
                                    ], 8, _hoisted_50)
                                ])
                            ]))
                            : _createCommentVNode("", true)
                    ]))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 4,
                        class: _normalizeClass(["social-section", { 'hide-mobile': !_ctx.hasSocialLinks && !Boolean(_ctx.instructor.website?.page_url) }])
                    }, [
                        (_ctx.hasSocialLinks)
                            ? (_openBlock(), _createBlock(_component_social, {
                                key: 0,
                                t: _ctx.t,
                                "social-links": _ctx.instructor.socials,
                                size: "mobile-large"
                            }, null, 8, ["t", "social-links"]))
                            : _createCommentVNode("", true),
                        (_ctx.instructor.website?.page_url)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                                _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.t.websiteLabel), 1),
                                _createElementVNode("div", _hoisted_54, [
                                    _createElementVNode("a", {
                                        href: _ctx.instructor.website.page_url,
                                        title: _ctx.instructor.website.title,
                                        target: "_blank",
                                        rel: "noopener"
                                    }, [
                                        _createElementVNode("span", _hoisted_56, [
                                            _createVNode(_component_WebSvg)
                                        ]),
                                        _createElementVNode("span", _hoisted_57, _toDisplayString(_ctx.websiteTitle), 1)
                                    ], 8, _hoisted_55)
                                ])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.instructor.instructor_tipping && _ctx.instructor.tipping_enabled)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_58, [
                                _createElementVNode("div", _hoisted_59, _toDisplayString(_ctx.t.leaveATipLabel), 1),
                                _createElementVNode("div", _hoisted_60, [
                                    _createElementVNode("a", {
                                        onClick: _cache[21] || (_cache[21] = _withModifiers(($event) => (_ctx.$emit('tip-modal:open')), ["prevent"]))
                                    }, [
                                        _createElementVNode("span", _hoisted_61, [
                                            _createVNode(_component_GiftSvg)
                                        ]),
                                        _createElementVNode("span", _hoisted_62, _toDisplayString(_ctx.t.leaveATipLinkTitle), 1)
                                    ])
                                ])
                            ]))
                            : _createCommentVNode("", true)
                    ], 2)),
                _createElementVNode("div", _hoisted_63, [
                    _createElementVNode("div", _hoisted_64, [
                        (!_ctx.editMode)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                                _createVNode(_component_ZButton, {
                                    variety: _ctx.isFollowing ? 'phoenix' : 'optimus',
                                    mode: _ctx.isFollowing ? 'dark' : 'light',
                                    class: _normalizeClass(["follow-mobile follow-button", { 'following': _ctx.isFollowing }]),
                                    compact: "desktop-only",
                                    onClick: _cache[22] || (_cache[22] = ($event) => (_ctx.followClick()))
                                }, {
                                    icon: _withCtx(() => [
                                        (_ctx.isFollowing && _ctx.showFollowIcon)
                                            ? (_openBlock(), _createBlock(_component_HeartFilledSvg, {
                                                key: 0,
                                                class: "following-icon"
                                            }))
                                            : (_ctx.showFollowIcon)
                                                ? (_openBlock(), _createBlock(_component_HeartSvg, {
                                                    key: 1,
                                                    class: "follow-icon"
                                                }))
                                                : _createCommentVNode("", true)
                                    ]),
                                    default: _withCtx(() => [
                                        _createTextVNode(" " + _toDisplayString(_ctx.isFollowing ? _ctx.t.saved : _ctx.t.save), 1)
                                    ]),
                                    _: 1
                                }, 8, ["variety", "mode", "class"])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_66, [
                        (!_ctx.editMode)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
                                _createVNode(_component_contact_button_handler, {
                                    class: "contact-button",
                                    t: _ctx.t,
                                    "instructor-pid": _ctx.instructorPid,
                                    "has-phone": _ctx.instructor.hasPhone,
                                    "has-email": _ctx.instructor.hasEmail,
                                    "has-whatsapp": _ctx.instructor.hasWhatsapp,
                                    "always-show-label": true,
                                    "show-icon": false,
                                    "instructor-contact-action": _ctx.instructorContactAction,
                                    instructor: _ctx.instructor,
                                    "requester-name": _ctx.requesterName,
                                    "requester-email": _ctx.requesterEmail,
                                    "recaptcha-key": _ctx.recaptchaKey,
                                    "onToast:message": _cache[23] || (_cache[23] = ($event) => (_ctx.$emit('toast:message', $event))),
                                    "onRecaptcha:failure": _cache[24] || (_cache[24] = ($event) => (_ctx.$emit('toast:message', { type: 'danger', message: $event }))),
                                    "onClicked:contact": _ctx.mixpanelTrack
                                }, null, 8, ["t", "instructor-pid", "has-phone", "has-email", "has-whatsapp", "instructor-contact-action", "instructor", "requester-name", "requester-email", "recaptcha-key", "onClicked:contact"]),
                                (_ctx.instructor.instructor_tipping && _ctx.instructor.tipping_enabled)
                                    ? (_openBlock(), _createBlock(_component_ZButton, {
                                        key: 0,
                                        variety: "cyclops",
                                        class: "mobile-button",
                                        compact: "desktop-only",
                                        onClick: _cache[25] || (_cache[25] = _withModifiers(($event) => (_ctx.$emit('tip-modal:open')), ["prevent"]))
                                    }, {
                                        icon: _withCtx(() => [
                                            _createVNode(_component_GiftSvg)
                                        ]),
                                        _: 1
                                    }))
                                    : _createCommentVNode("", true),
                                (_ctx.instructor.share_enabled)
                                    ? (_openBlock(), _createBlock(_component_ZButton, {
                                        key: 1,
                                        variety: "cyclops",
                                        class: "mobile-button",
                                        compact: "desktop-only",
                                        onClick: _withModifiers(_ctx.handleShareButtonClick, ["prevent"])
                                    }, {
                                        icon: _withCtx(() => [
                                            _createVNode(_component_IcExport)
                                        ]),
                                        _: 1
                                    }, 8, ["onClick"]))
                                    : _createCommentVNode("", true)
                            ]))
                            : _createCommentVNode("", true)
                    ])
                ])
            ])
        ]),
        _createVNode(_component_ShareModal, {
            t: _ctx.t,
            "share-type": _ctx.QRCodeShareType.SHARE_INSTRUCTOR,
            "fb-app-id": _ctx.fbAppId,
            show: _ctx.modals.share,
            "share-data": _ctx.shareData,
            simplified: "",
            onClose: _cache[26] || (_cache[26] = ($event) => (_ctx.modals.share = false))
        }, null, 8, ["t", "share-type", "fb-app-id", "show", "share-data"]),
        (_ctx.shareData?.qrSRC)
            ? (_openBlock(), _createBlock(_component_QRCodeModal, {
                key: 0,
                t: _ctx.t,
                show: _ctx.modals.qr,
                "instructor-data": {
                    title: _ctx.instructorState.display_name,
                    location: _ctx.instructorState?.location,
                    membership_type: _ctx.membershipTypeLabel,
                    rating_score: _ctx.instructorState?.rating_score,
                    num_reviews: _ctx.instructorState?.num_reviews,
                },
                "qr-src": _ctx.shareData?.qrSRC ?? '',
                "share-qr-url": _ctx.shareData.qrShareURL,
                "share-type": _ctx.QRCodeShareType.SHARE_INSTRUCTOR,
                onClose: _cache[27] || (_cache[27] = ($event) => (_ctx.modals.qr = false))
            }, null, 8, ["t", "show", "instructor-data", "qr-src", "share-qr-url", "share-type"]))
            : _createCommentVNode("", true)
    ], 2));
}
