import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "instructor-about-section" };
const _hoisted_2 = { class: "instructor-section--header" };
const _hoisted_3 = { class: "instructor-section__title--redesign z-h1" };
const _hoisted_4 = {
    key: 0,
    class: "instructor-about-header z-b2 f-inter"
};
const _hoisted_5 = { class: "instructor-body" };
const _hoisted_6 = { key: 0 };
const _hoisted_7 = { key: 1 };
const _hoisted_8 = {
    key: 1,
    class: "z-b3 f-inter"
};
const _hoisted_9 = { class: "about-me z-b3 f-inter" };
const _hoisted_10 = {
    key: 0,
    class: "instructor-about-header z-b2 f-inter"
};
const _hoisted_11 = { class: "membership-details" };
const _hoisted_12 = { key: 0 };
const _hoisted_13 = { class: "member-since" };
const _hoisted_14 = {
    key: 3,
    class: "about-me z-b3 f-inter"
};
const _hoisted_15 = { class: "instructor-about-header z-b2 f-inter" };
const _hoisted_16 = { class: "profile-badges-container" };
const _hoisted_17 = { class: "view-all-link" };
const _hoisted_18 = { class: "horizontal-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_PencilIcon = _resolveComponent("PencilIcon");
    const _component_HorizontalSeparator = _resolveComponent("HorizontalSeparator");
    const _component_ProfileBadges = _resolveComponent("ProfileBadges");
    const _component_AboutModal = _resolveComponent("AboutModal");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("section", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.t.about), 1),
                (_ctx.editMode)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        class: "",
                        href: "#",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => (_ctx.showEditModal = true), ["prevent"]))
                    }, [
                        _createVNode(_component_PencilIcon)
                    ]))
                    : _createCommentVNode("", true)
            ]),
            (_ctx.about !== '')
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.t.bio), 1))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
                (_ctx.biography)
                    ? (_openBlock(), _createElementBlock("p", {
                        key: 0,
                        class: _normalizeClass(["bio-text z-b3 f-inter", { 'show-overflow': _ctx.moreText }])
                    }, [
                        (!_ctx.moreText && _ctx.showMoreLink)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.truncatedBio), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.biography), 1))
                    ], 2))
                    : _createCommentVNode("", true),
                (_ctx.showMoreLink || _ctx.moreText)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        class: "more-text__link z-b3 f-inter",
                        onClick: _cache[1] || (_cache[1] =
                            //@ts-ignore
                            (...args) => (_ctx.toggleMoreText && _ctx.toggleMoreText(...args)))
                    }, _toDisplayString(_ctx.moreText ? 'Read Less' : 'Read More'), 1))
                    : _createCommentVNode("", true)
            ]),
            (_ctx.about === '' && _ctx.editMode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createTextVNode(_toDisplayString(_ctx.t.noBioPosted) + " ", 1),
                    _createElementVNode("a", {
                        class: "z-b3 f-inter",
                        href: "#",
                        onClick: _cache[2] || (_cache[2] = _withModifiers(($event) => (_ctx.showEditModal = true), ["prevent"]))
                    }, _toDisplayString(_ctx.t.add), 1)
                ]))
                : _createCommentVNode("", true),
            (_ctx.about !== '' || _ctx.editMode)
                ? (_openBlock(), _createBlock(_component_HorizontalSeparator, { key: 2 }))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_9, [
                (_ctx.memberships.length > 0)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.t.memberSince), 1))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.memberships, (membership, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                            key: index,
                            class: "member-type z-b3 f-inter"
                        }, [
                            (membership.start_date)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                                    _createTextVNode(_toDisplayString(membership.membership_type) + " " + _toDisplayString(_ctx.t.instructor) + " ", 1),
                                    _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.sinceYearMemberships[membership.membership_type]), 1)
                                ]))
                                : _createCommentVNode("", true)
                        ]));
                    }), 128))
                ])
            ]),
            _createVNode(_component_HorizontalSeparator),
            (_ctx.instructorBadges.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.t.achievements), 1),
                    _createElementVNode("div", _hoisted_16, [
                        _createVNode(_component_ProfileBadges, {
                            "badge-items": _ctx.visibleBadges,
                            "enable-noom-badge": _ctx.enableNoomBadge
                        }, null, 8, ["badge-items", "enable-noom-badge"])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                        (_ctx.showViewAllAchievements)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                class: "f-inter",
                                onClick: _cache[3] || (_cache[3] = _withModifiers(
                                //@ts-ignore
                                (...args) => (_ctx.showMore && _ctx.showMore(...args)), ["prevent"]))
                            }, _toDisplayString(_ctx.t.viewAllAchievements), 1))
                            : _createCommentVNode("", true),
                        (_ctx.showViewLessAchievements)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                class: "f-inter",
                                onClick: _cache[4] || (_cache[4] = _withModifiers(
                                //@ts-ignore
                                (...args) => (_ctx.showLess && _ctx.showLess(...args)), ["prevent"]))
                            }, _toDisplayString(_ctx.t.viewLessAchievements), 1))
                            : _createCommentVNode("", true)
                    ])
                ]))
                : _createCommentVNode("", true),
            _createVNode(_component_AboutModal, {
                t: _ctx.t,
                show: _ctx.showEditModal,
                action: _ctx.detailsInstructorAction,
                "about-value": _ctx.about,
                "licenses-value": _ctx.licenses,
                "license-badges": _ctx.licenseBadges,
                onClosed: _cache[5] || (_cache[5] = ($event) => (_ctx.showEditModal = false))
            }, null, 8, ["t", "show", "action", "about-value", "licenses-value", "license-badges"])
        ]),
        _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_HorizontalSeparator)
        ])
    ], 64));
}
