import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "instructor-section"
};
const _hoisted_2 = { class: "instructor-section__header" };
const _hoisted_3 = { class: "instructor-section__title f-inter" };
const _hoisted_4 = ["href"];
const _hoisted_5 = {
    key: 1,
    class: "instructor-section__filters"
};
const _hoisted_6 = { key: 0 };
const _hoisted_7 = { class: "class-list" };
const _hoisted_8 = {
    key: 1,
    class: "class-list__show-more"
};
import { computed, ref } from 'vue';
import { validateTranslations } from '@ts/Util/i18n';
import EditIcon from '@bx-icons/regular/bx-pencil.svg';
import SessionListElement from '@components/SessionsList/SessionListElement.vue';
import { theme, ThemeType } from "@ts/Util/theme";
const translationDefaults = {
    strongTitle: 'Sync Labs',
    zumbaTitle: 'Jam Sessions',
    showMore: 'Show More',
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'SessionsList',
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        sessions: {
            type: Array,
            required: true,
        },
        editMode: {
            type: Boolean,
            default: () => false
        },
        manageSessionsAction: {
            type: String,
            default: ''
        },
        hideFilters: {
            type: Boolean,
            default: true
        },
        pageSize: {
            type: Number,
            default: 3
        }
    },
    setup(__props) {
        const props = __props;
        const visibleRecords = ref(props.pageSize);
        const filteredClasses = computed(() => {
            if (!props.sessions) {
                return [];
            }
            //TBD filters
            return props.sessions;
        });
        const visibleSessions = computed(() => {
            return filteredClasses.value.slice(0, visibleRecords.value);
        });
        const titleThemed = computed(() => {
            return theme.value === ThemeType.Zumba ? props.t.zumbaTitle : props.t.strongTitle;
        });
        return (_ctx, _cache) => {
            return (__props.editMode || __props.sessions?.length)
                ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("h5", _hoisted_3, _toDisplayString(titleThemed.value), 1),
                        (__props.editMode)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                href: __props.manageSessionsAction,
                                class: "edit-icon"
                            }, [
                                _createVNode(_unref(EditIcon))
                            ], 8, _hoisted_4))
                            : _createCommentVNode("", true),
                        ((!__props.editMode || __props.sessions?.length) && !__props.hideFilters)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, " TBD filters "))
                            : _createCommentVNode("", true)
                    ]),
                    (visibleSessions.value?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleSessions.value, (session) => {
                                    return (_openBlock(), _createBlock(SessionListElement, {
                                        key: session.id,
                                        "session-data": session,
                                        t: __props.t
                                    }, null, 8, ["session-data", "t"]));
                                }), 128))
                            ])
                        ]))
                        : _createCommentVNode("", true),
                    (filteredClasses.value.length > visibleRecords.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("a", {
                                class: "f-inter",
                                onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => (visibleRecords.value += __props.pageSize), ["prevent"]))
                            }, _toDisplayString(__props.t.showMore), 1)
                        ]))
                        : _createCommentVNode("", true)
                ]))
                : _createCommentVNode("", true);
        };
    }
});
