import Modal from '@components/Core/Modal.vue';
import { defineComponent, computed } from 'vue';
import ProfilePictureDefault from '@modules/InstructorPage/ProfilePictureDefault.vue';
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue';
import ProfileCredentials from '@modules/InstructorPage/ProfileCredentials.vue';
import { isMobileReactive } from '@ts/Util/responsiveness';
export default defineComponent({
    name: 'VieCredentialModal',
    components: {
        HorizontalSeparator,
        Modal,
        ProfileCredentials,
        ProfilePictureDefault
    },
    props: {
        t: {
            type: Object,
            default: () => ({
                licenseToTeach: 'License to Teach',
                profileImageAlt: 'Instructor picture',
                print: 'print',
                additionalLicensesCertifications: 'Additional Licenses and Certifications'
            })
        },
        show: {
            type: Boolean,
            required: true,
            default: false
        },
        instructor: {
            type: Object,
            required: true
        },
        profilePhotoUrl: {
            type: String,
            default: '',
        },
        style: {
            type: Object,
            default: () => ({})
        },
        license: {
            type: Array,
            required: true,
        },
        education: {
            type: Array,
            required: true,
        },
        milestone: {
            type: Array,
            required: true,
        },
        licenses: {
            type: String,
            required: true,
        },
        enableNoomBadge: {
            type: Boolean,
            default: false
        },
    },
    emits: [
        'close'
    ],
    setup(props) {
        const formatDate = (dateTime) => {
            if (!dateTime) {
                return null;
            }
            const date = new Date(dateTime);
            return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
        };
        const membershipTypeLabel = computed(() => {
            const membershipType = props.instructor.membership;
            let joinDate = props.instructor.join_date ? formatDate(props.instructor.join_date) : null;
            if (!membershipType) {
                return '';
            }
            else {
                return membershipType + ", " + joinDate;
            }
        });
        const isMobile = isMobileReactive();
        const customModalStyles = computed(() => {
            return {
                modal: {
                    border: 0,
                    width: isMobile.value ? '100%' : '46.875rem',
                    paddingTop: isMobile.value ? '3.8125rem' : '2.5rem',
                    paddingBottom: isMobile.value ? '1.25rem' : '2.5rem',
                    paddingLeft: isMobile.value ? '1rem' : '2.5rem',
                    paddingRight: isMobile.value ? '1rem' : '2.5rem',
                    borderRadius: isMobile.value ? '0.5rem 0.5rem 0 0' : '0.25rem'
                }
            };
        });
        return {
            isMobile,
            membershipTypeLabel,
            formatDate,
            customModalStyles
        };
    },
});
