import { defineComponent, ref, computed, reactive } from 'vue';
import { validateTranslations } from '@ts/Util/i18n';
import Modal from '@components/Core/Modal.vue';
import ZButton from '@components/Core/ZButton.vue';
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue';
import ZInput from '@components/Core/ZInput.vue';
import { interpolate } from '@ts/Util/text';
const translationDefaults = {
    title: 'My Page URL',
    shortname: 'Usename',
    save: 'Save',
    cancel: 'Cancel',
    baseUrl: 'https://zumba.com/p/{0}',
    description: 'Enter your preferred username to customize the URL of this instructor page.',
    urlEditRequirementsHeader: 'Please be sure that your username:',
    urlEditRequirements1: 'begins and ends with a letter or number',
    urlEditRequirements2: 'is unique to you',
    errorCustomURL: 'The custom URL must be between 4-40 characters, and can only contain letters and numbers.',
    errorCustomURLTaken: "This custom URL is already taken please select another"
};
export default defineComponent({
    name: 'MyPageURLModal',
    components: {
        Modal,
        ZButton,
        ZInput,
        HorizontalSeparator
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        shortname: {
            type: String,
            default: '',
        },
        shortnameTaken: {
            type: Boolean,
            default: false
        },
        disable: {
            type: Boolean,
            default: false
        },
        showModal: {
            type: Boolean,
            required: true,
            default: false
        },
    },
    emits: ['confirm', 'cancel'],
    setup(props, context) {
        const show = ref(props.showModal);
        const formData = reactive({
            shortname: props.shortname
        });
        const state = reactive({
            isValid: true
        });
        const validate = () => {
            state.isValid = formData.shortname.match(/^[a-z\d]{4,40}$/gi) !== null;
        };
        const urlText = computed(() => interpolate(props.t.baseUrl, [`<strong>${formData.shortname}</strong>`]));
        const confirm = () => {
            validate();
            if (state.isValid) {
                context.emit('confirm', formData.shortname);
            }
        };
        const errorMessage = computed(() => {
            if (!state.isValid) {
                return props.t.errorCustomURL;
            }
            else if (props.shortnameTaken && props.shortname === formData.shortname) {
                return props.t.errorCustomURLTaken;
            }
            return '';
        });
        return {
            show,
            state,
            formData,
            urlText,
            confirm,
            validate,
            errorMessage
        };
    },
});
