import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "session-element f-inter" };
const _hoisted_2 = { class: "session-element__info" };
const _hoisted_3 = { class: "session-element__date z-b2" };
const _hoisted_4 = { class: "session-element__details" };
const _hoisted_5 = { class: "session-element__address z-b4" };
const _hoisted_6 = ["href"];
const _hoisted_7 = { class: "session-element__address z-b4" };
const _hoisted_8 = { class: "session-element__cta" };
const _hoisted_9 = {
    key: 0,
    class: "session-element__price"
};
const _hoisted_10 = { class: "session-element__cta-elements" };
import { computed } from 'vue';
import { formatCurrencyStr } from "@ts/Util/currency";
import { currentUserLocale } from '@ts/Util/i18n';
import ZButton from "@components/Core/ZButton.vue";
import { theme, ThemeType } from "@ts/Util/theme";
import { validateTranslations } from '@ts/Util/i18n';
const translationDefaults = {
    register: 'Register',
    soldOut: 'Sold Out',
    language: 'Language',
    virtual: 'Virtual',
    inPerson: 'In Person',
    online: 'Online',
    details: 'Details',
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'SessionListElement',
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        sessionData: {
            type: Object,
            required: true
        },
    },
    setup(__props) {
        const props = __props;
        const classDateText = computed(() => {
            const dateFormatOpts = { weekday: 'long', month: 'short', day: 'numeric' };
            const classDate = new Date(props.sessionData.date);
            let classDateTextFormatted = "";
            try {
                classDateTextFormatted = new Intl.DateTimeFormat(currentUserLocale(), dateFormatOpts).format(classDate);
            }
            catch (error) {
                //safari 15 support: https://github.com/w3c/respec/issues/1357
                const classDate = new Date(props.sessionData.date.replace(/-/g, "/"));
                classDateTextFormatted = new Intl.DateTimeFormat(currentUserLocale(), dateFormatOpts).format(classDate);
            }
            return classDateTextFormatted;
        });
        const priceFormatted = computed(() => {
            return formatCurrencyStr(props.sessionData.price || 0, props.sessionData.price_currency, currentUserLocale(), true);
        });
        const ctaText = computed(() => {
            if (props.sessionData.status === 'sold-out' || props.sessionData.status === 'sold_out') {
                return props.t.soldOut;
            }
            else if (props.sessionData.status === 'info_only') {
                return props.t.details;
            }
            else {
                return props.t.register;
            }
        });
        const ctaDisabled = computed(() => {
            return props.sessionData.status === 'sold-out' || props.sessionData.status === 'sold_out' || props.sessionData.status === 'info_only';
        });
        const platformTypeText = computed(() => {
            let text = '';
            if (props.sessionData.platform_type === 'virtual') {
                text = props.t.virtual;
            }
            else if (props.sessionData.platform_type === 'online') {
                text = props.t.online;
            }
            else {
                text = props.t.inPerson;
            }
            return text;
        });
        const buttonThemed = theme.value === ThemeType.Zumba ? 'rogue' : 'love';
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("span", null, _toDisplayString(classDateText.value), 1)
                    ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.platformTypeText) + " • " + _toDisplayString(__props.sessionData.location), 1),
                    _createElementVNode("a", {
                        class: "session-element__title p f-inter",
                        href: __props.sessionData.details_url
                    }, _toDisplayString(__props.sessionData.title), 9, _hoisted_6),
                    _createElementVNode("div", _hoisted_7, _toDisplayString(__props.t.language) + ": " + _toDisplayString(__props.sessionData.language), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                    (__props.sessionData.price)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(priceFormatted.value), 1))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_10, [
                        _createVNode(ZButton, {
                            class: "session-element__book-button",
                            disabled: ctaDisabled.value,
                            variety: _unref(buttonThemed),
                            href: __props.sessionData.register_url || __props.sessionData.details_url
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(ctaText.value), 1)
                            ]),
                            _: 1
                        }, 8, ["disabled", "variety", "href"])
                    ])
                ])
            ]));
        };
    }
});
