/**
 * URL validator
 */
export const urlValidator = (value) => {
    try {
        return Boolean(new URL(value));
    }
    catch {
        return false;
    }
};
export const urlToJson = (url) => {
    const a = document.createElement('a');
    a.href = url;
    return (({ protocol, username, password, host, hostname, port, pathname, hash, search }) => ({
        protocol,
        username,
        password,
        host,
        hostname,
        port,
        pathname,
        hash,
        search
    }))(a);
};
export const addParamToUrl = (key, value) => {
    const _window = window;
    const searchParams = new URLSearchParams(_window.location.search);
    !searchParams.has(key) ? searchParams.append(key, value) : searchParams.set(key, value);
    _window.history.replaceState(null, '', `${_window.location.pathname}?${searchParams.toString()}`);
};
export const getUrlParamValue = (key) => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has(key)) {
        return null;
    }
    return searchParams.get(key);
};
