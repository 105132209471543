import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import Modal from '@components/Core/Modal.vue';
import { isMobileReactive } from '@ts/Util/responsiveness';
import { supportedCurrencies, DEFAULT_CURRENCY } from '../../ts/Util/currency';
import { computed } from "vue";
import PayPalTipHandler from "@components/Buy/PayPalTipHandler.vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'TipInstructorModal',
    props: {
        t: {
            type: Object,
            default: () => ({
                tipTitle: 'Send tip to',
                tipSubtitle: 'Show some love to your instructor by sending a tip',
                tipAmountPlaceholder: 'Enter tip amount',
                sendTip: 'Send tip',
                tipCancel: 'Cancel',
            })
        },
        instructorName: {
            type: String,
            required: true,
        },
        show: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'light',
            validator: (value) => {
                // You can add more modes types here
                return [
                    'light',
                    'dark',
                ].includes(value);
            }
        },
        currencies: {
            type: Array,
            default: () => supportedCurrencies
        },
        currency: {
            type: String,
            default: DEFAULT_CURRENCY
        },
        instructorPid: {
            type: String,
            required: true,
        },
        storeTipUrl: {
            type: String,
            default: ''
        },
        markTipUrl: {
            type: String,
            default: ''
        },
        asyncContactRetrieval: {
            type: Boolean,
            default: true
        },
        tipDataAction: {
            type: String,
            default: ''
        }
    },
    emits: ['closed', 'toast:message'],
    setup(__props) {
        const localT = {
            tipTitle: 'Send tip to',
            tipSubtitle: 'Show some love to your instructor by sending a tip',
            tipAmountPlaceholder: 'Enter tip amount',
            sendTip: 'Send tip',
            cancel: 'Cancel'
        };
        const props = __props;
        const modalStyles = computed(() => {
            const isMobile = isMobileReactive();
            const styles = {};
            styles['modal__close'] = 'padding: 1rem';
            if (isMobile.value) {
                styles['modal'] = {
                    borderTopLeftRadius: '0.35rem',
                    borderTopRightRadius: '0.35rem',
                };
            }
            else {
                styles['modal'] = {
                    borderRadius: '0.35rem',
                    padding: '1.5rem 2rem 0.5rem'
                };
            }
            return styles;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(Modal, {
                show: __props.show,
                mode: __props.mode,
                "custom-styles": modalStyles.value,
                "mobile-drawer": true,
                style: { "z-index": "9999" },
                onClosed: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('closed')))
            }, {
                default: _withCtx(() => [
                    _createVNode(PayPalTipHandler, {
                        t: __props.t,
                        "instructor-name": __props.instructorName,
                        currencies: __props.currencies,
                        currency: __props.currency,
                        "instructor-pid": __props.instructorPid,
                        "store-tip-url": __props.storeTipUrl,
                        "mark-tip-url": __props.markTipUrl,
                        "async-contact-retrieval": __props.asyncContactRetrieval,
                        "tip-data-action": __props.tipDataAction,
                        "gift-source": 'instructor',
                        onClosed: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('closed'))),
                        "onToast:message": _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('toast:message', $event)))
                    }, null, 8, ["t", "instructor-name", "currencies", "currency", "instructor-pid", "store-tip-url", "mark-tip-url", "async-contact-retrieval", "tip-data-action"])
                ]),
                _: 1
            }, 8, ["show", "mode", "custom-styles"]));
        };
    }
});
