import { createApp, defineComponent, ref } from 'vue/dist/vue.esm-bundler';
import ClassPacks from "@modules/InstructorPage/ClassPacks.vue";
import Social from "@modules/InstructorPage/Social.vue";
import Vods from "@modules/InstructorPage/Vods.vue";
import LiveClasses from "@modules/InstructorPage/LiveClasses.vue";
import LiveClassesHandler from "@modules/InstructorPage/LiveClassesHandler.vue";
import SessionsList from "@modules/InstructorPage/SessionsList.vue";
import Reviews from "@modules/InstructorPage/Reviews.vue";
import ReviewsRedesign from '@modules/InstructorPage/ReviewsRedesign.vue';
import ProfilePicture from "@modules/InstructorPage/ProfilePicture.vue";
import ProfilePictureDefault from "@modules/InstructorPage/ProfilePictureDefault.vue";
import About from "@modules/InstructorPage/About.vue";
import AboutRedesign from "@modules/InstructorPage/AboutRedesign.vue";
import InstagramOptions from "@modules/InstructorPage/InstagramOptions.vue";
import ContactButton from '@components/ContactButton/ContactButton.vue';
import InstructorCover from '@modules/InstructorPage/InstructorCover.vue';
import InstructorCoverRedesign from '@modules/InstructorPage/InstructorCoverRedesign.vue';
import TopNav from '@modules/InstructorPage/TopNav.vue';
import TipInstructorModal from '@modules/InstructorPage/TipInstructorModal.vue';
import ViewAs from '@modules/InstructorPage/ViewAs.vue';
import Toast from "@components/Core/Toast.vue";
import NoDirectPayment from "@components/Buy/NoDirectPayment.vue";
import { get, post, redirect } from "@ts/Util/http";
import { csrfToken } from "@ts/Util/security";
import { currentUserLocale } from "@ts/Util/i18n";
import { createLead } from "@ts/Util/Leads";
import { readPageData } from '@ts/Util/page';
import ContactInstructor from "@modules/InstructorPage/ContactInstructor.vue";
import ContactInstructorHandler from "@modules/InstructorPage/ContactInstructorHandler.vue";
import NoomBanner from "@modules/InstructorPage/NoomBanner.vue";
const InstructorPage = defineComponent({
    name: 'InstructorPage',
    components: {
        InstructorCover,
        InstructorCoverRedesign,
        SessionsList,
        LiveClasses,
        Vods,
        ClassPacks,
        About,
        AboutRedesign,
        InstagramOptions,
        Reviews,
        ReviewsRedesign,
        ContactButton,
        Social,
        ProfilePicture,
        TopNav,
        ProfilePictureDefault,
        TipInstructorModal,
        ViewAs,
        NoDirectPayment,
        Toast,
        ContactInstructor,
        ContactInstructorHandler,
        LiveClassesHandler,
        NoomBanner,
    },
    setup() {
        const showTipModal = ref(false);
        const showNoDirectPaymentModal = ref(false);
        const loadingNoDirectPaymentModal = ref(false);
        const selectedClass = ref(null);
        function toggleTipModal(show) {
            showTipModal.value = show;
        }
        function showToastMessage(event) {
            toastState.value.queue.push({
                type: event.type,
                message: event.message,
                title: event.title
            });
        }
        const toastState = ref({
            queue: Array()
        });
        const locale = currentUserLocale() ?? 'en-US';
        const postRegisterLead = () => createLead(selectedClass.value?.classDetails?.id, 'Instructor Page')
            .catch((_) => toastState.value.queue.push({
            messageType: 'ErrorSaving',
            type: "danger"
        }));
        const getClassInfo = async (classId) => {
            const response = await get(`/${locale}/buy/noDirectPayment/${classId}`, csrfToken(), {});
            return await response.json();
        };
        const instagramFeedLoading = ref(false);
        const instagramFeedEnabled = ref(readPageData('instagramFeedEnabled'));
        const instagramUsername = ref(readPageData('instagramFeedUsername'));
        const instagramFeedShow = ref(readPageData('instagramFeedShow'));
        const noomBannerEnabled = ref(readPageData('noomBannerEnabled'));
        const instagramFeedDisconnectModal = ref(false);
        const connectInstagramFeed = async (username) => {
            try {
                instagramFeedLoading.value = true;
                await post(`/${locale}/member/instructor_page/connect_instagram_feed`, csrfToken(), { instagramUsername: username });
                instagramUsername.value = username;
                instagramFeedShow.value = true;
                instagramFeedEnabled.value = true;
            }
            catch (error) {
                console.error(error);
            }
            finally {
                instagramFeedLoading.value = false;
            }
        };
        const setInstagramFeedVisibility = async (visibility) => {
            try {
                instagramFeedLoading.value = true;
                await post(`/${locale}/member/instructor_page/set_instagram_feed_visibility`, csrfToken(), { visibility });
            }
            catch (error) {
                console.error(error);
            }
            finally {
                instagramFeedLoading.value = false;
            }
        };
        const disconnectInstagramFeed = async () => {
            try {
                instagramFeedLoading.value = true;
                await post(`/${locale}/member/instructor_page/disconnect_instagram_feed`, csrfToken(), {});
                instagramFeedEnabled.value = false;
                instagramFeedDisconnectModal.value = false;
            }
            catch (error) {
                console.error(error);
            }
            finally {
                instagramFeedLoading.value = false;
            }
        };
        const noomBannerLoading = ref(false);
        const setNoomBannerVisibility = async (visibility) => {
            try {
                noomBannerLoading.value = true;
                await post(`/${locale}/member/instructor_page/save_details`, csrfToken(), {
                    noom_banner_enabled: visibility
                });
            }
            catch (error) {
                console.error(error);
            }
            finally {
                noomBannerLoading.value = false;
            }
        };
        return {
            showTipModal,
            toggleTipModal,
            showToastMessage,
            toastState,
            showNoDirectPaymentModal,
            loadingNoDirectPaymentModal,
            selectedClass,
            postRegisterLead,
            closeNoDirectPaymentModal: () => showNoDirectPaymentModal.value = false,
            handleDirectPayment: (classId) => {
                loadingNoDirectPaymentModal.value = true;
                showNoDirectPaymentModal.value = true;
                getClassInfo(classId)
                    .then((classInfo) => {
                    if (!classInfo.classDetails.location_requires_membership) {
                        showNoDirectPaymentModal.value = false;
                        const redirectURL = `${classInfo.redirectUrl}&potential_source=${encodeURIComponent('Instructor Page')}`;
                        const newWindow = window.open(redirectURL, '_blank');
                        if (newWindow) {
                            newWindow.focus();
                        }
                        else {
                            redirect(redirectURL);
                        }
                        return;
                    }
                    selectedClass.value = classInfo;
                    loadingNoDirectPaymentModal.value = false;
                })
                    .catch((error) => {
                    console.error(error);
                    showNoDirectPaymentModal.value = false;
                });
            },
            instagramUsername,
            instagramFeedLoading,
            instagramFeedEnabled,
            instagramFeedShow,
            noomBannerEnabled,
            noomBannerLoading,
            instagramFeedDisconnectModal,
            connectInstagramFeed,
            setInstagramFeedVisibility,
            disconnectInstagramFeed,
            setNoomBannerVisibility,
        };
    }
});
createApp(InstructorPage).mount('#instructor-page-index');
