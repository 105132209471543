import { defineComponent, ref, computed } from 'vue';
import { validateTranslations } from '@ts/Util/i18n';
import { urlValidator } from '@ts/Util/url';
import Modal from '@components/Core/Modal.vue';
import ZButton from '@components/Core/ZButton.vue';
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue';
import ZInput from '@components/Core/ZInput.vue';
import { get } from '@ts/Util/http';
import { csrfToken } from '@ts/Util/security';
import debounce from 'lodash-es/debounce';
const translationDefaults = {
    instructions: 'Add a link to another webpage you want visitors to see.',
    title: 'Additional Webpage',
    pageLink: 'Page link',
    pageTitle: 'Page title',
    save: 'Save',
    cancel: 'Cancel',
};
export default defineComponent({
    name: 'WebsiteModal',
    components: {
        Modal,
        ZButton,
        ZInput,
        HorizontalSeparator
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        websiteTitle: {
            type: String,
            required: false,
            default: null
        },
        websiteUrl: {
            type: String,
            required: false,
            default: null
        },
        showModal: {
            type: Boolean,
            required: true,
            default: false
        },
        handleClosedModal: {
            type: Function,
            required: true
        },
        error: {
            type: String,
            required: false,
            default: ''
        },
        websiteTitleScraperAction: {
            type: String,
            required: false,
            default: ''
        }
    },
    emits: ['update:website'],
    setup(props, context) {
        const show = ref(props.showModal);
        const title = ref(props.websiteTitle);
        const url = ref(props.websiteUrl);
        const attachHttp = (value) => {
            if (value && !value.match(/^http[s]?:\/\//)) {
                value = "http://" + value;
            }
            return value;
        };
        const debouncedWebsiteTitleScrap = debounce(async (websiteUrl) => {
            const request = await get(props.websiteTitleScraperAction, csrfToken(), {
                website_url: attachHttp(websiteUrl)
            });
            const resp = await request.json();
            if (!request.ok || !resp.success) {
                throw Error('Error while getting the website title');
            }
            title.value = resp.website_title;
        }, 500);
        const cancelModal = () => {
            title.value = props.websiteTitle;
            url.value = props.websiteUrl;
            props.handleClosedModal();
        };
        const saveWebsite = () => {
            context.emit('update:website', {
                page_url: attachHttp(url.value),
                title: title.value,
            });
        };
        const internalError = ref(props.error);
        const validate = async () => {
            if (!urlValidator(attachHttp(url.value)) || !!title.value) {
                return;
            }
            debouncedWebsiteTitleScrap(url.value);
        };
        const isValid = computed(() => {
            if (!urlValidator(attachHttp(url.value)) && title.value) {
                return false;
            }
            return true;
        });
        if (!title.value && urlValidator(attachHttp(url.value))) {
            debouncedWebsiteTitleScrap(url.value);
        }
        return {
            show,
            isValid,
            cancelModal,
            title,
            url,
            saveWebsite,
            validate,
            internalError
        };
    },
});
