import { ref, defineComponent, reactive } from 'vue';
import { isMobileReactive } from '@ts/Util/responsiveness';
import { csrfToken } from '@ts/Util/security';
import { post } from '@ts/Util/http';
import { validateTranslations } from '@ts/Util/i18n';
import Modal from '@components/Core/Modal.vue';
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue';
import ZButton from '@components/Core/ZButton.vue';
import FacebookIcon from '@bx-icons/logos/bxl-facebook-circle.svg';
import LinkedinIcon from '@bx-icons/logos/bxl-linkedin.svg';
import InstagramIcon from '@bx-icons/logos/bxl-instagram-alt.svg';
import TiktokIcon from '@bx-icons/logos/bxl-tiktok.svg';
import SpotifyIcon from '@bx-icons/logos/bxl-spotify.svg';
import ZInput from '@components/Core/ZInput.vue';
import { validateEmail } from '@ts/Util/email';
const translationDefaults = {
    socialLinks: 'Social Links',
    socialLinksDetails: 'Link directly to your social accounts by adding your usernames',
    facebook: 'Facebook',
    instagram: 'Instagram',
    spotify: 'Spotify',
    tiktok: 'Tiktok',
    linkedin: 'Linkedin',
    cancel: 'Cancel',
    save: 'Save',
    errorInvalidSocialLink: 'Invalid format',
};
export default defineComponent({
    name: 'SocialModal',
    components: {
        Modal,
        HorizontalSeparator,
        ZButton,
        FacebookIcon,
        LinkedinIcon,
        InstagramIcon,
        TiktokIcon,
        SpotifyIcon,
        ZInput,
    },
    props: {
        show: {
            type: Boolean,
            default: () => false
        },
        action: {
            type: String,
            required: true,
        },
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        socialLinks: {
            type: Object,
            required: true,
        }
    },
    emits: ['closed', 'save'],
    setup(props, ctx) {
        const networks = ref([
            'facebook', 'instagram', 'spotify', 'tiktok', 'linkedin',
        ]);
        // Minimal validation pattern for social media profiles (url characters)
        const urlPattern = /^(https?:\/\/)?[a-zA-Z0-9-._~:/?#[@\]!$&'()*+,;=]+$/;
        const state = reactive({
            errorSocialNetwork: {},
            isSaving: false,
        });
        networks.value.forEach(network => {
            state.errorSocialNetwork[network] = false;
        });
        const values = ref(props.socialLinks);
        const isMobile = isMobileReactive();
        const doCancel = () => {
            values.value = props.socialLinks;
            ctx.emit('closed');
        };
        const doSave = async () => {
            if (!isFormValid())
                return false;
            state.isSaving = true;
            const response = await post(props.action, csrfToken(), values.value);
            if (response.ok) {
                window.location.reload();
            }
            else {
                state.isSaving = false;
            }
        };
        const validateSocialNetwork = (network) => {
            const isValid = isValidSocialNetwork(network);
            state.errorSocialNetwork[network] = !isValid;
        };
        const isValidSocialNetwork = (network) => {
            const value = values.value[network] || '';
            if (value) {
                //not allow emails
                if (!urlPattern.test(value) || validateEmail(value)) {
                    return false;
                }
            }
            return true;
        };
        const isFormValid = () => {
            networks.value.forEach(network => {
                const isValid = isValidSocialNetwork(network);
                state.errorSocialNetwork[network] = !isValid;
            });
            if (Object.values(state.errorSocialNetwork).some(error => error)) {
                return false;
            }
            return true;
        };
        return {
            doCancel,
            doSave,
            isMobile,
            networks,
            values,
            state,
            validateSocialNetwork,
        };
    }
});
