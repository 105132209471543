import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "social"
};
const _hoisted_2 = { class: "social-label f-inter" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_social_links = _resolveComponent("social-links");
    return (Object.keys(_ctx.socialLinks).length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t.socialLabel), 1),
            _createVNode(_component_social_links, {
                "social-links": _ctx.socialLinks,
                size: _ctx.size
            }, null, 8, ["social-links", "size"])
        ]))
        : _createCommentVNode("", true);
}
