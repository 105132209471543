import { defineComponent, computed, reactive } from 'vue';
import { validateTranslations } from '@ts/Util/i18n';
import MyPageURLModal from '@modules/InstructorPage/MyPageURLModal.vue';
import MyPageURLConfirmModal from '@modules/InstructorPage/MyPageURLConfirmModal.vue';
import { validate as UUIDValidate } from 'uuid';
import { get, post } from '@ts/Util/http';
import { csrfToken } from "@ts/Util/security";
const translationDefaults = {
    title: 'My Page URL',
    shortname: 'Usename',
    save: 'Save',
    cancel: 'Cancel',
    description: 'Enter your preferred username to customize the URL of this instructor page.',
    urlEditRequirementsHeader: 'Please be sure that your username:',
    urlEditRequirements1: 'begins and ends with a letter or number',
    urlEditRequirements2: 'is unique to you',
    baseUrl: 'https://zumba.com/p/{0}',
    errorCustomURL: 'The custom URL must be between 4-40 characters, and can only contain letters and numbers.',
    errorCustomURLTaken: "This custom URL is already taken please select another",
    confirmModal: {
        confirmNewUrl: "Confirm new URL",
        back: "Back",
        confirm: "Confirm",
        uponConfirmation: "Upon confirmation, your URL will be changed and you can modify your instructor page URL {0} more times.",
        uponConfirmationOne: "Upon confirmation, your URL will be changed and you can modify your instructor page URL {0} more time.",
        uponConfirmationNoMore: "Upon confirmation, your URL will be changed. You will not be able to edit this URL once it is changed.",
    }
};
export default defineComponent({
    name: 'MyPageURLModalHandler',
    components: {
        MyPageURLModal,
        MyPageURLConfirmModal,
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        shortname: {
            type: String,
            default: '',
        },
        showModal: {
            type: Boolean,
            required: true,
            default: false
        },
        saveUrlAction: {
            type: String,
            required: true,
            default: ''
        },
        isUrlAvailableAction: {
            type: String,
            required: true,
            default: ''
        }
    },
    emits: [
        'save',
        'cancel'
    ],
    setup(props, context) {
        const state = reactive({
            mode: props.showModal ? 'edit' : 'none',
            confirmData: null,
            disable: false,
            lastValidatedShortname: (UUIDValidate(props.shortname) ? '' : props.shortname),
            urlUpdated: false,
            shortnameTaken: false,
        });
        const isAvailable = async (shortname) => {
            const response = await get(props.isUrlAvailableAction, csrfToken(), { shortname });
            return await response.json();
        };
        const confirmAvailabilityURL = (shortname) => {
            state.disable = true;
            isAvailable(shortname)
                .then((results) => {
                state.shortnameTaken = false;
                state.lastValidatedShortname = shortname;
                if (results.is_available) {
                    state.mode = 'confirm';
                    state.confirmData = {
                        ...results,
                        shortname: shortname
                    };
                }
                else {
                    state.shortnameTaken = true;
                }
            })
                .finally(() => state.disable = false);
        };
        const updateURL = async (shortname) => {
            return await post(props.saveUrlAction, csrfToken(), { shortname });
        };
        const saveURL = (shortname) => {
            state.disable = true;
            updateURL(shortname)
                .then((response) => {
                if (!response.ok) {
                    throw new Error("Cannot save URL");
                }
                state.mode = 'none';
                state.urlUpdated = true;
                context.emit('save', { shortname, requestsLeft: 2 - state.confirmData?.request_count ?? 0 });
            })
                .finally(() => state.disable = false);
        };
        const closeModal = () => {
            state.mode = 'none';
            context.emit('cancel');
        };
        const requestsLeft = computed(() => 2 - state.confirmData?.request_count ?? 0);
        return {
            state,
            confirmAvailabilityURL,
            closeModal,
            saveURL,
            requestsLeft
        };
    },
    watch: {
        showModal: function (newVal, oldVal) {
            this.state.mode = newVal ? 'edit' : 'none';
        }
    }
});
