import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "my-page-url-confirm-modal" };
const _hoisted_2 = { class: "modal-title" };
const _hoisted_3 = { class: "instructions" };
const _hoisted_4 = { class: "main-container" };
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = { class: "url-copy" };
const _hoisted_7 = { class: "actions-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_HorizontalSeparator = _resolveComponent("HorizontalSeparator");
    const _component_horizontal_separator = _resolveComponent("horizontal-separator");
    const _component_ZButton = _resolveComponent("ZButton");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        show: _ctx.showModal,
        mode: 'light',
        "mobile-drawer": true,
        "show-close-button": false,
        onClosed: _cache[2] || (_cache[2] = () => _ctx.$emit('close'))
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.t.confirmNewUrl), 1),
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t.instructions), 1),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("p", { innerHTML: _ctx.urlText }, null, 8, _hoisted_5),
                    _createVNode(_component_HorizontalSeparator),
                    _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.confirmationText), 1)
                ]),
                _createVNode(_component_horizontal_separator),
                _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_ZButton, {
                        variety: "phoenix",
                        wide: "mobile-only",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => (_ctx.$emit('back')), ["prevent"]))
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t.back), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_ZButton, {
                        variety: "optimus",
                        wide: "mobile-only",
                        disabled: _ctx.disable,
                        onClick: _cache[1] || (_cache[1] = _withModifiers(($event) => (_ctx.$emit('confirm', _ctx.shortname)), ["prevent"]))
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t.confirm), 1)
                        ]),
                        _: 1
                    }, 8, ["disabled"])
                ])
            ])
        ]),
        _: 1
    }, 8, ["show"]));
}
