import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "instructor-website-modal" };
const _hoisted_2 = { class: "modal-headline f-inter" };
const _hoisted_3 = { class: "instructions" };
const _hoisted_4 = { class: "main-container" };
const _hoisted_5 = { class: "actions-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ZInput = _resolveComponent("ZInput");
    const _component_horizontal_separator = _resolveComponent("horizontal-separator");
    const _component_ZButton = _resolveComponent("ZButton");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        show: _ctx.showModal,
        mode: 'light',
        "mobile-drawer": true,
        "show-close-button": false,
        "custom-styles": { modal: { 'padding': '2.5rem' } },
        onClosed: _cache[4] || (_cache[4] = () => _ctx.handleClosedModal())
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.t.title), 1),
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t.instructions), 1),
                _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_ZInput, {
                        modelValue: _ctx.url,
                        "onUpdate:modelValue": [
                            _cache[0] || (_cache[0] = ($event) => ((_ctx.url) = $event)),
                            _ctx.validate
                        ],
                        name: "title",
                        type: "text",
                        required: true,
                        label: _ctx.t.pageLink,
                        error: _ctx.internalError
                    }, null, 8, ["modelValue", "label", "error", "onUpdate:modelValue"]),
                    _createVNode(_component_ZInput, {
                        modelValue: _ctx.title,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.title) = $event)),
                        name: "page_url",
                        type: "text",
                        required: false,
                        label: _ctx.t.pageTitle
                    }, null, 8, ["modelValue", "label"])
                ]),
                _createVNode(_component_horizontal_separator),
                _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_ZButton, {
                        variety: "phoenix",
                        wide: "mobile-only",
                        onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.cancelModal()))
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t.cancel), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_ZButton, {
                        variety: "optimus",
                        wide: "mobile-only",
                        disabled: !_ctx.isValid,
                        onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.saveWebsite()))
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t.save), 1)
                        ]),
                        _: 1
                    }, 8, ["disabled"])
                ])
            ])
        ]),
        _: 1
    }, 8, ["show"]));
}
