import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
import { computed } from 'vue';
import XIcon from "@bx-icons/regular/bx-x.svg";
export default /*@__PURE__*/ _defineComponent({
    __name: 'Tag',
    props: {
        showCancel: {
            type: Boolean,
            default: true
        },
        variety: {
            type: String,
            default: 'default',
            validator: (value) => {
                // You can add more variety types here
                const allowedVarieties = [
                    'default',
                    'filled',
                    'featured',
                    'disabled',
                    'information',
                    'success',
                    'warning',
                    'error',
                    'small',
                ];
                return value.split(' ').every(elem => allowedVarieties.includes(elem));
            },
        },
    },
    emits: ['cancel:clicked'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const classes = computed(() => {
            return {
                [props.variety]: true,
            };
        });
        const emit = __emit;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["tag f-inter", classes.value])
            }, [
                _renderSlot(_ctx.$slots, "default"),
                (__props.showCancel)
                    ? (_openBlock(), _createBlock(_unref(XIcon), {
                        key: 0,
                        class: "tag_close_icon",
                        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('cancel:clicked')))
                    }))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
